import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { MppBaseDateTimePicker } from '../base-date-time-picker';
import { MppViewModes } from '../enums';
import {
  DEFAULT_DATE_INPUT_FORMAT,
  DEFAULT_MONTH_VIEW_FORMAT,
  DEFAULT_YEAR_VIEW_FORMAT,
} from './date-picker.constants';
import { IDateFormat, TRegionDateFormat } from '../types';
import { MppDateTimeInputComponent } from '../components/date-time-input/date-time-input.component';
import { startOfMonth, startOfYear } from 'date-fns';

@Component({
  selector: 'mpp-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-date-picker' },
})
export class MppDatePickerComponent extends MppBaseDateTimePicker {
  @Input()
  public readonly viewMode: MppViewModes = MppViewModes.DAYS;

  @Input()
  public readonly placeholder: string = '';

  @Input()
  public readonly min: Date;

  @Input()
  public readonly max: Date;

  @ViewChild(MppDateTimeInputComponent, { static: true })
  protected readonly inputComponent: MppDateTimeInputComponent;

  public get inputFormat(): IDateFormat {
    return this.format ? this.format[this.userRegion] : this.getDefaultViewFormat();
  }

  public constructor(
    elementRef: ElementRef<HTMLElement>,
    viewContainerRef: ViewContainerRef,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(elementRef, viewContainerRef, changeDetectorRef);
  }

  @HostListener('document:pointerdown', ['$event.target'])
  public onOutsideClick(target: HTMLElement): void {
    if (
      !(this.dropdownShown$$.value || this.inputComponent.isFocused) ||
      this.dropdownElement?.contains(target) ||
      this.elementRef.nativeElement.contains(target)
    ) {
      return;
    }

    this.inputComponent.blur();

    if (!this.elementRef.nativeElement.contains(target)) {
      this.dropdownShown$$.next(false);
    }
  }

  @HostListener('pointerdown')
  public onHostClick(): void {
    if (this.inputComponent.isFocused) {
      return;
    }

    this.inputComponent.focus();
  }

  public onChange(value: Date): void {
    this.control.setValue(value);
  }

  public onFocus(): void {
    this.dropdownShown$$.next(true);
  }

  public getDefaultViewFormat(): IDateFormat {
    const viewModeFormats: Record<MppViewModes, TRegionDateFormat> = {
      [MppViewModes.DAYS]: DEFAULT_DATE_INPUT_FORMAT,
      [MppViewModes.MONTHS]: DEFAULT_MONTH_VIEW_FORMAT,
      [MppViewModes.YEARS]: DEFAULT_YEAR_VIEW_FORMAT,
    };

    return viewModeFormats[this.viewMode][this.userRegion];
  }

  public onSelect(date: Date): void {
    this.control.setValue(this.dateFormatter(date));

    this.dropdownShown$$.next(false);
    this.inputComponent.blur();
  }

  protected get dropdownWidth(): number | null {
    return null;
  }

  protected onDropdownToggle(isCalendarShown: boolean): void {
    if (!isCalendarShown) {
      this.control.markAsTouched();
      this.control.markAsDirty();
    }
  }

  private dateFormatter(date: Date): Date {
    const dateOffsetMap: Record<MppViewModes, Date> = {
      [MppViewModes.DAYS]: date,
      [MppViewModes.MONTHS]: startOfMonth(date),
      [MppViewModes.YEARS]: startOfYear(date),
    };

    return dateOffsetMap[this.viewMode];
  }
}
