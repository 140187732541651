import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MppHeaderItemComponent } from './header-item/header-item.component';
import { MppActionsTabGroupComponent } from './tab-group.component';
import { MppTabComponent } from './tab/tab.component';

@NgModule({
  declarations: [MppTabComponent, MppActionsTabGroupComponent, MppHeaderItemComponent],
  exports: [MppActionsTabGroupComponent, MppTabComponent],
  imports: [CommonModule, TranslateModule],
})
export class MppTabGroupModule {}
