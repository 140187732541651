import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'mpp-marker',
  templateUrl: './marker.component.html',
  styleUrls: ['./marker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'mpp-checkbox-marker' },
})
export class MppMarkerComponent {
  @Input()
  @HostBinding('class.checked')
  public readonly isChecked: boolean;

  @Input()
  @HostBinding('class.indeterminate')
  public readonly isIndeterminate: boolean | undefined;

  @Input()
  @HostBinding('class.disabled')
  public readonly disabled: boolean = false;
}
