import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { DEFAULT_SIZE, DEFAULT_STROKE_WIDTH, SVG_SIZE } from './spinner.constants';

@Component({
  selector: 'mpp-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-spinner' },
})
export class MppSpinnerComponent implements OnInit {
  /** @deprecated Use flags instead */
  @Input()
  @HostBinding('attr.data-type')
  public readonly type: 'stretched' | 'inline';

  @Input()
  @HostBinding('class.stretched')
  public readonly isStretched: boolean = false;

  @Input()
  @HostBinding('class.inline')
  public readonly isInline: boolean = false;

  public readonly DEFAULT_STROKE_WIDTH: number = DEFAULT_STROKE_WIDTH;

  public readonly SVG_SIZE: number = SVG_SIZE;

  public readonly viewBox: string = this.getViewBox();

  public size: number;

  public constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  public ngOnInit(): void {
    if (this.isInline && this.isStretched) {
      throw new Error(
        'Please use only one modifier at a time (either isStretched or isInline) to avoid conflicts.'
      );
    }

    const elementStyle: CSSStyleDeclaration = window.getComputedStyle(
      this.elementRef.nativeElement
    );

    const sizeProps: string = elementStyle.getPropertyValue('--spinner-size');

    this.size = +sizeProps || DEFAULT_SIZE;
  }

  public getCircleRadius(): number {
    const DIAMETER_DELIMITER = 2;

    return (this.SVG_SIZE - this.DEFAULT_STROKE_WIDTH) / DIAMETER_DELIMITER;
  }

  private getViewBox(): string {
    return `0 0 ${this.SVG_SIZE} ${this.SVG_SIZE}`;
  }
}
