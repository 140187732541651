import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { MppPipesModule } from '../../../../../pipes';
import { MppDateTimeInputComponent } from './date-time-input.component';

@NgModule({
  declarations: [MppDateTimeInputComponent],
  exports: [MppDateTimeInputComponent],
  imports: [CommonModule, NgxMaskModule.forRoot(), ReactiveFormsModule, MppPipesModule],
})
export class MppDateTimeInputModule {}
