<ng-template #actionsTemplate>
  <mpp-select-icons-panel (onClearSearchClick)="clearSearch()">
  </mpp-select-icons-panel>
</ng-template>

<ng-select [formControl]="control"
           [searchable]="isOpen"
           [items]="options"
           bindValue="value"
           bindLabel="label"
           [searchFn]="searchFn"
           [clearable]="false"
           [multiple]="true"
           [closeOnSelect]="false"
           [clearOnBackspace]="false"
           [placeholder]="placeholder | translate"
           [class.all-selected]="isSelectAllChecked"
           (open)="onControlOpen()"
           (close)="onControlClose()"
           (search)="onSearch($event.term)"
           appendTo="body">
  <ng-template ng-multi-label-tmp
               let-items="items">
    <ng-container *ngIf="!isOpen && !isSelectAllChecked">
      <div class="label-container">
        <div class="label-container__content">
          <span *ngFor="let selectedItem of selectComponent.selectedItems"
                class="label-container__item">{{ selectedItem.label }}</span>
        </div>

        <div *ngIf="selectComponent.selectedItems.length as selectedItemsCount"
             class="label-container__counter selected-items-counter">
          {{ selectedItemsCount }}
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template ng-header-tmp *ngIf="selectAllLabel">
    <mpp-checkbox [checked]="isSelectAllChecked"
                  [indeterminate]="selectComponent.hasValue && !selectAllCheckbox.checked"
                  (onChange)="$event ? selectAll() : unselectAll()"
                  #selectAllCheckbox>
      <div class="multiple-item-wrapper">
        <div class="multiple-item-wrapper__content">{{ selectAllLabel | translate }}</div>

        <div class="multiple-item-wrapper__counter selected-items-counter"
             *ngIf="selectAllCheckbox.checked">{{ control.value.length }}</div>
      </div>
    </mpp-checkbox>
  </ng-template>

  <ng-template ng-option-tmp
               let-item$="item$"
               let-index="index"
               let-item="item"
               let-search="searchTerm">
    <mpp-checkbox (click)="$event.preventDefault()"
                  [disabled]="item$.disabled"
                  [checked]="item$.selected">
      <div class="multiple-item-wrapper">
        <div class="multiple-item-wrapper__content">
          <ng-container [ngTemplateOutlet]="options[index].templateRef"
                        [ngTemplateOutletContext]="{ search: search }">
          </ng-container>
        </div>
      </div>
    </mpp-checkbox>
  </ng-template>

  <ng-template ng-notfound-tmp>
    <div class="not-found-option">
      {{ 'COMMON.DROPDOWNS.NO_ITEMS_FOUND' | translate }}
    </div>
  </ng-template>
</ng-select>
