import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MppDirectivesModule } from '../../../directives';
import { MppPipesModule } from '../../../pipes';
import { MppLanguageService } from '../../../services/language.service';
import { COMMON_ERRORS_TOKEN } from '../form-controls.constants';
import { MppGroupTagSelectComponent } from './group-tag-select.component';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';

@NgModule({
  declarations: [MppGroupTagSelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MppDirectivesModule,
    MppPipesModule,
    NgSelectModule,
    NgOptionHighlightModule,
    FormsModule,
  ],
  exports: [MppGroupTagSelectComponent],
  providers: [MppLanguageService],
})
export class MppGroupTagSelectModule {
  public static forRoot(
    errors: Record<string, string>
  ): ModuleWithProviders<MppGroupTagSelectModule> {
    return {
      ngModule: MppGroupTagSelectModule,
      providers: [{ provide: COMMON_ERRORS_TOKEN, useValue: errors }],
    };
  }

  public static forChild(): ModuleWithProviders<MppGroupTagSelectModule> {
    return { ngModule: MppGroupTagSelectModule };
  }
}
