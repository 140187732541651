import { MppViewModes } from '../../enums';

export const DECADE = 10;

export const MODES_ORDER_STEP = 1;

export const MODES_SWITCH_ORDER: MppViewModes[] = [
  MppViewModes.DAYS,
  MppViewModes.MONTHS,
  MppViewModes.YEARS,
];

export const CALENDAR_TITLE_MONTH_VIEW_FORMAT = 'LLLL yyyy';

export const CALENDAR_TITLE_YEARS_VIEW_FORMAT = 'yyyy';
