import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogCloseDirective, DialogModule } from '@ngneat/dialog';
import { MppButtonModule } from '../button';
import { MppDialogService } from './services';

@NgModule({
  imports: [
    CommonModule,
    MppButtonModule,
    DialogModule.forRoot({
      closeButton: false,
      size: 'auto',
      sizes: {
        auto: {},
      },
    }),
  ],
  exports: [DialogCloseDirective],
})
export class MppDialogModule {
  public static forRoot(): ModuleWithProviders<MppDialogModule> {
    return {
      ngModule: MppDialogModule,
      providers: [MppDialogService],
    };
  }
}
