import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MppAutoUnsubscribeComponent } from '../../helpers';

@Component({
  selector: 'mpp-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-checkbox' },
})
export class MppCheckboxComponent extends MppAutoUnsubscribeComponent implements OnInit, OnChanges {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  public static ngAcceptInputType_control: AbstractControl;

  @Input()
  public readonly control: FormControl;

  @Input()
  public readonly disabled: boolean;

  @Input()
  public readonly indeterminate: boolean | undefined;

  @Input()
  public readonly checked: boolean;

  @Output()
  public readonly onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.disabled')
  public get isDisabled(): boolean {
    return this.control ? this.control.disabled : this.disabled;
  }

  public get isChecked(): boolean {
    return this.control ? this.control.value : this.checked;
  }

  public ngOnInit(): void {
    if (this.indeterminate !== undefined && this.control) {
      throw new Error("Indeterminate state isn't supported with reactive forms");
    }

    if (!this.control && this.checked === undefined) {
      throw new Error('You must pass formControl or checked prop');
    }
  }

  public ngOnChanges(): void {
    if (this.indeterminate && this.checked) {
      throw new Error("Indeterminate can't be true while checkbox is checked");
    }
  }
}
