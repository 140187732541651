import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MppEmptyStateComponent } from './empty-state.component';

@NgModule({
  imports: [CommonModule],
  declarations: [MppEmptyStateComponent],
  exports: [MppEmptyStateComponent],
})
export class MppEmptyStateModule {}
