import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MppUserLanguage } from '../enums';

@Injectable()
export class MppLanguageService {
  private readonly DEFAULT_LANGUAGE: MppUserLanguage =
    this.translateService.getDefaultLang() as MppUserLanguage;
  private readonly changed$$: Subject<MppUserLanguage> = new Subject<MppUserLanguage>();

  public get changed$(): Observable<MppUserLanguage> {
    return this.changed$$.asObservable();
  }

  public constructor(private readonly translateService: TranslateService) {}

  public setBrowserLanguage$(): Observable<void> {
    const navigatorLanguage = this.translateService.getBrowserLang();
    const language = Object.values(MppUserLanguage).includes(navigatorLanguage as MppUserLanguage)
      ? (navigatorLanguage as MppUserLanguage)
      : this.DEFAULT_LANGUAGE;

    this.changed$$.next(language);

    return this.translateService.use(language);
  }

  public isTranslationLoaded(): boolean {
    const currentLang = this.translateService.store.currentLang;
    const translations = this.translateService.store.translations;
    return !!translations[currentLang];
  }
}
