<div class="access-container__content">
  <div class="page-content">
    <div class="page-content__header">
      <div class="page-content__title"
           [innerHTML]="'ROOT.RESET_PASSWORD_PAGE.TITLE' | translate"></div>

      <div class="page-content__sub-title">
        {{ 'ROOT.RESET_PASSWORD_PAGE.SUB_TITLE' | translate }}
      </div>
    </div>

    <form class="page-content__form"
          [formGroup]="form">
      <am-input [type]="isPasswordExposed ? 'text' : 'password'"
                [control]="form.controls['password']"
                placeholder="ROOT.RESET_PASSWORD_PAGE.NEW_PASSWORD_PLACEHOLDER"
                #newPasswordControl>
        <button suffix
                class="icon"
                type="button"
                *ngIf="form.controls['password'].value || form.controls['passwordConfirmation'].value"
                [ngClass]="isPasswordExposed ? 'icon-eye-off-filled' : 'icon-eye-filled'"
                (click)="isPasswordExposed = !isPasswordExposed"></button>
      </am-input>

      <am-input [type]="isPasswordExposed ? 'text' : 'password'"
                [control]="form.controls['passwordConfirmation']"
                placeholder="ROOT.RESET_PASSWORD_PAGE.CONFIRMATION_PASSWORD_PLACEHOLDER"
                #confirmationPasswordControl>
      </am-input>

      <div class="page-content__buttons">
        <button class="page-content__button"
                mppButton="fill blue"
                [disabled]="isResetting"
                (click)="reset()"
        >
          {{ 'ROOT.RESET_PASSWORD_PAGE.SEND_LINK_BUTTON_LABEL' | translate }}
        </button>
      </div>
    </form>

    <div class="page-content__footer">
      <a routerLink="/"
         class="page-content__link icon icon-chevron-left-outlined">
        {{ 'ROOT.RESET_PASSWORD_PAGE.BACK_TO_LOGIN' | translate }}
      </a>
    </div>
  </div>
</div>

<div class="access-container__preview">
  <div class="access-preview"
       [style.background-image]="'url(\'assets/images/reset-password-image.svg\')'"
  >
    <a class="access-preview__logo-wrapper" href="https://www.arturin.com/">
      <img class="access-preview__logo"
           src="../../assets/images/black-logo.svg"
           alt="" />
    </a>
  </div>
</div>
