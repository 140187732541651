import { MppSocialNetwork } from '../enums';

export type TSocialIcon =
  | MppSocialNetwork.FACEBOOK
  | MppSocialNetwork.GOOGLE
  | MppSocialNetwork.LINKEDIN
  | MppSocialNetwork.TWITTER
  | MppSocialNetwork.INSTAGRAM
  | 'emailing'
  | 'yelp'
  | 'tripadvisor'
  | 'pinterest'
  | 'youtube';
