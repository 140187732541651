<div class="mpp-summary-counter__icon icon" [ngClass]="data.iconClass"></div>

<div class="mpp-summary-counter__content">
  <div *ngIf="isCountAvailable()" class="mpp-summary-counter__count">
    {{ data.count }}
  </div>

  <ng-template #loader>
    <div class="mpp-summary-counter__loader mpp-content-loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </ng-template>

  <div class="mpp-summary-counter__label">
    {{ getCountLabel(data) | translate }}
  </div>
</div>
