import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { MppHeaderItemComponent } from './header-item/header-item.component';
import { SLIDE_IN_OUT_ANIMATION } from './animations/slide-in-out.animation';
import { MppTabComponent } from './tab/tab.component';
import { asyncScheduler, Subject } from 'rxjs';
import { distinctUntilChanged, observeOn, takeUntil } from 'rxjs/operators';
import { MppAutoUnsubscribeComponent } from '../../../helpers';

@Component({
  selector: 'mpp-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  animations: [SLIDE_IN_OUT_ANIMATION],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-tab-group' },
})
export class MppActionsTabGroupComponent
  extends MppAutoUnsubscribeComponent
  implements AfterContentInit
{
  @Input()
  public readonly isNavigationShown = true;

  @Input()
  public readonly isContentShown = true;

  @Input()
  public readonly isLabelStretched = false;

  @Input()
  public set index(value: number) {
    if (value === this.selectedIndex) {
      return;
    }

    this.indexChanged$$.next(value);
  }

  @Output()
  public readonly onIndexChange: EventEmitter<number> = new EventEmitter<number>();

  @ContentChildren(MppTabComponent)
  public readonly tabs: QueryList<MppTabComponent>;

  @ContentChild('actionsTemplate', { read: TemplateRef })
  public readonly actionsTemplate: TemplateRef<any>;

  @ViewChildren(MppHeaderItemComponent)
  public headerItems: QueryList<MppHeaderItemComponent>;

  public selectedIndex: number;
  public next: MppTabComponent | null;
  public active: MppTabComponent;

  public contentAnimationState: string | null = null;
  public isContentReversed = false;

  private readonly INITIAL_INDEX = 0;

  private readonly indexChanged$$: Subject<number> = new Subject();

  public constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  public ngAfterContentInit(): void {
    this.initIndexChanges();

    this.tabs.changes.pipe(observeOn(asyncScheduler), takeUntil(this.destroy$$)).subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  public onLabelClick(index: number): void {
    this.indexChanged$$.next(index);
  }

  public onContentTransitionEnd(): void {
    if (this.next) {
      this.active = this.next!;
      this.next = this.contentAnimationState = null;
    }
  }

  private initIndexChanges(): void {
    this.indexChanged$$
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$$))
      .subscribe((activeIndex) => {
        const MIN_INDEX = 0;

        if (activeIndex >= this.tabs.length || activeIndex < MIN_INDEX) {
          throw new Error(`Index ${activeIndex} doesn't exist`);
        }

        const tabs: MppTabComponent[] = this.tabs.toArray();
        const currentSelectedIndex: number = tabs.indexOf(this.active);

        if (!this.active) {
          this.selectedIndex = activeIndex;
          this.active = tabs[activeIndex];

          return;
        }

        if (this.selectedIndex !== activeIndex) {
          this.onIndexChange.emit(activeIndex);
          this.next = tabs[activeIndex];
          this.contentAnimationState = activeIndex > currentSelectedIndex ? 'in' : 'out';
          this.isContentReversed = activeIndex < currentSelectedIndex;
        }

        this.selectedIndex = activeIndex;
      });

    if (!this.selectedIndex) {
      this.indexChanged$$.next(this.INITIAL_INDEX);
    }
  }
}
