import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MppFiltersBarComponent } from './filters-bar.component';
import { MppFormControlsModule } from '../form-controls';

@NgModule({
  declarations: [MppFiltersBarComponent],
  imports: [CommonModule, MppFormControlsModule],
  exports: [MppFiltersBarComponent],
})
export class MppFiltersBarModule {}
