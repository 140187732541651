import { takeUntil } from 'rxjs/operators';
import { combineLatest, ReplaySubject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { Directive, Input, OnInit } from '@angular/core';
import { MppAutoUnsubscribeComponent } from '../helpers/auto-unsubscribe-component';
import equal from 'fast-deep-equal';

@Directive({
  selector: '[mppFormDirtyStatus]',
})
export class MppFormDirtyStatusDirective extends MppAutoUnsubscribeComponent implements OnInit {
  @Input() public formGroup: FormGroup;

  private readonly value$$: ReplaySubject<any> = new ReplaySubject(1);

  public ngOnInit(): void {
    if (!this.formGroup) {
      return;
    }

    this.value$$.next(this.formGroup.value);

    const valueChanges$ = this.formGroup.valueChanges;

    combineLatest([this.value$$, valueChanges$])
      .pipe(takeUntil(this.destroy$$))
      .subscribe(([prevValue, currentValue]) => {
        if (!equal(prevValue, currentValue)) {
          return;
        }

        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();
      });
  }
}
