import { trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MppAutoUnsubscribeComponent } from '../../helpers';
import { BehaviorSubject } from 'rxjs';
import { EMPTY_CLASS, EXPAND_ANIMATION } from './button.constants';
import { MppButtonThemes, MppButtonType, MppButtonWriteMode } from './enums';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: '[mppButton]', // eslint-disable-line @angular-eslint/component-selector
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-button' },
  animations: [trigger('expandAnimation', EXPAND_ANIMATION)],
})
export class MppButtonComponent extends MppAutoUnsubscribeComponent implements AfterViewInit {
  @Input('mppButton') // eslint-disable-line @angular-eslint/no-input-rename
  @HostBinding('attr.data-button') // TODO: outdated
  @HostBinding('arrt.data-type')
  // TODO: replace input with types only
  public readonly type: MppButtonType | 'rtl' | string;

  @Input()
  @HostBinding('attr.data-theme')
  public readonly theme: MppButtonThemes;

  @Input()
  @HostBinding('attr.data-write-mode')
  public readonly writeMode?: MppButtonWriteMode;

  @Input()
  @HostBinding('class.rounded')
  public readonly isRounded: boolean = false;

  @Input()
  @HostBinding('class.loading')
  public set isLoading(value: boolean) {
    this.loadingState.next(value);
  }

  public get isLoading(): boolean {
    return this.loadingState.value;
  }

  @Input()
  @HostBinding('class.animated')
  public isAnimated = false;

  @ViewChild('text')
  public readonly textRef: ElementRef<HTMLElement>;

  public isTextElementExpanded = false;

  public isContentEmpty = false;
  public isContentExpanded = true;

  private readonly loadingState: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public get isContentDisplaying(): boolean {
    return this.isLoading || this.isContentExpanded;
  }

  public constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this.loadingState.pipe(takeUntil(this.destroy$$)).subscribe(() => {
      if (this.isContentEmpty) {
        return;
      }
    });
  }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    if (!this.isAnimated) {
      return;
    }

    this.isContentExpanded = true;
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    if (!this.isAnimated) {
      return;
    }

    this.isContentExpanded = false;
  }

  public ngAfterViewInit(): void {
    this.isContentEmpty = !this.textRef.nativeElement.innerHTML;

    if (this.isAnimated && this.isContentEmpty) {
      this.isAnimated = false;
    }

    if (this.isAnimated && !this.isContentEmpty) {
      this.isContentExpanded = false;
      this.setContentEmptyClass(!this.isContentDisplaying);
    } else {
      this.setContentEmptyClass(this.isContentEmpty);
    }

    this.changeDetectorRef.detectChanges();
  }

  public onAnimationStart(): void {
    if (!this.isAnimated) {
      return;
    }

    this.setContentEmptyClass(!this.isContentDisplaying);
  }

  private setContentEmptyClass(value: boolean): void {
    this.elementRef.nativeElement.classList.toggle(EMPTY_CLASS, value);
  }
}
