import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { MppAutoUnsubscribeComponent } from '../../../helpers';
import { MppLanguageService } from '../../../services/language.service';
import { takeUntil } from 'rxjs/operators';
import { merge } from 'rxjs';
import { TCommonErrors } from '../types/common-errors';

@Directive()
export abstract class MppBaseFormControl extends MppAutoUnsubscribeComponent implements OnInit {
  @Input()
  public readonly control: FormControl;

  @Input()
  public readonly errorsMap: Record<string, string> = {};

  @Input()
  public readonly placeholderText: string = '';

  @Input()
  @HostBinding('class.optional')
  public readonly isOptional: boolean = false;

  @Input()
  @HostBinding('class.rounded')
  public readonly isRounded: boolean = false;

  @Output()
  public readonly onTyping: EventEmitter<Event> = new EventEmitter();

  @Output()
  public readonly onChange: EventEmitter<Event> = new EventEmitter();

  @Output()
  public readonly onFocus: EventEmitter<Event> = new EventEmitter();

  @Output()
  public readonly onBlur: EventEmitter<Event> = new EventEmitter();

  public errorMessage = '';

  @HostBinding('class.disabled')
  public get isDisabled(): boolean {
    return this.control.disabled;
  }

  @HostBinding('class.invalid')
  public get isInvalid(): boolean {
    return this.control.invalid;
  }

  public constructor(
    protected readonly languageService: MppLanguageService,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly COMMON_ERRORS: TCommonErrors
  ) {
    super();
  }

  public ngOnInit(): void {
    this.updateErrorMessage();

    merge(this.control.statusChanges, this.languageService.changed$)
      .pipe(takeUntil(this.destroy$$))
      .subscribe(() => this.updateErrorMessage());
  }

  private updateErrorMessage(): void {
    const controlErrors: ValidationErrors | null = this.control.errors;

    this.changeDetectorRef.markForCheck();

    if (!controlErrors) {
      this.errorMessage = '';
      return;
    }

    const errorKeys: string[] = Object.keys(controlErrors);
    const errorsMap: Record<string, string> = {
      ...this.COMMON_ERRORS,
      ...this.errorsMap,
    };

    const messages: string[] = errorKeys.map((errorKey) => {
      const message: string | undefined = errorsMap[errorKey];

      if (!message) {
        throw Error(`${errorKey} is not added to control translations`);
      }

      return message;
    });

    this.errorMessage = messages.join('<br>');
  }
}
