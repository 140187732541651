import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { isToday } from 'date-fns';

@Component({
  selector: 'mpp-day',
  template: '{{ date | date: format }}',
  styleUrls: ['./day.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'mpp-day' },
})
export class MppDayComponent {
  @Input()
  public readonly date: Date;

  @Input()
  public readonly format: string;

  @Input()
  @HostBinding('class.secondary')
  public readonly isSecondary: boolean;

  @Input()
  @HostBinding('class.active')
  public readonly isActive: boolean;

  @Input()
  @HostBinding('class.start')
  public readonly isStart: boolean;

  @Input()
  @HostBinding('class.end')
  public readonly isEnd: boolean;

  @Input()
  @HostBinding('class.disabled')
  public readonly isDisabled: boolean;

  @Input()
  // eslint-disable-next-line
  // @ts-ignore
  private readonly disabledRange: (Date | Date[])[];

  @HostBinding('class.current')
  public get isToday(): boolean {
    return isToday(this.date);
  }
}
