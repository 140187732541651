<div class="access-container__content">
  <div class="page-content">
    <div class="page-content__header">
      <div class="page-content__title"
           [innerHTML]="'ROOT.FORGOT_PASSWORD_PAGE.TITLE' | translate"
      ></div>

      <div class="page-content__sub-title">
        {{ 'ROOT.FORGOT_PASSWORD_PAGE.SUB_TITLE' | translate }}
      </div>
    </div>

    <form class="page-content__form"
          [formGroup]="form">
      <re-captcha #recaptcha="reCaptcha"
                  formControlName="recaptcha"
                  size="invisible">
      </re-captcha>

      <am-input type="text"
                [control]="form.controls['email']"
                placeholder="ROOT.FORGOT_PASSWORD_PAGE.EMAIL_PLACEHOLDER">
      </am-input>

      <div class="page-content__buttons">
        <button class="page-content__button"
                mppButton="fill blue"
                (click)="onSubmitClick()"
                [disabled]="form.hasError('required', 'email')"
        >
          {{ 'ROOT.FORGOT_PASSWORD_PAGE.SEND_LINK_BUTTON_LABEL' | translate }}
        </button>
      </div>
    </form>

    <div class="page-content__footer">
      <a class="page-content__link icon icon-chevron-left-outlined"
         routerLink="/"
      >
        {{ 'ROOT.FORGOT_PASSWORD_PAGE.BACK_TO_LOGIN' | translate }}
      </a>

      <div class="content-policy"
           [innerHTML]="'ROOT.FORGOT_PASSWORD_PAGE.CAPTCHA_POLICY' | translate | mppSanitizeHtml">
      </div>
    </div>
  </div>
</div>

<div class="access-container__preview">
  <div class="access-preview"
       [style.background-image]="'url(\'assets/images/forgot-password-image.svg\')'"
  >
    <a class="access-preview__logo-wrapper" href="https://www.arturin.com/">
      <img class="access-preview__logo"
           src="../../assets/images/black-logo.svg"
           alt="" />
    </a>
  </div>
</div>
