import { Component, ContentChild, Input, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mpp-information-section',
  templateUrl: './information-section.component.html',
  styleUrls: ['./information-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-information-section' },
})
export class MppInformationSectionComponent {
  @Input()
  public readonly heading: string;

  @ContentChild('actions')
  public readonly actionsRef: TemplateRef<any>;
}
