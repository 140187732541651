<div class="mpp-empty-state__emoji" *ngIf="emoji && !url">{{ emoji }}</div>

<img *ngIf="url" [src]="url" alt="" class="mpp-empty-state__img">

<div class="mpp-empty-state__content" *ngIf="heading || description">
  <div class="mpp-empty-state__title" *ngIf="heading" [innerHTML]="heading"></div>

  <div class="mpp-empty-state__description" *ngIf="description" [innerHTML]="description"></div>
</div>

<div class="mpp-empty-state__actions" *ngIf="actions">
  <ng-container *ngTemplateOutlet="actions"></ng-container>
</div>
