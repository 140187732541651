import { ChangeDetectionStrategy, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { IMediaDialogData, IMediaItemData } from './types';
import { SWIPER_CONFIG } from './media-dialog.constants';
import { DialogRef } from '@ngneat/dialog';
import { Swiper } from 'swiper';
import { MppMediaItemType } from './enums';

@Component({
  selector: 'mpp-media-dialog',
  templateUrl: './media-dialog.component.html',
  styleUrls: ['./media-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'mpp-media-dialog' },
})
export class MppMediaDialogComponent {
  @ViewChild(SwiperDirective)
  public readonly swiperDirective: SwiperDirective;

  public readonly mediaItemType: typeof MppMediaItemType = MppMediaItemType;

  public swiperConfig: SwiperConfigInterface = SWIPER_CONFIG;

  public activeMedia: IMediaItemData;
  public activeIndex: number;

  public get data(): IMediaDialogData {
    return this.ref.data;
  }

  public activeVideoPlaying: HTMLVideoElement | null = null;

  public constructor(private readonly ref: DialogRef<IMediaDialogData, void>) {
    this.activeIndex = this.data.initialSlide || 0;
    this.swiperConfig.initialSlide = this.activeIndex;
    this.activeMedia = this.data.media[this.activeIndex];
  }

  public onClose(): void {
    this.ref.close();
  }

  public onSlideChange(swiper: Swiper): void {
    this.activeIndex = swiper.activeIndex;
    this.activeMedia = this.data.media[swiper.activeIndex];

    if (!this.activeVideoPlaying) {
      return;
    }

    this.activeVideoPlaying.pause();
    this.activeVideoPlaying = null;
  }

  public onPlayClick(video: HTMLVideoElement): void {
    video.play();

    this.activeVideoPlaying = video;
  }

  public onPauseClick(video: HTMLVideoElement): void {
    video.pause();

    this.activeVideoPlaying = null;
  }
}
