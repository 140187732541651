import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

export abstract class MppBaseCachedEntityManager<T, K = void> {
  protected entity$$: ReplaySubject<T> = new ReplaySubject<T>(1);

  private isInitialized = false;
  private isInitializing = false;

  public get$(params?: K): Observable<T> {
    if (!this.isInitializing && !this.isInitialized) {
      this.isInitializing = true;

      this.load$(params).subscribe((entity) => {
        this.entity$$.next(entity);

        this.isInitialized = true;
        this.isInitializing = false;
      });
    }

    return this.entity$$.asObservable();
  }

  public refresh(): void {
    this.refresh$().subscribe();
  }

  public refresh$(): Observable<T> {
    return this.load$().pipe(tap((entity) => this.entity$$.next(entity)));
  }

  public emit(updatedEntity: T): void {
    this.entity$$.next(updatedEntity);
  }

  public resetCache(): void {
    this.entity$$.complete();

    this.entity$$ = new ReplaySubject(1);

    this.isInitialized = false;
    this.isInitializing = false;
  }

  protected abstract load$(params?: K): Observable<T>;
}
