import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MppAutoUnsubscribeComponent } from '../../../helpers';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';
import { createPopper } from '@popperjs/core';
import { DROPDOWN_OPTIONS } from './date-range-picker.constants';
import { MppUserRegions } from '../../../enums';
import { IDateFormat, TRegionDateFormat } from './types';

@Directive()
export abstract class MppBaseDateTimePicker
  extends MppAutoUnsubscribeComponent
  implements OnInit, OnDestroy
{
  @Input()
  public readonly control: FormControl;

  @Input()
  public readonly isClearable = false;

  @Input()
  public readonly userRegion: MppUserRegions = MppUserRegions.FR;

  @Input()
  public format: TRegionDateFormat;

  @Output()
  public readonly onClear: EventEmitter<void> = new EventEmitter();

  @ViewChild('dropdownTemplate', { static: true })
  public readonly dropdownTemplateRef: TemplateRef<any>;

  protected dropdownElement: HTMLElement | null;

  protected readonly dropdownShown$$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private dropdownViewRef: EmbeddedViewRef<HTMLElement>;

  public constructor(
    protected readonly elementRef: ElementRef<HTMLElement>,
    protected readonly viewContainerRef: ViewContainerRef,
    protected readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initDropdownListener();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.hideDropdown();
  }

  public onTyping(): void {
    this.dropdownShown$$.next(false);
  }

  public onClearClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.dropdownShown$$.next(false);
    this.onClear.emit();
  }

  private initDropdownListener(): void {
    const toggleActionsMap: (() => void)[] = [this.hideDropdown, this.showDropdown];

    this.dropdownShown$$
      .pipe(takeUntil(this.destroy$$), skip(1), distinctUntilChanged())
      .subscribe((isCalendarShown) => {
        toggleActionsMap[Number(isCalendarShown)].apply(this);

        this.onDropdownToggle(isCalendarShown);
      });
  }

  private showDropdown(): void {
    this.dropdownViewRef = this.viewContainerRef.createEmbeddedView(this.dropdownTemplateRef);
    this.dropdownElement = this.dropdownViewRef.rootNodes[0];

    if (!this.dropdownElement) {
      throw new Error('dropdownElement is not initialised');
    }

    if (this.dropdownWidth) {
      this.dropdownElement.style.width = `${this.dropdownWidth}px`;
    }

    document.body.append(this.dropdownElement);

    setTimeout(() => {
      createPopper(this.elementRef.nativeElement, this.dropdownElement!, DROPDOWN_OPTIONS);
    }, 0);
  }

  private hideDropdown(): void {
    if (!this.dropdownElement) {
      return;
    }

    this.dropdownElement.remove();
    this.dropdownElement = null;

    this.dropdownViewRef.destroy();
  }

  public abstract get inputFormat(): IDateFormat;

  protected abstract onDropdownToggle(isCalendarShown: boolean): void;

  protected abstract get dropdownWidth(): number | null;
}
