import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { RESET_PASSWORD_TOKEN_PARAM_NAME } from '../reset-password-page.constants';

@Injectable()
export class AmResetPasswordPageGuard implements CanActivate {
  public canActivate(next: ActivatedRouteSnapshot): boolean {
    return !!next.queryParams[RESET_PASSWORD_TOKEN_PARAM_NAME];
  }
}
