import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'mpp-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'mpp-empty-state' },
})
export class MppEmptyStateComponent {
  @Input()
  public readonly emoji: string;

  @Input()
  public readonly url: string;

  @Input()
  public readonly heading: string;

  @Input()
  public readonly description: string;

  @ContentChild('actionsTemplate')
  public readonly actions: TemplateRef<any>;
}
