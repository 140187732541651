import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MppInputComponent } from './input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MppDirectivesModule } from '../../../directives';
import { MppLanguageService } from '../../../services/language.service';
import { COMMON_ERRORS_TOKEN } from '../form-controls.constants';

@NgModule({
  declarations: [MppInputComponent],
  imports: [CommonModule, ReactiveFormsModule, MppDirectivesModule],
  exports: [MppInputComponent],
  providers: [MppLanguageService],
})
export class MppInputModule {
  public static forRoot(errors: Record<string, string>): ModuleWithProviders<MppInputModule> {
    return {
      ngModule: MppInputModule,
      providers: [{ provide: COMMON_ERRORS_TOKEN, useValue: errors }],
    };
  }

  public static forChild(): ModuleWithProviders<MppInputModule> {
    return { ngModule: MppInputModule };
  }
}
