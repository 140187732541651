/* eslint-disable no-bitwise */
/**
 * ITU-R BT.709
 * https://en.wikipedia.org/wiki/Rec._709#Luma_coefficients
 * @param hex - color value
 *
 * @return value from 0 to 255
 */
export function getHexLuma(hex: string): number {
  const rgb = parseInt(hex.substring(1), 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}
