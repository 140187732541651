import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV_TOKEN, ERROR_CODES, ERROR_TYPES } from '@arturin/constants';
import { MppAccessTokenService } from './access-token.service';
import { IBaseEnvironment, TErrorResponse } from '../types';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class MppHttpErrorsHandlerService {
  public constructor(
    @Inject(ENV_TOKEN) private readonly env: IBaseEnvironment,
    private readonly toastrService: ToastrService,
    private readonly accessTokenService: MppAccessTokenService,
    private readonly translateService: TranslateService
  ) {}

  public handle(errorResponse: HttpErrorResponse): void {
    if (!navigator.onLine) {
      this.handleOfflineConnection();
      return;
    }

    switch (errorResponse.status) {
      case ERROR_CODES.serverError:
        this.handleServerError();
        break;

      case ERROR_CODES.unauthorized:
        if (this.accessTokenService.exist) {
          this.handleUnauthenticated();
          break;
        }

      default:
        this.handleProcessableResponse(errorResponse);
    }
  }

  private handleOfflineConnection(): void {
    this.toastrService.error(
      this.translateService.instant('COMMON.NOTIFICATION_MESSAGE.CONNECTION_LOST')
    );

    throw new Error('Connection to server lost');
  }

  private handleUnauthenticated(): void {
    this.accessTokenService.destroy();
    window.location.href = this.env.accessManagerUrl;
  }

  private handleProcessableResponse(errorResponse: HttpErrorResponse): void {
    const error = errorResponse.error as TErrorResponse;

    // @todo: remove this on first stabilization sprint cause we dont have such calls now
    // @todo: add specific handler before functionality will be written on sockets
    if (
      error.type === ERROR_TYPES.NOT_CREATED_YET ||
      error.type === ERROR_TYPES.MUTED ||
      error.type === ERROR_TYPES.SHOOT_IN ||
      !error.type
    ) {
      return;
    }

    error.intercepted = true;

    // @todo: check TErrorResponse and make message only string type on BE implementation
    const message = Array.isArray(error.message) ? error.message[0] : error.message;

    if (error.type === ERROR_TYPES.WARNING_INFO) {
      this.toastrService.warning(message);
    } else {
      this.toastrService.error(message || this.translateService.instant('COMMON.NOTIFICATION_MESSAGE.DEFAULT_ERROR_MESSAGE'));
    }
  }

  private handleServerError(): void {
    // @todo: discuss with Antony and Makshark additional handling logic
    this.toastrService.error(this.translateService.instant('COMMON.NOTIFICATION_MESSAGE.SERVER_ERROR'));
  }
}
