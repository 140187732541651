import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class MppUserSessionService {
  public constructor(private readonly http: HttpClient) {}

  public create$(
    credentials: { email: string; password: string },
    rememberMe: boolean
  ): Observable<string> {
    const body = {
      user: {
        ...credentials,
        rememberMe,
      },
    };

    return this.http
      .post<{ redirect_uri: string }>('api/v2/login', body, { withCredentials: true })
      .pipe(map((data) => data.redirect_uri));
  }

  public delete$(): Observable<void> {
    return this.http.delete<void>('api/v2/logout');
  }

  public getVisitorOriginInfo$(): Observable<HttpResponse<{ redirect_uri: string }>> {
    return this.http.post<{ redirect_uri: string }>('api/v2/login_back', {}, { observe: 'response' });
  }
}
