import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { map, take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RecaptchaComponent } from 'ng-recaptcha';
import { MppAutoUnsubscribeComponent } from '@arturin/core';
import { AmUserPasswordService } from '@am/_services/user-password.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'am-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  encapsulation: ViewEncapsulation.None,
  host: { class: 'access-container' },
})
export class AmForgotPasswordPageComponent extends MppAutoUnsubscribeComponent {
  @ViewChild(RecaptchaComponent)
  public readonly recaptcha: RecaptchaComponent;

  public form: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    recaptcha: [null, Validators.required],
  });

  public constructor(
    private readonly formBuilder: FormBuilder,
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService,
    private readonly userPasswordService: AmUserPasswordService,
    private readonly router: Router
  ) {
    super();
  }

  public onSubmitClick(): void {
    if (this.form.hasError('required', 'recaptcha')) {
      this.getCaptchaResult$().subscribe((success) => {
        if (!success) {
          const message: string = this.translateService.instant(
            'ROOT.FORGOT_PASSWORD_PAGE.CAPTCHA_ERROR'
          );

          this.toastrService.error(message);

          return;
        }

        setTimeout(() => this.sendMail(), 0);
      });
    } else {
      this.sendMail();
    }
  }

  private getCaptchaResult$(): Observable<boolean> {
    const result$ = this.recaptcha.resolved.pipe(map(Boolean), take(1), takeUntil(this.destroy$$));

    this.recaptcha.execute();

    return result$;
  }

  private sendMail(): void {
    if (this.form.hasError('email', 'email')) {
      const message: string = this.translateService.instant(
        'ROOT.FORGOT_PASSWORD_PAGE.ERROR_EMAIL_INVALID'
      );

      this.toastrService.error(message);
      return;
    }

    this.userPasswordService
      .sendResetLink$(this.form.value)
      .pipe(takeUntil(this.destroy$$))
      .subscribe(
        () => {
          const REDIRECT_TIMEOUT = 3000;

          const message: string = this.translateService.instant(
            'ROOT.FORGOT_PASSWORD_PAGE.SUCCESSFUL_ATTEMPT'
          );

          setTimeout(() => this.router.navigateByUrl('/'), REDIRECT_TIMEOUT);

          this.toastrService.success(message);
        },
        () => {
          this.form.setErrors({ badForgotAttempt: true });
        }
      );
  }
}
