import { TRegionDateFormat } from '../types';
import { MppUserRegions } from '../../../../enums';

export const DEFAULT_DATE_INPUT_FORMAT: TRegionDateFormat = {
  [MppUserRegions.US]: {
    mask: 'm0/d0/0000',
    date: 'MM/dd/yyyy',
    placeholder: 'EEE. dd MMM.',
  },
  [MppUserRegions.FR]: {
    mask: 'd0/m0/0000',
    date: 'dd/MM/yyyy',
    placeholder: 'EEE. dd MMM.',
  },
};

export const DEFAULT_MONTH_VIEW_FORMAT: TRegionDateFormat = {
  [MppUserRegions.US]: {
    mask: 'm0/0000',
    date: 'MM/yyyy',
    placeholder: 'MMM. yyyy',
  },
  [MppUserRegions.FR]: {
    mask: 'm0/0000',
    date: 'MM/yyyy',
    placeholder: 'MMM. yyyy',
  },
};

export const DEFAULT_YEAR_VIEW_FORMAT: TRegionDateFormat = {
  [MppUserRegions.US]: {
    mask: '0000',
    date: 'yyyy',
    placeholder: 'yyyy',
  },
  [MppUserRegions.FR]: {
    mask: '0000',
    date: 'yyyy',
    placeholder: 'yyyy',
  },
};
