import { transition, style, animate } from '@angular/animations';

const timings = '500ms cubic-bezier(.55,1.54,.49,1.06)';

export const expandAnimation = [
  transition(':enter', [
    style({ height: 0, opacity: 0 }),
    animate(timings, style({ height: '*', opacity: 1 })),
  ]),
  transition(':leave', [animate(timings, style({ height: 0, opacity: 0 }))]),
];
