import { Pipe, PipeTransform } from '@angular/core';
import { MppUtilitiesService } from '../services/utitities.service';

@Pipe({
  name: 'mppGroupDigits',
})
export class MppGroupDigitsPipe implements PipeTransform {
  private readonly DEFAULT_DIVIDER = ' ';

  public constructor(private readonly utilitiesService: MppUtilitiesService) {}

  public transform(value: number | null, divider: string = this.DEFAULT_DIVIDER): string {
    return this.utilitiesService.groupDigits(value, divider);
  }
}
