import { ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef } from '@angular/core';
import { TComponent } from '../../types';

@Component({
  selector: 'mpp-template-outlet',
  template:
    '<ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{ hide: hide }"></ng-container>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MppTemplateOutletComponent {
  @Input()
  public content: TemplateRef<TComponent<any>>;

  @Input()
  public hide: () => void;

  @HostBinding('style.z-index')
  public readonly Z_INDEX = 11;
}
