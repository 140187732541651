import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MppDirectivesModule } from '../../../directives';
import { MppTextareaComponent } from './text-area.component';
import { MppLanguageService } from '../../../services/language.service';
import { COMMON_ERRORS_TOKEN } from '../form-controls.constants';
import { MppButtonModule } from '../../button';

@NgModule({
  declarations: [MppTextareaComponent],
  imports: [CommonModule, ReactiveFormsModule, MppDirectivesModule, MppButtonModule],
  exports: [MppTextareaComponent],
  providers: [MppLanguageService],
})
export class MppTextareaModule {
  public static forRoot(errors: Record<string, string>): ModuleWithProviders<MppTextareaModule> {
    return {
      ngModule: MppTextareaModule,
      providers: [{ provide: COMMON_ERRORS_TOKEN, useValue: errors }],
    };
  }

  public static forChild(): ModuleWithProviders<MppTextareaModule> {
    return { ngModule: MppTextareaModule };
  }
}
