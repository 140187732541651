import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { isAfter, isDate, isEqual } from 'date-fns';

import { DEFAULT_STEP_MINUTES, DEFAULT_TIME_INPUT_FORMAT } from './time-range.constants';
import { MppTimeRangeService } from './services/time-range.service';
import { MppBaseRange } from '../base-range';
import { MppRange } from '../enums';
import { IDateFormat } from '../types';

@Component({
  selector: 'mpp-time-range',
  templateUrl: './time-range.component.html',
  styleUrls: ['./time-range.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-range' },
})
export class MppTimeRangeComponent extends MppBaseRange implements OnInit {
  @Input()
  public readonly minutesStep: number = DEFAULT_STEP_MINUTES;

  public timeSteps: Date[];

  public readonly range: typeof MppRange = MppRange;

  @HostBinding('class.invalid')
  public get isInvalid(): boolean {
    return this.control.invalid;
  }

  @HostBinding('class.disabled')
  public get isDisabled(): boolean {
    return this.control.disabled;
  }

  public get inputFormat(): IDateFormat {
    return this.format ? this.format[this.userRegion] : DEFAULT_TIME_INPUT_FORMAT[this.userRegion];
  }

  public constructor(
    elementRef: ElementRef<HTMLElement>,
    viewContainerRef: ViewContainerRef,
    changeDetectorRef: ChangeDetectorRef,
    private readonly timeRangeService: MppTimeRangeService
  ) {
    super(elementRef, viewContainerRef, changeDetectorRef);
  }

  public ngOnInit(): void {
    this.timeSteps = this.timeRangeService.getTimeSteps(this.minutesStep);

    super.ngOnInit();
  }

  public isAvailableTime(date: Date): boolean {
    return this.isStartFocused || (isDate(this.startDate) && isAfter(date, this.startDate as Date));
  }

  protected startDateFormatter(date: Date): Date {
    return date;
  }

  protected endDateFormatter(date: Date): Date {
    return date;
  }

  protected get dropdownWidth(): number | null {
    return this.elementRef.nativeElement.clientWidth;
  }

  protected isDateMatches(start: Date | null, end: Date | null): boolean {
    if (!isDate(start) || !isDate(end)) {
      return false;
    }

    return isEqual(start!, end!);
  }
}
