import { Component, ContentChild, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mpp-filters-bar',
  templateUrl: './filters-bar.component.html',
  styleUrls: ['./filters-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-filters-bar' },
})
export class MppFiltersBarComponent {
  @ContentChild('searchTemplate')
  public readonly searchTemplate: TemplateRef<any>;

  @ContentChild('actionsTemplate')
  public readonly actionsTemplate: TemplateRef<any>;
}
