import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MppSummaryCounterComponent } from './summary-counter.component';
import { MppPipesModule } from '../../pipes';

@NgModule({
  imports: [CommonModule, TranslateModule, MppPipesModule],
  declarations: [MppSummaryCounterComponent],
  exports: [MppSummaryCounterComponent],
})
export class MppSummaryCounterModule {}
