import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';

@Injectable()
export class MppCleanHttpClientFactory {
  private cleanHttpClient: HttpClient;

  public constructor(private readonly httpBackend: HttpBackend) {}

  public create(): HttpClient {
    if (!this.cleanHttpClient) {
      this.cleanHttpClient = new HttpClient(this.httpBackend);
    }

    return this.cleanHttpClient;
  }
}
