import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { merge } from 'rxjs';
import { last, startWith, takeUntil } from 'rxjs/operators';
import { MppBaseDropdownComponent } from '../base-dropdown.component';
import { MppOptionComponent } from '../_components/option/option.component';
import { TSearchFn } from '../_types';

@Component({
  selector: 'mpp-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MppMultiSelectComponent
  extends MppBaseDropdownComponent
  implements AfterContentInit, AfterViewInit, OnDestroy
{
  @Input() public readonly control: FormControl;

  @Input() public readonly searchFn: TSearchFn;

  @Input() public readonly selectAllLabel: string;

  @Input() public readonly placeholder: string;

  @Input() public readonly hasMinSelectRestriction: boolean = false;

  @ContentChildren(MppOptionComponent)
  public readonly optionsQueryList: QueryList<MppOptionComponent>;

  public isSelectAllChecked = false;

  public options: MppOptionComponent[];

  protected readonly last = last;

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef<HTMLElement>,
    viewContainerRef: ViewContainerRef
  ) {
    super(changeDetectorRef, elementRef, viewContainerRef);
  }

  public ngAfterContentInit(): void {
    this.initValueChangesListener();

    this.optionsQueryList.changes
      .pipe(startWith(this.optionsQueryList), takeUntil(this.destroy$$))
      .subscribe((optionsQueryList: QueryList<MppOptionComponent>) => {
        this.options = optionsQueryList.toArray();
      });
  }

  public selectAll(): void {
    const allValues = this.options.map((option) => option.value);
    this.control.setValue(allValues);
  }

  public unselectAll(): void {
    this.control.setValue(this.hasMinSelectRestriction ? [this.options[0].value] : []);
  }

  public onControlClose(): void {
    this.isOpen = false;
    this.onClose.emit();
  }

  private initValueChangesListener(): void {
    const valueChanges$ = this.control.valueChanges.pipe(startWith(this.control.value));

    merge(valueChanges$, this.optionsQueryList.changes)
      .pipe(takeUntil(this.destroy$$))
      .subscribe((value) => {
        this.isSelectAllChecked = value.length === this.optionsQueryList.length;
      });
  }
}
