<div class="select-icons-panel__search-icon icon icon-search-outdated">
</div>

<button class="select-icons-panel__clear-button icon icon-close-outdated"
        (click)="onClearSearchClick.emit()"
        type="button">
</button>

<button class="select-icons-panel__dropdown-button icon icon-arrow-down-outdated"
        type="button">
</button>
