import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'mpp-tab',
  template: '',
  styleUrls: ['./tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'mpp-tab' },
})
export class MppTabComponent implements OnChanges {
  @Input() public readonly disabled: boolean;
  @Input() public readonly error: boolean;
  @Input() public readonly label: string;

  @Output() public readonly onChange: EventEmitter<void> = new EventEmitter();

  @ContentChild('labelTemplate', { read: TemplateRef })
  public readonly labelTemplate: TemplateRef<any>;

  @ContentChild('contentTemplate', { read: TemplateRef })
  public readonly templateRef: TemplateRef<any>;

  public ngOnChanges(): void {
    this.onChange.emit();
  }
}
