import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import {
  DEBOUNCE_TIME_VALUE_CHANGES,
  MATCHED_OPTION_CLASS,
  NO_ITEMS_FOUND_CLASS,
  VISIBLE_OPTION_CLASS,
} from './group-multi-select.constants';
import { TSearchFn } from '../_types';
import { MppBaseDropdownComponent } from '../base-dropdown.component';

@Component({
  selector: 'mpp-group-multi-select',
  templateUrl: './group-multi-select.component.html',
  styleUrls: ['./group-multi-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MppGroupMultiSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'group-multiselect' },
})
export class MppGroupMultiSelectComponent
  extends MppBaseDropdownComponent
  implements ControlValueAccessor, AfterViewInit, OnInit, OnDestroy
{
  @Input() public readonly items: any[];

  @Input() public readonly searchFn: TSearchFn;

  @Input() public readonly placeholder: string;

  @Input() public readonly groupBy: string;

  @Input() public readonly valueField: string;

  @Input() public readonly labelField: string;

  @Input() public readonly selectAllLabel: string;

  @ContentChild('optionTemplate')
  public readonly optionTemplate: TemplateRef<{ item: any; search: string }>;

  @ContentChild('groupOptionTemplate')
  public readonly groupOptionTemplate: TemplateRef<{ item: any }>;

  public expandedGroupIds: Set<any> = new Set();

  public uniqueSelectedItems: any[] = [];

  public innerValue: any[] = [];

  public isSelectAllChecked = false;
  public isInitialized = false;

  private readonly valueChanges$$: Subject<any[]> = new Subject();

  private onTouched: () => void;

  private onChange: (value: any[]) => void;

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef<HTMLElement>,
    viewContainerRef: ViewContainerRef
  ) {
    super(changeDetectorRef, elementRef, viewContainerRef);
  }

  public ngOnInit(): void {
    this.valueChanges$$
      .pipe(
        debounceTime(DEBOUNCE_TIME_VALUE_CHANGES),
        map((values: any[]) => values.filter((value, index) => values.indexOf(value) === index)),
        distinctUntilChanged(
          (prevValue, newValue) => prevValue.sort().toString() === newValue.sort().toString()
        ),
        takeUntil(this.destroy$$)
      )
      .subscribe((uniqueIds) => {
        this.uniqueSelectedItems = this.getUniqueSelectedItems(uniqueIds);
        this.isSelectAllChecked = this.isAllOptionsSelected();

        this.changeDetectorRef.markForCheck();

        if (this.isInitialized) {
          this.onChange(uniqueIds);
        }

        this.isInitialized = true;
      });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.valueChanges$$.complete();
  }

  public isSelectAllIndeterminate(): boolean {
    if (this.isSelectAllChecked) {
      return false;
    }

    return this.selectComponent.itemsList.items
      .filter((option) => !!option.parent)
      .some((option) => option.selected);
  }

  public selectAll(): void {
    const items = this.selectComponent.itemsList.items.filter((option) => !option.disabled);

    items.forEach((item) => this.selectComponent.select(item));
  }

  public unselectAll(): void {
    const items = this.selectComponent.itemsList.items.filter((option) => !option.disabled);

    items.forEach((item) => this.selectComponent.unselect(item));
  }

  public isGroupOptionIndeterminate(option: NgOption): boolean {
    return !option.selected && option.children!.some((childOption) => childOption.selected);
  }

  public onGroupOptionClick(event: Event, option: NgOption): void {
    event.preventDefault();

    const duplicateChildOptions = option
      .children!.map((childOption) => this.getDuplicateOptions(childOption))
      .reduce((prevValue, currValue) => prevValue.concat(currValue), [])
      .filter((childOption) => childOption.parent !== option && !childOption.disabled);

    if (option.selected) {
      duplicateChildOptions.forEach((childOption) => this.selectComponent.unselect(childOption));
    } else {
      duplicateChildOptions.forEach((childOption) => this.selectComponent.select(childOption));
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public writeValue(value: any): void {
    if (!Array.isArray(value)) {
      throw new Error('Array must be passed');
    }

    this.onNgModelChange(value);
  }

  public onGroupVisibilityToggleClick(event: Event, option: NgOption): void {
    event.stopPropagation();

    const childOptionElements = this.getChildOptionElements(option);
    const groupValue = this.getOptionValue(option);

    if (this.expandedGroupIds.has(groupValue)) {
      this.expandedGroupIds.delete(groupValue);

      childOptionElements.forEach((element) => element.classList.remove(VISIBLE_OPTION_CLASS));
    } else {
      this.expandedGroupIds.add(groupValue);

      childOptionElements.forEach((element) => element.classList.add(VISIBLE_OPTION_CLASS));
    }
  }

  public onOptionClick(event: Event, option: NgOption): void {
    event.preventDefault();

    const duplicateOptions = this.getDuplicateOptions(option);

    if (option.selected) {
      duplicateOptions.forEach((duplicateOption) => this.selectComponent.unselect(duplicateOption));
    } else {
      duplicateOptions.forEach((duplicateOption) => this.selectComponent.select(duplicateOption));
    }
  }

  public getOptionValue(option: NgOption): any {
    return option.value![this.valueField];
  }

  public onControlClose(): void {
    this.isOpen = false;
    this.onClose.emit();

    this.isSearching = false;

    this.expandedGroupIds.clear();

    this.onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onSearch(term: string): void {
    super.onSearch(term);

    if (this.selectComponent.itemsList.filteredItems.length) {
      this.dropdownPanelElement!.classList.remove(NO_ITEMS_FOUND_CLASS);
    } else {
      this.dropdownPanelElement!.classList.add(NO_ITEMS_FOUND_CLASS);
    }

    if (this.isSearching) {
      setTimeout(() => this.hideDuplicateSearchOptions(), 0);
    } else {
      setTimeout(() => this.showExpandedGroups(), 0);
    }
  }

  public getGroupSelectedCounter(groupOption: NgOption): number {
    return groupOption.children!.filter((option) => option.selected).length;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onNgModelChange(value: any): void {
    this.valueChanges$$.next(value);
    this.innerValue = value;
  }

  public onControlOpen(): void {
    super.onControlOpen();
    this.selectAllDuplicateOptions();
  }

  private getUniqueSelectedItems(uniqueIds: number[]): any[] {
    return uniqueIds.map((uniqueId) => {
      const selectedOption = this.selectComponent.itemsList.items
        .filter((option) => option.parent)
        .find((option) => this.getOptionValue(option) === uniqueId)!;

      return selectedOption.value!;
    });
  }

  private isAllOptionsSelected(): boolean {
    const uniqueOptions = this.items
      .map((groupItem) => {
        const childOptions: any[] = groupItem[this.groupBy];

        return childOptions.map((childOption) => childOption[this.valueField]);
      })
      .reduce((prevValue, currValue) => prevValue.concat(currValue), [])
      .filter((value, index, values) => values.indexOf(value) === index);

    return this.uniqueSelectedItems.length === uniqueOptions.length;
  }

  private getChildOptionElements(groupOption: NgOption): HTMLElement[] {
    return groupOption.children!.map((childOption) => {
      const elementId = `#${groupOption.htmlId!} ~ #${childOption.htmlId!}`;

      return document.querySelector<HTMLElement>(elementId)!;
    });
  }

  private getDuplicateOptions(originOption: NgOption): NgOption[] {
    const originValue = this.getOptionValue(originOption);

    return this.selectComponent.itemsList.items.filter(
      (option) =>
        option.parent && option !== originOption && this.getOptionValue(option) === originValue
    );
  }

  private showExpandedGroups(): void {
    this.selectComponent.itemsList.items
      .filter((option) => option.children && this.expandedGroupIds.has(this.getOptionValue(option)))
      .map((expandedGroupOption) => this.getChildOptionElements(expandedGroupOption))
      .reduce((prevValue, currValue) => prevValue.concat(currValue), [])
      .forEach((childOptionElement) => childOptionElement.classList.add(VISIBLE_OPTION_CLASS));
  }

  private hideDuplicateSearchOptions(): void {
    const uniqueValues: any[] = [];

    this.selectComponent.itemsList.filteredItems
      .filter((option) => option.children)
      .forEach((groupOption) => {
        const childOptionElements = this.getChildOptionElements(groupOption);

        childOptionElements.filter(Boolean).forEach((childOptionElement, index) => {
          const optionValue = this.getOptionValue(groupOption.children![index]);

          if (uniqueValues.includes(optionValue)) {
            childOptionElement.classList.remove(MATCHED_OPTION_CLASS);
            return;
          }

          childOptionElement.classList.add(MATCHED_OPTION_CLASS);
          uniqueValues.push(optionValue);
        });
      });
  }

  private selectAllDuplicateOptions(): void {
    this.selectComponent.itemsList.items
      .filter((option) => option.parent && option.selected)
      .map((childOption) => this.getDuplicateOptions(childOption))
      .reduce((prevValue, currValue) => prevValue.concat(currValue), [])
      .forEach((duplicateOption) => this.selectComponent.select(duplicateOption));
  }
}
