<mpp-image-add *ngIf="!image"
               [size]="size"
               (click)="fileInput.click()"
>{{ addImageText }}
</mpp-image-add>

<mpp-image-card *ngIf="!!image"
                [size]="size"
                [image]="image"
                [badge]="badge"
                [action]="action"
                [isRemovable]="isRemovable"
                (click)="fileInput.click()"
                (onRemove)="onRemove.emit()"
></mpp-image-card>

<div class="mpp-image-picker__spinner-wrapper">
  <mpp-spinner class="mpp-image-picker__spinner" type="stretched"></mpp-spinner>
</div>

<input type="file"
       hidden
       #fileInput
       (change)="onFileChange($event)"
       [accept]="acceptedMimeTypes.join(',')"
       [multiple]="false" />


