import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export const RESET_PASSWORD_TOKEN_PARAM_NAME = 'token';

export const MIN_PASSWORD_LENGTH = 6;

export const PASSWORD_EQUALITY_FORM_VALIDATOR: ValidatorFn = (
  form: FormGroup
): ValidationErrors | null =>
  form.controls.password.value !== form.controls.passwordConfirmation.value
    ? { mismatch: true }
    : null;
