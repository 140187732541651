<input class="mpp-phone-input__control"
       type="tel"
       [formControl]="control"
       [placeholder]="placeholderText"
       [value]="control.value | mppPhoneNumberFormat: phoneNumberSettings.alpha2CountryCode"
       (input)="onTyping.emit($event)"
       (change)="onChange.emit($event)"
       (focus)="onFocus.emit($event)"
       (blur)="onBlur.emit($event)"
>

<div class="mpp-phone-input__error-icon icon icon-alert-circle-filled"
     mppTooltip
     [content]="errorMessage"
></div>

