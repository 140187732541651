import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MppAutoUnsubscribeComponent } from '../../../../../helpers/auto-unsubscribe-component';
import { IDateFormat } from '../../types';
import { FormControl } from '@angular/forms';
import { format, parse } from 'date-fns';

@Component({
  selector: 'mpp-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-date-time-input' },
})
export class MppDateTimeInputComponent extends MppAutoUnsubscribeComponent {
  @Input()
  public set value(date: Date | null) {
    const formattedValue = date && this.format ? format(date, this.format.date) : '';

    this._value = date;
    this.control.setValue(formattedValue);
  }

  public get value(): Date | null {
    return this._value;
  }

  @Input()
  @HostBinding('class.centered')
  public readonly isCentered: boolean = true;

  @Input()
  public readonly format: IDateFormat;

  @Input()
  public readonly placeholder: string = '';

  @Output()
  public readonly onChange: EventEmitter<Date> = new EventEmitter();

  @Output()
  public readonly onFocus: EventEmitter<void> = new EventEmitter();

  @Output()
  public readonly onTyping: EventEmitter<string> = new EventEmitter();

  @Output()
  public readonly onBlur: EventEmitter<void> = new EventEmitter();

  @ViewChild('input', { static: true })
  private readonly inputRef: ElementRef<HTMLInputElement>;

  public readonly control: FormControl = new FormControl();

  public isFocused = false;

  private _value: Date | null;

  public focus(): void {
    this.inputRef.nativeElement.focus();
  }

  public blur(): void {
    this.isFocused = false;
    this.inputRef.nativeElement.blur();
  }

  public onInputChange(): void {
    const newDate = this.value || new Date();

    const date = parse(this.inputRef.nativeElement.value, this.format.date, newDate);

    this.onChange.emit(date);
  }

  public onInputFocus(): void {
    if (this.isFocused) {
      return;
    }

    this.onFocus.emit();
    this.isFocused = true;
  }

  public onInputBlur(): void {
    if (!this.isFocused) {
      this.onBlur.emit();

      return;
    }

    this.inputRef.nativeElement.focus();
  }
}
