export enum MppButtonThemes {
  BLACK = 'black',
  BLUE = 'blue',
  GREEN = 'green',
  GREY = 'grey',
  ORANGE = 'orange',
  PURPLE = 'purple',
  RED = 'red',
  SECONDARY = 'secondary',
  WHITE = 'white',
  YELLOW = 'yellow',
}
