import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { Toast } from 'ngx-toastr';

@Component({
  selector: 'mpp-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-toast-message icon' },
})
export class MppToastMessageComponent extends Toast {
  @HostListener('click')
  public onHostClick(): void {
    this.remove();
  }
}
