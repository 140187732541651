import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MppAccessTokenService } from '../services/access-token.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MppLocaleHeaderInterceptor implements HttpInterceptor {
  public constructor(
    private readonly accessTokenService: MppAccessTokenService,
    private readonly translateService: TranslateService
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const updatedRequest = this.accessTokenService.exist
      ? req.clone({
          setHeaders: { 'X-Internationalization-Locale': this.translateService.currentLang },
        })
      : req;

    return next.handle(updatedRequest);
  }
}
