import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mpp-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'mpp-user-avatar' },
})
export class MppUserAvatarComponent {
  @Input()
  public readonly url: string | null | undefined;

  @Input()
  public readonly name: string | null | undefined;
}
