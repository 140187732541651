import { Injectable } from '@angular/core';

@Injectable()
export class MppUtilitiesService {
  private readonly DEFAULT_DIVIDER = ' ';

  public groupDigits(value: number | null, divider: string = this.DEFAULT_DIVIDER): string {
    const valueStr: string = value !== null ? value.toString() : '';
    const regex = new RegExp(/(\d)(?=(\d{3})+$)/g);
    return valueStr.replace(
      regex,
      (_, digit, group) => digit + (group.length % 3 === 0 ? divider : '')
    );
  }
}
