import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MppButtonModule } from '../../button';
import { MppConfirmationDialogComponent } from './confirmation-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MppConfirmationDialogComponent],
  imports: [CommonModule, MppButtonModule, TranslateModule],
  exports: [MppConfirmationDialogComponent],
})
export class MppConfirmationDialogModule {}
