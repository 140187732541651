import { animate, style, transition } from '@angular/animations';

const ANIMATION_TIMINGS = '0.3s cubic-bezier(.74,.09,.21,.94)';

export const EMPTY_CLASS = 'content-empty';

export const EXPAND_ANIMATION = [
  transition(':leave', animate(ANIMATION_TIMINGS, style({ width: 0, padding: 0, opacity: 0 }))),

  transition(':enter', [
    style({ width: 0, padding: 0, opacity: 0 }),
    animate(ANIMATION_TIMINGS, style({ width: '*', padding: '*', opacity: 1 })),
  ]),
];
