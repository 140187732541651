import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mpp-placeholder-section',
  templateUrl: './placeholder-section.component.html',
  styleUrls: ['./placeholder-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'mpp-placeholder-section' },
})
export class MppPlaceholderSectionComponent {
  @Input()
  public readonly headline: string;

  @Input()
  public readonly description: string;
}
