<div class="mpp-calendar__container">
  <div class="mpp-calendar__header">
    <button class="mpp-calendar__title"
            (click)="onChangeView()"
    >
      <ng-container *ngIf="activeViewPanel !== viewPanels.YEARS">
        {{ currentDate | mppDateFormat: titleFormat }}
      </ng-container>

      <ng-container *ngIf="activeViewPanel === viewPanels.YEARS">
        {{ decadeBoundaries[range.START] | mppDateFormat: titleFormat }}
        -
        {{ decadeBoundaries[range.END] | mppDateFormat: titleFormat }}
      </ng-container>
    </button>

    <div class="mpp-calendar__buttons">
      <button class="mpp-calendar__button icon icon-chevron-left-outlined"
              (click)="onBackward()"
      ></button>

      <button class="mpp-calendar__button icon icon-chevron-right-outlined"
              (click)="onForward()"
      ></button>
    </div>
  </div>

  <div class="mpp-calendar__content" [ngSwitch]="activeViewPanel">
    <div class="mpp-days" *ngSwitchCase="viewPanels.DAYS">
      <div class="mpp-days__header">
        <div class="mpp-days__week secondary" *ngFor="let day of daysOfWeek">
          {{ day | mppDateFormat: WEEK_DAY_FORMAT }}
        </div>
      </div>

      <div class="mpp-days__content">
        <mpp-day *ngFor="let date of days"
                 [date]="date"
                 [format]="DAY_FORMAT"
                 [isDisabled]="isDisabledDate(date)"
                 [isSecondary]="!isSameMonth(date, currentDate)"
                 [isActive]="isSelectedDate(date)"
                 [isStart]="isStartDay(date)"
                 [isEnd]="isEndDay(date)"
                 (click)="onDateSelect(date)"
        ></mpp-day>
      </div>
    </div>

    <div class="mpp-raised-range" *ngSwitchCase="viewPanels.MONTHS">
      <div class="mpp-raised-range__item"
           *ngFor="let date of months"
           (click)="onDateSelect(date)"
           [class.current]="isThisMonth(date) && isThisYear()"
           [class.disabled]="isDisabledDate(date)"
      >{{ date | mppDateFormat: MONTH_FORMAT }}</div>
    </div>

    <div class="mpp-raised-range" *ngSwitchCase="viewPanels.YEARS">
      <div class="mpp-raised-range__item"
           *ngFor="let date of years"
           (click)="onDateSelect(date)"
           [class.current]="isSameYear(date)"
           [class.disabled]="isDisabledDate(date)"
      >{{ date | mppDateFormat: YEAR_FORMAT }}</div>
    </div>
  </div>
</div>
