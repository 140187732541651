import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { TComponent } from '../types';

@Injectable()
export class MppComponentInitializerService {
  public constructor(
    private readonly appRef: ApplicationRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver
  ) {}

  public create<C>(
    component: TComponent<C>,
    injector: Injector,
    containerNode: HTMLElement
  ): ComponentRef<C> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    const componentRef = componentFactory.create(injector);

    const componentRootNode = this.getComponentRootNode(componentRef);

    this.appRef.attachView(componentRef.hostView);
    containerNode.appendChild(componentRootNode);

    return componentRef;
  }

  public destroy<C>(componentRef: ComponentRef<C>): void {
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }

  private getComponentRootNode(componentRef: ComponentRef<any>): HTMLElement {
    return (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0];
  }
}
