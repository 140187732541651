import { ModuleWithProviders, NgModule } from '@angular/core';
import { COMMON_ERRORS_TOKEN } from './form-controls.constants';
import { MppFormFieldModule } from './form-field';
import { MppInputModule } from './input';
import { MppPhoneInputModule } from './phone-input';
import { MppTextareaModule } from './text-area';
import { MppSimpleSelectModule } from './simple-select';
import { MppTagSelectModule } from './tag-select';
import { MppGroupTagSelectModule } from './group-tag-select';
import { MppDatePickerModule, MppDateRangeModule, MppTimeRangeModule } from './date-time-picker';
import { TCommonErrors } from './types/common-errors';

@NgModule({
  imports: [
    MppFormFieldModule,
    MppInputModule,
    MppPhoneInputModule,
    MppTextareaModule,
    MppSimpleSelectModule,
    MppTagSelectModule,
    MppTimeRangeModule.forRoot(),
    MppDateRangeModule.forRoot(),
    MppDatePickerModule.forRoot(),
  ],
  exports: [
    MppFormFieldModule,
    MppInputModule,
    MppPhoneInputModule,
    MppTextareaModule,
    MppSimpleSelectModule,
    MppTagSelectModule,
    MppGroupTagSelectModule,
    MppTimeRangeModule,
    MppDateRangeModule,
    MppDatePickerModule,
  ],
  declarations: [],
})
export class MppFormControlsModule {
  public static forRoot(errors: TCommonErrors): ModuleWithProviders<MppFormControlsModule> {
    return {
      ngModule: MppFormControlsModule,
      providers: [{ provide: COMMON_ERRORS_TOKEN, useValue: errors }],
    };
  }

  public static forChild(): ModuleWithProviders<MppFormControlsModule> {
    return { ngModule: MppFormControlsModule };
  }
}
