import { IEmployeeInfo } from './_types';

export const EMPLOYEES_INFO: IEmployeeInfo[] = [
  {
    imageUrl: '/assets/images/company-employees/paul.jpg',
    name: 'Paul Lemonnier',
    jobTitle: 'ROOT.EMPLOYEE_INFO.DIGITAL_MARKETING_JOB_TITLE',
    quote: 'ROOT.EMPLOYEE_INFO.PAUL_QUOTE',
  },
  {
    imageUrl: '/assets/images/company-employees/la.jpg',
    name: 'Louis-Adrien Mercier',
    jobTitle: 'ROOT.EMPLOYEE_INFO.CUSTOMER_SUCCESS_JOB_TITLE',
    quote: 'ROOT.EMPLOYEE_INFO.LOUIS_QUOTE',
  },
  {
    imageUrl: '/assets/images/company-employees/daphne.jpg',
    name: 'Daphné de Calbiac',
    jobTitle: 'ROOT.EMPLOYEE_INFO.HUMAN_RESOURCES_JOB_TITLE',
    quote: 'ROOT.EMPLOYEE_INFO.DAPHNE_QUOTE',
  },
  {
    imageUrl: '/assets/images/company-employees/theo.jpg',
    name: 'Theophile Barrabé',
    jobTitle: 'ROOT.EMPLOYEE_INFO.FIELD_MARKETING_JOB_TITLE',
    quote: 'ROOT.EMPLOYEE_INFO.THEOPHILE_QUOTE',
  },
  {
    imageUrl: '/assets/images/company-employees/quentin.jpg',
    name: 'Quentin Delame-Lelievre',
    jobTitle: 'ROOT.EMPLOYEE_INFO.CUSTOMER_SUCCESS_JOB_TITLE',
    quote: 'ROOT.EMPLOYEE_INFO.QUENTIN_QUOTE',
  },
  {
    imageUrl: '/assets/images/company-employees/cedric.jpg',
    name: 'Cedric Thijsman',
    jobTitle: 'ROOT.EMPLOYEE_INFO.CUSTOMER_SUCCESS_JOB_TITLE',
    quote: 'ROOT.EMPLOYEE_INFO.CEDRIC_QUOTE',
  },
  {
    imageUrl: '/assets/images/company-employees/mathilde.jpg',
    name: 'Mathilde Violet',
    jobTitle: 'ROOT.EMPLOYEE_INFO.CUSTOMER_SUCCESS_JOB_TITLE',
    quote: 'ROOT.EMPLOYEE_INFO.MATHILDE_QUOTE',
  },
  {
    imageUrl: '/assets/images/company-employees/estelle.jpg',
    name: 'Estelle Arvor',
    jobTitle: 'ROOT.EMPLOYEE_INFO.CUSTOMER_SUCCESS_JOB_TITLE',
    quote: 'ROOT.EMPLOYEE_INFO.ESTELLE_QUOTE',
  },
];

export const LOGIN_ERRORS_MAP: Record<string, string> = {
  email: 'ROOT.LOGIN_PAGE.ERROR_EMAIL_INVALID',
  required: 'ROOT.LOGIN_PAGE.ERROR_EMAIL_INVALID',
  invalidAttempt: 'ROOT.LOGIN_PAGE.ERROR_INVALID_ATTEMPT',
};
