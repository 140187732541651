export enum MppTypography {
  HEADLINE_1 = 'headline_1',
  HEADLINE_2 = 'headline_2',
  HEADLINE_3 = 'headline_3',
  HEADLINE_4 = 'headline_4',
  HEADLINE_5 = 'headline_5',
  HEADLINE_6 = 'headline_6',
  SUBTITLE_1 = 'subtitle_1',
  SUBTITLE_2 = 'subtitle_2',
  SUBTITLE_3 = 'subtitle_3',
  PARAGRAPH_1 = 'paragraph_1',
  PARAGRAPH_2 = 'paragraph_2',
  CAPTION_1 = 'caption_1',
  CAPTION_2 = 'caption_2',
  CAPTION_3 = 'caption_3',
  LABEL_1 = 'label_1',
}
