<ng-template #tagsCountTemplate>
  <div class="mpp-group-tags-count" [hidden]="!hiddenTags.length">
    {{ hiddenTags.length }}
  </div>
</ng-template>

<ng-template #errorIconTemplate>
  <span class="mpp-group-select-error icon icon-alert-circle-filled"
        mppTooltip
        [hidden]="!isInvalid"
        [content]="errorMessage"
  ></span>
</ng-template>

<ng-select class="mpp-lib"
           data-type="tag-select"
           [formControl]="control"
           [items]="listItems"
           [clearable]="false"
           [clearOnBackspace]="false"
           [multiple]="true"
           [hideSelected]="true"
           [readonly]="readonly"
           [isOpen]="isOpen"
           [bindValue]="bindValue"
           [bindLabel]="bindLabel"
           [groupBy]="groupBy"
           [maxSelectedItems]="maxSelectedItems"
           appendTo="body"
           [notFoundText]="notFoundText"
           [placeholder]="placeholderText"
           (search)="onType($event.term)"
           (add)="onAdd($event)"
           (remove)="onRemove($event.value)"
           (blur)="onSelectBlur()"
           (focus)="onSelectFocus()"
           (change)="onSelectChange()"
>
  <ng-template ng-multi-label-tmp
               let-clear="clear"
               let-listItems="items"
  >
    <div class="mpp-group-tags-buffer">
      <div class="mpp-group-tag" *ngFor="let item of listItems">
        <div class="mpp-group-tag__content">{{ item[bindLabel] }}</div>

        <div class="mpp-group-tag__close-button icon icon-close-bold"></div>
      </div>
    </div>

    <div class="mpp-group-tags-container">
      <div class="mpp-group-tag" *ngFor="let item of listItems">
        <div class="mpp-group-tag__content">{{ item[bindLabel] }}</div>

        <div class="mpp-group-tag__close-button icon icon-plus-bold"
             (mousedown)="$event.stopPropagation();clear(item)"
        ></div>
      </div>
    </div>
  </ng-template>

  <ng-template *ngIf="optionsGroupTemplate" ng-optgroup-tmp let-item="item">
    <ng-container [ngTemplateOutlet]="optionsGroupTemplate"
                  [ngTemplateOutletContext]="{ item: item }"
    ></ng-container>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    <div class="ng-option-label" *ngIf="!optionTemplate" [ngOptionHighlight]="search">
      {{ item[bindLabel] }}
    </div>

    <ng-container *ngIf="optionTemplate"
                  [ngTemplateOutlet]="optionTemplate"
                  [ngTemplateOutletContext]="{ item: item, search: search }"
    ></ng-container>
  </ng-template>
</ng-select>
