import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MppDateRangeComponent } from './date-range.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MppPipesModule } from '../../../../pipes';
import { MppCalendarModule } from '../components/calendar/calendar.module';
import { MppSimpleSelectModule } from '../../simple-select';
import { MppDateTimeInputModule } from '../components/date-time-input/date-time-input.module';

@NgModule({
  declarations: [MppDateRangeComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MppPipesModule,
    MppCalendarModule,
    MppSimpleSelectModule,
    MppDateTimeInputModule,
  ],
  exports: [MppDateRangeComponent],
})
export class MppDateRangeModule {
  public static forRoot(): ModuleWithProviders<MppDateRangeModule> {
    return {
      ngModule: MppDateRangeModule,
    };
  }

  public static forChild(): ModuleWithProviders<MppDateRangeModule> {
    return {
      ngModule: MppDateRangeModule,
    };
  }
}
