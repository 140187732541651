import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { MppMultiSelectComponent } from './multi-select/multi-select.component';
import { MppOptionComponent } from './_components/option/option.component';
import { MppSimpleSelectComponent } from './simple-select/simple-select.component';
import { MppSelectIconsPanelComponent } from './_components/icons-panel/icons-panel.component';
import { MppGroupMultiSelectComponent } from './group-multi-select/group-multi-select.component';
import { MppCheckboxModule } from '../checkbox';

// TODO: Move separate dropdowns module to the MppFormControlsModule
@NgModule({
  declarations: [
    MppMultiSelectComponent,
    MppOptionComponent,
    MppSimpleSelectComponent,
    MppSelectIconsPanelComponent,
    MppGroupMultiSelectComponent,
  ],
  imports: [
    CommonModule,
    MppCheckboxModule,
    NgSelectModule,
    NgOptionHighlightModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
  ],
  exports: [
    MppMultiSelectComponent,
    MppOptionComponent,
    MppSimpleSelectComponent,
    MppSelectIconsPanelComponent,
    MppGroupMultiSelectComponent,
  ],
})
export class MppDropdownsModule {}
