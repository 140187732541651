export enum MppActivityErrorType {
  MISSING_VARIABLES = 'missing_variables',
  CREDENTIALS = 'credentials',
  TOO_MANY_RETRY = 'too_many_retry',
  EXECUTION_EXPIRED = 'execution_expired',
  TIMEOUT = 'timeout',
  SYSTEM_ERROR = 'system_error',

  FB_AUTHORIZATION_VIDEO = 'fb_authorization_video',
  FB_PERMISSION_UNPUBLISHED_POST = 'fb_permission_unpublished_post',
  FB_MISSING_UPLOAD_FILE = 'fb_missing_upload_file',
  FB_TOO_MUCH_DATA = 'fb_too_much_data',
  FB_UNKNOWN_ERROR = 'fb_unknow_error',
  FB_INTERNAL_ERROR = 'fb_internal_error',
  FB_INVALID_IMAGE = 'fb_invalid_image',
  FB_AUTHORIZATION_SHARING = 'fb_authorization_sharing',
  FB_USER_NOT_OWNER = 'fb_user_not_owner',
  FB_UNEXPECTED_TOKEN = 'fb_unexpected_token',
  FB_VIDEO_FETCHING = 'fb_video_fetching',

  INSTA_AUTHORIZATION = 'insta_authorization',
  INSTA_NOT_CONFIRMED_USER = 'insta_not_confirmed_user',
  INSTA_RESTRICTED_USER = 'insta_restricted_user',
  INSTA_TOO_MANY_TAGS = 'insta_too_many_tags',
  INSTA_INVALID_PARAMETER = 'insta_invalid_parameter',
  INSTA_UNSUPPORTED_POST_REQUEST = 'insta_unsupported_post_request',
  INSTA_NOT_LOGGED = 'insta_not_logged',
  INSTA_NOT_PHOTO_NOR_VIDEO = 'insta_not_photo_nor_video',
  INSTA_UNAUTHORIZATION_ACTION = 'insta_unauthorization_action',
  INSTA_NOT_BUSINESS = 'insta_not_business',
  INSTA_TOO_MANY_ACTIONS = 'insta_too_many_actions',
  INSTA_IMAGE_SIZE = 'insta_image_size',
  INSTA_REQUEST_LIMIT_REACHED = 'insta_request_limit_reached',
  INSTA_VIDEO_FORMAT_NOT_SUPPORTED = 'insta_video_format_not_supported',
  INSTA_SESSION_CHANGED = 'insta_session_changed',

  LK_500_ERROR = 'lk_500_error',
  LK_AUTHORIZATION_IMAGE = 'lk_authorization_image',
  LK_503_ERROR = 'lk_503_error',
  LK_AUTHORIZATION = 'lk_authorization',
  LK_MEMBER_RESTRICTED = 'lk_member_restricted',
  LK_SSL_CERTIFICATE = 'lk_ssl_certificate',
  LK_AUTHORIZATION_VIDEO = 'lk_authorization_video',
  LK_TOKEN_REVOKED = 'lk_token_revoked',
  LK_SSL_ERROR = 'lk_ssl_error',
  LK_CONNECTION_RESET = 'lk_connection_reset',
  LK_ORGANISATION_DENIED = 'lk_organisation_denied',
  LK_EXECUTION_EXPIRED = 'lk_execution_expired',
  LK_INVALID_CONTENT_OWNERSHIP = 'lk_invalid_content_ownership',
  LK_AUTHORIZATION_RESSOURCE = 'lk_authorization_ressource',

  TW_ASYNC_FAIL = 'tw_async_fail',
  TW_ACCOUNT_LOCKED = 'tw_account_locked',
  TW_SSL_CERTIFICATE = 'tw_ssl_certificate',
  TW_403_ERROR = 'tw_403_error',
  TW_500_ERROR = 'tw_500_error',
  TW_AUTOMATED_REQUEST = 'tw_automated_request',
  TW_MEDIA_PROCESSING_TIMEOUT = 'tw_media_processing_timeout',
  TW_404_ERROR = 'tw_404_error',
  TW_TCP_ERROR = 'tw_tcp_error',
  TW_UNKNOW_ERROR_VIDEO = 'tw_unknow_error_video',
  TW_VIDEO_TOO_LONG = 'tw_video_too_long',
  TW_INVALID_MEDIA_TYPE = 'tw_invalid_media_type',
  TW_TOO_LONG = 'tw_too_long',
  TW_ACCOUNT_SUSPENDED = 'tw_account_suspended',
  TW_503_ERROR = 'tw_503_error',
  TW_TIMEOUT = 'tw_timeout',

  GO_INVALID_ARGUMENT = 'go_invalid_argument',
  GO_FORBIDDEN = 'go_forbidden',
  GO_REJECTED_STATE = 'go_rejected_state',
  GO_ENTITY_NOT_FOUND = 'go_entity_not_found',
  GO_TOO_MANY_OPEN_FILES = 'go_too_many_open_files',
  GO_SERVER_ERROR = 'go_server_error',
  GO_LOCAL_POST_FORBIDDEN = 'go_local_post_forbidden',
  GO_SYSTEM_LIB = 'go_system_lib',
  GO_AUTHORIZATION = 'go_authorization',
  GO_PROCESSING = 'go_processing',
}
