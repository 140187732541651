import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'mpp-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'mpp-page-header' },
})
export class MppPageHeaderComponent {
  @Input()
  public readonly heading: string;

  @Input()
  public readonly description: string;

  public constructor(@Attribute('icon') public readonly icon: string) {}
}
