import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import {
  MppButtonModule,
  MppCheckboxModule,
  MppDirectivesModule,
  MppHttpInterceptorsModule,
  MppPipesModule,
  MppServicesModule,
  MppToastMessageModule,
  MppUserAvatarModule,
  MppTranslateHttpLoader,
  MppCleanHttpClientFactory,
  MppUserSessionService,
} from '@arturin/core';
import { API_URL_TOKEN, ENV_TOKEN } from '@arturin/constants';

import { AmForgotPasswordPageComponent } from './forgot-password-page/forgot-password-page.component';
import { AmResetPasswordPageComponent } from './reset-password-page/reset-password-page.component';
import { AmLoginPageComponent } from './login-page/login-page.component';
import { AmInputComponent } from './_components/input/input.component';
import { routes } from './routes';
import { AmResetPasswordPageGuard } from '@am/reset-password-page/_guards/reset-password-page.guard';
import { AmUserPasswordService } from '@am/_services/user-password.service';
import { AmAccessGuard } from '@am/_guards/access.guard';
import { RecaptchaFormsModule, RecaptchaModule, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { AmRootComponent } from '@am/root.component';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AmRootComponent,
    AmLoginPageComponent,
    AmInputComponent,
    AmForgotPasswordPageComponent,
    AmResetPasswordPageComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MppUserAvatarModule,
    MppDirectivesModule,
    MppCheckboxModule,
    MppToastMessageModule,
    MppPipesModule,
    MppButtonModule,

    RecaptchaFormsModule,
    RecaptchaModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: MppTranslateHttpLoader,
        deps: [MppCleanHttpClientFactory],
      },
    }),
    BrowserModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    MppHttpInterceptorsModule.forRoot(environment.apiUrl),
    MppServicesModule.forRoot(environment),
  ],
  providers: [
    AmResetPasswordPageGuard,
    AmUserPasswordService,
    AmAccessGuard,
    { provide: ENV_TOKEN, useValue: environment },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.recaptchaKey },
    },
    { provide: API_URL_TOKEN, useValue: environment.apiUrl },
    MppCleanHttpClientFactory,
    MppUserSessionService,
  ],
  bootstrap: [AmRootComponent],
})
export class AmRootModule {}
