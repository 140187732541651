import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MppPhoneInputComponent } from './phone-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MppDirectivesModule } from '../../../directives';
import { MppPipesModule } from '../../../pipes';
import { MppLanguageService } from '../../../services/language.service';
import { COMMON_ERRORS_TOKEN } from '../form-controls.constants';

@NgModule({
  declarations: [MppPhoneInputComponent],
  imports: [CommonModule, ReactiveFormsModule, MppDirectivesModule, MppPipesModule],
  exports: [MppPhoneInputComponent],
  providers: [MppLanguageService],
})
export class MppPhoneInputModule {
  public static forRoot(errors: Record<string, string>): ModuleWithProviders<MppPhoneInputModule> {
    return {
      ngModule: MppPhoneInputModule,
      providers: [{ provide: COMMON_ERRORS_TOKEN, useValue: errors }],
    };
  }

  public static forChild(): ModuleWithProviders<MppPhoneInputModule> {
    return { ngModule: MppPhoneInputModule };
  }
}
