import { MppAttachmentOriginType, MppMediaType } from '../enums';
import { IRawMppAttachment } from '../types';

export class MppAttachment<T extends MppAttachment<T> = never> {
  public constructor(
    public readonly id: number,
    public readonly byteSize: number,
    public readonly checksum: string,
    public readonly contentType: MppMediaType,
    public readonly source: string,
    public readonly origin: MppAttachmentOriginType,
    public readonly originId: string,
    public readonly originUrl: string,
    public readonly smallPreviewUrl: string,
    public readonly filename: string
  ) {}

  public static fromRawData<I extends MppAttachment<I>>(
    value: IRawMppAttachment
  ): MppAttachment<I> {
    return new MppAttachment(
      value.id,
      value.byte_size,
      value.checksum,
      value.content_type,
      value.source,
      value.origin,
      value.origin_id,
      value.origin_url,
      value.small_preview_url,
      value.filename
    );
  }
}
