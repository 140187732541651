import { MppSocialCredentialStatus, MppSocialNetwork } from '../enums';

export interface IRawSocialCredential {
  active_name: string | null;
  active_page_id: string | null;
  id: number | null;
  oauth_expires_at: string | null;
  profile_picture_url: string | null;
  profile_url: string | null;
  show_change_button: boolean;
  social_network: MppSocialNetwork;
  status: MppSocialCredentialStatus;
  uid: string | null;
  reviews_url: string | null;
}
