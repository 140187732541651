export function toSnakeCase(camelCaseStr: string): string {
  if (!camelCaseStr) {
    return '';
  }

  const snakeCaseStr = camelCaseStr.replace(
    /[A-Z]/g,
    (uppercaseChar) => `_${uppercaseChar.toLowerCase()}`
  );

  return snakeCaseStr[0] === '_' ? snakeCaseStr.slice(1) : snakeCaseStr;
}
