import { Injectable } from '@angular/core';
import {
  addYears,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachYearOfInterval,
  endOfDecade,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDecade,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subYears,
} from 'date-fns';
import { DECADE } from '../calendar.constants';

@Injectable()
export class MppCalendarService {
  public getDaysRange(date: Date): Date[] {
    return eachDayOfInterval({
      start: startOfWeek(startOfMonth(date)),
      end: endOfWeek(endOfMonth(date)),
    });
  }

  public getWeekRange(date: Date): Date[] {
    return eachDayOfInterval({
      start: startOfWeek(date),
      end: endOfWeek(date),
    });
  }

  public getMonthsRange(date: Date): Date[] {
    return eachMonthOfInterval({
      start: startOfYear(date),
      end: endOfYear(date),
    });
  }

  public getYearsRange(date: Date): Date[] {
    return eachYearOfInterval({
      start: subYears(startOfDecade(date), 2),
      end: endOfDecade(date),
    });
  }

  public addDecade(date: Date, count: number): Date {
    return addYears(date, count * DECADE);
  }

  public subDecade(date: Date, count: number): Date {
    return subYears(date, count * DECADE);
  }
}
