import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { MppToastMessageComponent } from './toast-message.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [MppToastMessageComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastComponent: MppToastMessageComponent,
      iconClasses: {
        error: 'error icon-close-circle-filled',
        info: 'info icon-info-filled',
        success: 'success icon-checkmark-circle-filled',
        warning: 'warning icon-alert-circle-filled',
      },
      maxOpened: 5,
      autoDismiss: true,
    }),
  ],
  exports: [MppToastMessageComponent],
})
export class MppToastMessageModule {}
