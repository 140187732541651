import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { MppBaseDropdown } from '../helpers/base-dropdown';
import { TSearchFn } from '../types/search-fn';
import { MppLanguageService } from '../../../services/language.service';
import { COMMON_ERRORS_TOKEN } from '../form-controls.constants';
import { TCommonErrors } from '../types/common-errors';

@Component({
  selector: 'mpp-simple-select',
  templateUrl: './simple-select.component.html',
  styleUrls: ['./simple-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-simple-select' },
})
export class MppSimpleSelectComponent extends MppBaseDropdown {
  @Input() public readonly placeholderText: string;
  @Input() public readonly notFoundText: string;
  @Input() public readonly hasSearch: boolean = false;
  @Input() public readonly searchFn: TSearchFn;
  @Input() public items: any[] = [];

  @Output() public readonly onClear: EventEmitter<Event> = new EventEmitter();

  public isOpen = false;

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef<HTMLElement>,
    viewContainerRef: ViewContainerRef,
    languageService: MppLanguageService,
    @Inject(COMMON_ERRORS_TOKEN) COMMON_ERRORS: TCommonErrors,
    @Attribute('bindValue') public readonly bindValue: string,
    @Attribute('bindLabel') public readonly bindLabel: string
  ) {
    super(changeDetectorRef, elementRef, viewContainerRef, languageService, COMMON_ERRORS);

    this.bindLabel ||= this.DEFAULT_BIND_LABEL;
  }

  public onControlClose(): void {
    this.isOpen = false;
    this.onClose.emit();
  }
}
