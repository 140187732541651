import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MppImagePickerComponent } from './image-picker.component';
import { MppImageAddComponent } from './image-add';
import { MppImageCardComponent } from './image-card';
import { MppSpinnerModule } from '../spinner';

@NgModule({
  declarations: [MppImagePickerComponent, MppImageAddComponent, MppImageCardComponent],
  imports: [CommonModule, MppSpinnerModule],
  exports: [MppImagePickerComponent, MppImageAddComponent, MppImageCardComponent],
  providers: [],
})
export class MppImagePickerModule {}
