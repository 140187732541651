import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MppPipesModule } from '../../pipes/pipes.module';
import { MppUserAvatarComponent } from './user-avatar.component';

@NgModule({
  imports: [CommonModule, MppPipesModule],
  declarations: [MppUserAvatarComponent],
  exports: [MppUserAvatarComponent],
})
export class MppUserAvatarModule {}
