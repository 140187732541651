import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[mppRadioButton]',
})
export class MppRadioButtonDirective {
  @Input()
  public readonly control: FormControl;

  @Input()
  public readonly value: string | number;

  @HostBinding('class.active')
  public get isActive(): boolean {
    return this.control.value === this.value;
  }

  @HostListener('click')
  public onHostClick(): void {
    if (this.isActive) {
      return;
    }

    this.control.setValue(this.value);
  }
}
