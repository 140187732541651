import { ModuleWithProviders, NgModule } from '@angular/core';
import { API_URL_TOKEN } from '@arturin/constants';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MppBaseUrlInterceptor } from './base-url.interceptor';
import { MppAccessTokenInterceptor } from './access-token.interceptor';
import { MppLocaleHeaderInterceptor } from './locale-header.interceptor';
import { MppParamsFormatInterceptor } from './params-format.interceptor';
import { MppBodyFormatInterceptor } from './body-format.interceptor';
import { MppErrorsInterceptor } from './errors.interceptor';

@NgModule()
export class MppHttpInterceptorsModule {
  public static forRoot(apiUrlToken: string): ModuleWithProviders<MppHttpInterceptorsModule> {
    return {
      ngModule: MppHttpInterceptorsModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: MppBaseUrlInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MppAccessTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MppLocaleHeaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MppParamsFormatInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MppBodyFormatInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: MppErrorsInterceptor, multi: true },
        { provide: API_URL_TOKEN, useValue: apiUrlToken },
      ],
    };
  }
}
