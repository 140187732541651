<input class="mpp-color-picker__control"
       [value]="control.value"
       [colorPicker]="control.value"
       [cpUseRootViewContainer]="true"
       [cpOKButton]="true"
       [cpOKButtonText]="labels.confirm"
       [cpCancelButton]="true"
       [cpCancelButtonText]="labels.cancel"
       [cpCloseClickOutside]="true"
       [cpSaveClickOutside]="false"
       [cpAlphaChannel]="'disabled'"
       [cpOutputFormat]="'hex'"
       [cpPresetLabel]="labels.preset"
       [cpPresetColors]="presets"
       [cpPosition]="'bottom'"
       (colorPickerSelect)="this.colorPickerSelect($event)"
       disabled
>

<span class="mpp-color-picker__arrow icon icon-chevron-down-outlined"></span>
