<div class="mpp-confirmation-dialog__header">
  <ng-container [ngSwitch]="type">
    <div class="mpp-confirmation-dialog__icon icon icon-alert-triangle-filled"
         *ngSwitchCase="confirmationType.ERROR"
    ></div>

    <div class="mpp-confirmation-dialog__icon icon icon-alert-circle-filled"
         *ngSwitchCase="confirmationType.WARNING"
    ></div>

    <div class="mpp-confirmation-dialog__icon icon icon-info-filled"
         *ngSwitchCase="confirmationType.ACCENT"
    ></div>

    <div class="mpp-confirmation-dialog__icon icon icon-checkmark-circle-filled"
         *ngSwitchCase="confirmationType.SUCCESS"
    ></div>
  </ng-container>

  <div class="mpp-confirmation-dialog__title"
       [innerHTML]="config.titleText | translate"
  ></div>

  <div class="mpp-confirmation-dialog__description"
       [innerHTML]="config.descriptionText | translate"
  ></div>
</div>

<div class="mpp-confirmation-dialog__body"
     [innerHTML]="config.bodyText | translate"
></div>

<div class="mpp-confirmation-dialog__actions">
  <button mppButton="stroke white"
          (click)="onClose(false)"
  >{{ config.declineText | translate }}</button>

  <ng-container [ngSwitch]="type">
    <button class="icon"
            [ngClass]="config.confirmIcon || 'icon-trash-2-filled'"
            mppButton="fill red"
            *ngSwitchCase="confirmationType.ERROR"
            (click)="onClose(true)"
    >{{ config.confirmText | translate }}</button>

    <button class="icon icon-checkmark-circle-filled"
            mppButton="fill green"
            *ngSwitchCase="confirmationType.SUCCESS"
            (click)="onClose(true)"
    >{{ config.confirmText | translate }}</button>

    <button class="icon icon-info-filled"
            mppButton="fill blue"
            *ngSwitchCase="confirmationType.ACCENT"
            (click)="onClose(true)"
    >{{ config.confirmText | translate }}</button>

    <button class="icon"
            mppButton="fill orange"
            *ngSwitchCase="confirmationType.WARNING"
            (click)="onClose(true)"
    >{{ config.confirmText | translate }}</button>
  </ng-container>
</div>
