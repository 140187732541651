import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MppCollapseSectionComponent } from './collapse-section.component';
import { MppSpinnerModule } from '../spinner';

@NgModule({
  imports: [CommonModule, MppSpinnerModule],
  declarations: [MppCollapseSectionComponent],
  exports: [MppCollapseSectionComponent],
})
export class MppCollapseSectionModule {}
