<ng-template #actionsTemplate>
  <mpp-select-icons-panel (onClearSearchClick)="clearSearch()">
  </mpp-select-icons-panel>
</ng-template>

<ng-select class="mpp-lib"
           [formControl]="control"
           [searchable]="hasSearch && isOpen"
           [clearable]="true"
           [clearOnBackspace]="false"
           [items]="items"
           [placeholder]="placeholderText"
           [searchFn]="searchFn"
           [bindValue]="bindValue"
           [bindLabel]="bindLabel"
           [notFoundText]="notFoundText"
           appendTo="body"
           (open)="onControlOpen()"
           (close)="onControlClose()"
           (clear)="onClear.emit()"
           (change)="onChange.emit($event)"
           (search)="onSearch.emit($event.term);toggleSearch($event.term);"
>
  <ng-template ng-label-tmp
               let-item="item"
  >
    <ng-container *ngIf="item && (!hasSearch || !isOpen)">
      <div class="mpp-select-labels">
        <div class="mpp-select-labels__list">
          <span class="mpp-select-labels__item">{{ item[bindLabel] }}</span>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template ng-option-tmp let-item="item">
    {{ item[bindLabel] }}
  </ng-template>
</ng-select>
