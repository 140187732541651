import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

export const SWIPER_CONFIG: SwiperConfigInterface = {
  slidesPerView: 'auto',
  speed: 500,
  navigation: {
    nextEl: '.mpp-media-dialog__arrow[data-direction="next"]',
    prevEl: '.mpp-media-dialog__arrow[data-direction="prev"]',
  },
};
