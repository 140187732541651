import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { IConfirmationConfig } from './types';
import { MppConfirmationType } from './enums';

@Component({
  selector: 'mpp-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-confirmation-dialog' },
})
export class MppConfirmationDialogComponent {
  public readonly confirmationType: typeof MppConfirmationType = MppConfirmationType;

  @HostBinding('attr.data-type')
  public get type(): MppConfirmationType {
    return this.dialogRef.data.type || MppConfirmationType.ERROR;
  }

  public constructor(private readonly dialogRef: DialogRef<IConfirmationConfig, boolean>) {}

  public get config(): IConfirmationConfig {
    return this.dialogRef.data;
  }

  public onClose(value: boolean): void {
    this.dialogRef.close(value);
  }
}
