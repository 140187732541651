import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { MppBaseFormControl } from './base-form-control';
import { COMMON_ERRORS_TOKEN } from '../form-controls.constants';
import { MppLanguageService } from '../../../services/language.service';
import { TCommonErrors } from '../types/common-errors';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class MppBaseDropdown
  extends MppBaseFormControl
  implements AfterViewInit, OnDestroy
{
  @Input() public readonly panelCssClass?: string;

  @Output() public readonly onOpen: EventEmitter<void> = new EventEmitter();

  @Output() public readonly onClose: EventEmitter<void> = new EventEmitter();

  @Output() public readonly onSearch: EventEmitter<string> = new EventEmitter();

  @ViewChild(NgSelectComponent, { static: true })
  public selectComponent: NgSelectComponent;

  @ViewChild(NgSelectComponent, { read: ElementRef, static: true })
  public selectElementRef: ElementRef<HTMLElement>;

  @ViewChild('actionsTemplate', { static: true })
  public actionsTemplateRef: TemplateRef<void>;

  public isOpen = false;
  public isSearching = false;

  public dropdownPanelElement: HTMLElement | null = null;

  protected readonly DEFAULT_BIND_LABEL: string = 'label';
  protected actionsEmbeddedViewRef: EmbeddedViewRef<void> | undefined;

  private readonly SEARCHING_DROPDOWN_PANEL_CLASS = 'searching';

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    protected readonly elementRef: ElementRef<HTMLElement>,
    protected readonly viewContainerRef: ViewContainerRef,
    protected readonly languageService: MppLanguageService,
    @Inject(COMMON_ERRORS_TOKEN) COMMON_ERRORS: TCommonErrors
  ) {
    super(languageService, changeDetectorRef, COMMON_ERRORS);
  }

  @HostListener('window:scroll')
  public onScroll(): void {
    this.isOpen = false;
    this.selectComponent.close();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.actionsEmbeddedViewRef) {
      this.actionsEmbeddedViewRef.destroy();
    }
  }

  public ngAfterViewInit(): void {
    this.initCustomActionsView();
  }

  public onControlOpen(): void {
    this.isOpen = true;
    this.onOpen.emit();

    setTimeout(() => {
      this.dropdownPanelElement = document.querySelector<HTMLElement>('.ng-dropdown-panel')!;

      if (this.panelCssClass) {
        this.dropdownPanelElement.classList.add(this.panelCssClass);
      }
    }, 0);
  }

  public toggleSearch(term: string): void {
    this.isSearching = !!term;

    if (this.isSearching) {
      this.dropdownPanelElement!.classList.add(this.SEARCHING_DROPDOWN_PANEL_CLASS);
    } else {
      this.dropdownPanelElement!.classList.remove(this.SEARCHING_DROPDOWN_PANEL_CLASS);
    }
  }

  public clearSearch(): void {
    this.selectComponent.searchTerm = '';
    this.selectComponent.itemsList.resetFilteredItems();

    this.toggleSearch('');

    this.changeDetectorRef.detectChanges();
    this.selectComponent.detectChanges();
  }

  private initCustomActionsView(): void {
    if (!this.actionsTemplateRef) {
      return;
    }

    this.actionsEmbeddedViewRef = this.viewContainerRef.createEmbeddedView(this.actionsTemplateRef);

    this.selectElementRef.nativeElement
      .querySelector('.ng-select-container')!
      .appendChild(this.actionsEmbeddedViewRef.rootNodes[0]);
  }
}
