import {
  Attribute,
  Component,
  ContentChild,
  HostBinding,
  HostListener,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MppTypography } from '../../enums';

@Component({
  selector: 'mpp-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-radio-button' },
})
export class MppRadioButtonComponent {
  @Input()
  public readonly control: FormControl;

  @Input()
  public readonly value: string | number | Record<string, any>;

  @Input()
  @HostBinding('attr.data-theme')
  public readonly theme: 'dark';

  @Input()
  @HostBinding('attr.data-typography')
  public readonly typography: MppTypography = MppTypography.CAPTION_2;

  @HostBinding('class.disabled')
  public isDisabled = false;

  @ContentChild(TemplateRef)
  public readonly labelTemplate: TemplateRef<any>;

  @Input()
  public set disabled(isDisabled: boolean) {
    this.isDisabled = this.control.disabled || isDisabled;
  }

  public get boundLabel(): string | null {
    return this.value[this.bindLabel] || null;
  }

  @HostBinding('class.active')
  public get isActive(): boolean {
    const selectedValue = this.control.value;

    return this.value
      ? selectedValue === this.value
      : selectedValue[this.bindValue] === this.value[this.bindValue];
  }

  public constructor(
    @Attribute('bindValue') public readonly bindValue: string,
    @Attribute('bindLabel') public readonly bindLabel: string
  ) {}

  @HostListener('click')
  public onHostClick(): void {
    if (this.isDisabled) {
      return;
    }

    this.control.setValue(this.value);
  }
}
