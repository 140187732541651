<div class="mpp-collapse-section__header" #headerTemplate>
  <ng-container *ngTemplateOutlet="summaryTemplate ? summaryTemplate : summaryDefaultTemplate"></ng-container>

  <ng-template #summaryDefaultTemplate>
    <div class="mpp-collapse-section__summary">
      {{ summary }}
    </div>
  </ng-template>

  <button class="mpp-collapse-section__button icon"
          type="button"
          *ngIf="!isLoading"
          [ngClass]="isExpanded ? 'icon-minus-bold' : 'icon-plus-bold'"
  ></button>

  <mpp-spinner class="mpp-collapse-section__spinner" *ngIf="isLoading"></mpp-spinner>
</div>

<div class="mpp-collapse-section__content" *ngIf="isExpanded" @expandAnimation>
  <ng-container [ngTemplateOutlet]="contentTemplate"
                [ngTemplateOutletContext]="{data: data}"
  ></ng-container>
</div>
