<div class="mpp-toast-message__content">
  <div class="mpp-toast-message__title"
       *ngIf="title"
       [class]="options.titleClass"
       [attr.aria-label]="title"
  >
    {{ title }}
  </div>

  <div class="mpp-toast-message__message"
       *ngIf="message"
       role="alert"
       aria-live="polite"
       [class]="options.messageClass"
       [innerHTML]="message"
  ></div>
</div>
