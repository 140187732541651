import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MppFormFieldComponent } from './form-field.component';
import { MppDirectivesModule } from '../../../directives';

@NgModule({
  declarations: [MppFormFieldComponent],
  imports: [CommonModule, MppDirectivesModule],
  exports: [MppFormFieldComponent],
})
export class MppFormFieldModule {}
