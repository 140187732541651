import { Injectable } from '@angular/core';
import { eachMinuteOfInterval, endOfToday, startOfToday } from 'date-fns';

@Injectable()
export class MppTimeRangeService {
  public getTimeSteps(minutesStep: number): Date[] {
    const interval: Interval = {
      start: startOfToday(),
      end: endOfToday(),
    };

    return eachMinuteOfInterval(interval, { step: minutesStep });
  }
}
