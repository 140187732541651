import { NgModule } from '@angular/core';
import { MppControlDirective } from './control.directive';
import { MppClickOutsideDirective } from './click-outside.directive';
import { MppTooltipDirective } from './tooltip.directive';
import { MppDropdownOutletDirective } from './dropdown-outlet.directive';
import { MppTemplateOutletModule } from '../components/template-outlet/template-outlet.module';
import { MppRadioButtonDirective } from './radio-button.directive';
import { MppFormDirtyStatusDirective } from './form-dirty-status.directive';

@NgModule({
  declarations: [
    MppControlDirective,
    MppClickOutsideDirective,
    MppTooltipDirective,
    MppDropdownOutletDirective,
    MppRadioButtonDirective,
    MppFormDirtyStatusDirective,
  ],
  imports: [MppTemplateOutletModule],
  exports: [
    MppControlDirective,
    MppClickOutsideDirective,
    MppTooltipDirective,
    MppDropdownOutletDirective,
    MppRadioButtonDirective,
    MppFormDirtyStatusDirective,
  ],
})
export class MppDirectivesModule {}
