import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MppLanguageService, MppPageTitleService } from '@arturin/core';

@Component({
  selector: 'am-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  host: { class: 'rt-root' },
  encapsulation: ViewEncapsulation.None,
})
export class AmRootComponent implements OnInit {
  public isLoading = true;

  public constructor(
    protected readonly languageService: MppLanguageService,
    protected readonly pageTitleService: MppPageTitleService
  ) {}

  public ngOnInit(): void {
    this.languageService.setBrowserLanguage$().subscribe(() => {
      this.pageTitleService.listenOnRouteChanges();
      this.isLoading = false;
    });
  }
}
