import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { isAfter, isBefore, isEqual } from 'date-fns';
import { MppRange } from './enums';
import { MppBaseDateTimePicker } from './base-date-time-picker';
import { MppDateTimeInputComponent } from './components/date-time-input/date-time-input.component';
import { Subject } from 'rxjs';

@Directive()
export abstract class MppBaseRange extends MppBaseDateTimePicker implements OnInit {
  @Input() public readonly startPlaceholder = '';

  @Input() public readonly endPlaceholder = '';

  @HostBinding('class.focused')
  public get isFocused(): boolean {
    return this.isStartFocused || this.isEndFocused;
  }

  @HostBinding('class.equal')
  public get isEqualDates(): boolean {
    return this.isDateMatches(this.control.value[MppRange.START], this.control.value[MppRange.END]);
  }

  @ViewChild('startInput', { static: true })
  protected readonly startInput: MppDateTimeInputComponent;

  @ViewChild('endInput', { static: true })
  protected readonly endInput: MppDateTimeInputComponent;

  public startDate: Date | null;
  public endDate: Date | null;

  public focusedDate: Date | null;

  protected isStartFocused = false;

  protected isEndFocused = false;

  private readonly onStartChange$$: Subject<Date> = new Subject();
  private readonly onEndChange$$: Subject<Date> = new Subject();

  public get isStartInputChanged(): boolean {
    const oldValue: Date | null = this.control.value[MppRange.START];

    if (oldValue && this.startDate) {
      return !isEqual(oldValue, this.startDate);
    }

    return !oldValue !== !this.startDate;
  }

  public get isEndInputChanged(): boolean {
    const oldValue: Date | null = this.control.value[MppRange.END];

    if (oldValue && this.endDate) {
      return !isEqual(oldValue, this.endDate);
    }

    return !oldValue !== !this.endDate;
  }

  public constructor(
    elementRef: ElementRef<HTMLElement>,
    viewContainerRef: ViewContainerRef,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(elementRef, viewContainerRef, changeDetectorRef);
  }

  @HostListener('document:pointerdown', ['$event.target'])
  public onOutsideClick(target: HTMLElement): void {
    if (!this.dropdownShown$$.value || this.dropdownElement?.contains(target)) {
      return;
    }

    if (this.isStartFocused && this.isEndInputChanged) {
      return;
    }

    this.startInput.blur();
    this.endInput.blur();

    if (!this.elementRef.nativeElement.contains(target)) {
      this.dropdownShown$$.next(false);
    }
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.initControls();
    this.initStartDateListener();
    this.initEndDateListener();
  }

  public onStartFocus(): void {
    this.isEndFocused = false;
    this.isStartFocused = true;

    this.focusedDate = this.startDate;

    this.dropdownShown$$.next(true);
  }

  public onEndFocus(): void {
    this.isEndFocused = true;
    this.isStartFocused = false;

    this.focusedDate = this.endDate;

    this.dropdownShown$$.next(true);
  }

  public onSelect(date: Date): void {
    if (this.isStartFocused) {
      this.setStartDate(date);
    } else {
      this.setEndDate(date);
    }
  }

  public setStartDate(date: Date): void {
    this.onStartChange$$.next(this.startDateFormatter(date));
  }

  public setEndDate(date: Date): void {
    this.onEndChange$$.next(this.endDateFormatter(date));
  }

  protected onDropdownToggle(isCalendarShown: boolean): void {
    if (!isCalendarShown && (this.isStartInputChanged || this.isEndInputChanged)) {
      this.control.setValue([this.startDate, this.endDate]);

      this.isStartFocused = false;
      this.isEndFocused = false;

      this.control.markAsTouched();
      this.control.markAsDirty();
    }
  }

  private initControls(): void {
    if (!this.control?.value?.length) {
      const RANGE = 2;
      const emptyValue = Array(RANGE).fill(null);

      this.control.setValue(emptyValue, { emitEvent: false });
    }

    const [start, end]: (Date | null)[] = this.control.value;

    this.startDate = start ? this.startDateFormatter(start) : null;
    this.endDate = end ? this.endDateFormatter(end) : null;
  }

  private initStartDateListener(): void {
    this.onStartChange$$.pipe(takeUntil(this.destroy$$)).subscribe((date: Date) => {
      this.startDate = date;

      if (!this.endDate || isAfter(date, this.endDate)) {
        this.endDate = date;
      }

      this.startInput.blur();
      this.endInput.focus();
    });
  }

  private initEndDateListener(): void {
    this.onEndChange$$.pipe(takeUntil(this.destroy$$)).subscribe((date: Date) => {
      this.endDate = date;

      if (!this.startDate || isBefore(date, this.startDate)) {
        this.startDate = date;
        this.endDate = date;

        return;
      }

      this.endInput.blur();
      this.dropdownShown$$.next(false);
    });
  }

  protected abstract startDateFormatter(date: Date): Date;

  protected abstract endDateFormatter(date: Date): Date;

  protected abstract get dropdownWidth(): number | null;

  protected abstract isDateMatches(start: Date | null, end: Date | null): boolean;
}
