import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'mpp-option',
  template: ``,
  encapsulation: ViewEncapsulation.None,
})
export class MppOptionComponent implements OnInit, OnChanges {
  @Input() public readonly value: any;

  @Input() public readonly label: string;

  @Input() public disabled: boolean;

  @Output() public readonly onChanges: EventEmitter<void> = new EventEmitter();

  @ContentChild(TemplateRef)
  public readonly templateRef: TemplateRef<any>;

  public ngOnInit(): void {
    if (this.value === undefined) {
      throw new Error("Attribute 'value' is required");
    }

    if (!this.label) {
      throw new Error("Attribute 'label' is required");
    }
  }

  public ngOnChanges(): void {
    this.onChanges.emit();
  }
}
