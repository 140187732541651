import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { MppBaseRange } from '../base-range';
import { MppViewModes } from '../enums/view-modes';
import {
  endOfMonth,
  endOfYear,
  isDate,
  isEqual,
  isSameMonth,
  isSameYear,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { MppRange } from '../enums';
import { IDateFormat, TRegionDateFormat } from '../types';
import {
  DEFAULT_DATE_INPUT_FORMAT,
  DEFAULT_MONTH_VIEW_FORMAT,
  DEFAULT_YEAR_VIEW_FORMAT,
} from './date-range.constants';

@Component({
  selector: 'mpp-date-range',
  templateUrl: './date-range.component.html',
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-range' },
})
export class MppDateRangeComponent extends MppBaseRange {
  @Input()
  public readonly min: Date;

  @Input()
  public readonly max: Date;

  @Input()
  public readonly viewMode: MppViewModes = MppViewModes.DAYS;

  @ViewChild('calendar', { read: ElementRef })
  public readonly calendarRef: ElementRef<HTMLElement>;

  @HostBinding('class.invalid')
  public get isInvalid(): boolean {
    return this.control.invalid;
  }

  public readonly range: typeof MppRange = MppRange;

  public get inputFormat(): IDateFormat {
    return this.format ? this.format[this.userRegion] : this.getDefaultViewFormat();
  }

  public constructor(
    elementRef: ElementRef<HTMLElement>,
    viewContainerRef: ViewContainerRef,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(elementRef, viewContainerRef, changeDetectorRef);
  }

  public getDefaultViewFormat(): IDateFormat {
    const viewModeFormats: Record<MppViewModes, TRegionDateFormat> = {
      [MppViewModes.DAYS]: DEFAULT_DATE_INPUT_FORMAT,
      [MppViewModes.MONTHS]: DEFAULT_MONTH_VIEW_FORMAT,
      [MppViewModes.YEARS]: DEFAULT_YEAR_VIEW_FORMAT,
    };

    return viewModeFormats[this.viewMode][this.userRegion];
  }

  protected startDateFormatter(date: Date): Date {
    const dateOffsetMap: Record<MppViewModes, Date> = {
      [MppViewModes.DAYS]: date,
      [MppViewModes.MONTHS]: startOfMonth(date),
      [MppViewModes.YEARS]: startOfYear(date),
    };

    return dateOffsetMap[this.viewMode];
  }

  protected endDateFormatter(date: Date): Date {
    const dateOffsetMap: Record<MppViewModes, Date> = {
      [MppViewModes.DAYS]: date,
      [MppViewModes.MONTHS]: endOfMonth(date),
      [MppViewModes.YEARS]: endOfYear(date),
    };

    return dateOffsetMap[this.viewMode];
  }

  protected get dropdownWidth(): number | null {
    return null;
  }

  protected isDateMatches(start: Date | null, end: Date | null): boolean {
    if (!isDate(start) || !isDate(end)) {
      return false;
    }

    switch (this.viewMode) {
      case MppViewModes.DAYS:
        return isEqual(start!, end!);

      case MppViewModes.MONTHS:
        return isSameMonth(start!, end!);

      case MppViewModes.YEARS:
        return isSameYear(start!, end!);
    }
  }
}
