import { Pipe, PipeTransform } from '@angular/core';
import { MppUserRegions } from '../enums';

@Pipe({ name: 'mppCurrency' })
export class MppCurrencyPipe implements PipeTransform {
  private currencyMap: Record<MppUserRegions, string> = {
    [MppUserRegions.FR]: '€',
    [MppUserRegions.US]: '$',
  };

  public transform(value: number | string = 0, region: MppUserRegions = MppUserRegions.US): string {
    return `${value} ${this.currencyMap[region]}`;
  }
}
