import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { startWith, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { merge } from 'rxjs';
import { MppOptionComponent } from '../_components/option/option.component';
import { MppBaseDropdownComponent } from '../base-dropdown.component';
import { TSearchFn } from '../_types';

@Component({
  selector: 'mpp-simple-select',
  templateUrl: './simple-select.component.html',
  styleUrls: ['./simple-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'simple-select' },
})
export class MppSimpleSelectComponent extends MppBaseDropdownComponent implements AfterContentInit {
  @Input() public readonly control: FormControl;

  @Input() public readonly placeholder: string;

  @Input() public readonly hasSearch: boolean = true;

  @Input() public readonly searchFn: TSearchFn;

  @ContentChildren(MppOptionComponent)
  public readonly optionsQueryList: QueryList<MppOptionComponent>;

  public options: MppOptionComponent[];

  public isOpen = false;

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef<HTMLElement>,
    viewContainerRef: ViewContainerRef
  ) {
    super(changeDetectorRef, elementRef, viewContainerRef);
  }

  public ngAfterContentInit(): void {
    this.optionsQueryList.changes
      .pipe(startWith(this.optionsQueryList), takeUntil(this.destroy$$))
      .subscribe((optionsQueryList) => {
        this.options = optionsQueryList.toArray();
        this.initOptionsChangesListener();
      });
  }

  public onControlClose(): void {
    this.isOpen = false;
    this.onClose.emit();
  }

  private initOptionsChangesListener(): void {
    merge(...this.options.map((option) => option.onChanges))
      .pipe(takeUntil(this.optionsQueryList.changes), takeUntil(this.destroy$$))
      .subscribe(() => {
        this.options = this.optionsQueryList.toArray();
      });
  }
}
