import {
  Component,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { getHexLuma, MppAutoUnsubscribeComponent } from '../../helpers';
import { ColorPickerDirective } from 'ngx-color-picker';

@Component({
  selector: 'mpp-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  host: { class: 'mpp-color-picker' },
  encapsulation: ViewEncapsulation.None,
})
export class MppColorPickerComponent extends MppAutoUnsubscribeComponent implements OnDestroy {
  @Input()
  @HostBinding('attr.data-type')
  public readonly type: 'hex' | 'simple' = 'simple';

  @Input()
  public readonly control: AbstractControl;

  @Input()
  public readonly labels: { preset: string; confirm: string; cancel: string };

  @Input()
  public readonly presets: string[] = [];

  @ViewChild(ColorPickerDirective)
  private readonly colorPickerDirective: ColorPickerDirective;

  @HostBinding('attr.style')
  public get blendedColors(): any {
    let resultBlend = `--control-color: ${this.control.value};`;

    if (this.control.value) {
      const luma = getHexLuma(this.control.value);
      const arrowBlendColor = luma > 100 ? 'black' : 'white';

      resultBlend += `--text-color: ${arrowBlendColor}; --arrow-section-color: ${this.control.value}d9`;
    }

    return this.sanitizer.bypassSecurityTrustStyle(`${resultBlend};`);
  }

  public constructor(private readonly sanitizer: DomSanitizer) {
    super();
  }

  @HostListener('click')
  public onClick(): void {
    this.colorPickerDirective.openDialog();
  }

  public colorPickerSelect(value: string): void {
    this.control.setValue(value);

    this.control.markAsDirty();
    this.control.markAsTouched();
  }
}
