import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MppDirectivesModule } from '../../../directives';
import { MppPipesModule } from '../../../pipes';
import { MppLanguageService } from '../../../services/language.service';
import { MppSimpleSelectComponent } from './simple-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MppSelectIconsPanelComponent } from './components/icons-panel/icons-panel.component';
import { COMMON_ERRORS_TOKEN } from '../form-controls.constants';

@NgModule({
  declarations: [MppSimpleSelectComponent, MppSelectIconsPanelComponent],
  imports: [CommonModule, ReactiveFormsModule, MppDirectivesModule, MppPipesModule, NgSelectModule],
  exports: [MppSimpleSelectComponent],
  providers: [MppLanguageService],
})
export class MppSimpleSelectModule {
  public static forRoot(
    errors: Record<string, string>
  ): ModuleWithProviders<MppSimpleSelectModule> {
    return {
      ngModule: MppSimpleSelectModule,
      providers: [{ provide: COMMON_ERRORS_TOKEN, useValue: errors }],
    };
  }

  public static forChild(): ModuleWithProviders<MppSimpleSelectModule> {
    return { ngModule: MppSimpleSelectModule };
  }
}
