<div class="mpp-media-dialog__preview">
  <div class="mpp-media-dialog__images">
    <div class="swiper-container" [swiper]="swiperConfig" (slideChange)="onSlideChange($event)">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let media of data.media">
          <div class="mpp-media-dialog-slide" [ngSwitch]="media.type">
            <div class="mpp-media-dialog-slide__media-item" *ngSwitchCase="mediaItemType.IMAGE">
              <img class="icon icon-image-filled"
                   [src]="media.url"
                   alt=""
              >
            </div>

            <div class="mpp-media-dialog-slide__media-item" *ngSwitchCase="mediaItemType.VIDEO">
              <video class="mpp-media-dialog-slide__media-item"
                     [src]="media.url"
                     #video
              ></video>

              <button class="mpp-media-dialog-slide__action-button icon icon-play-circle-filled"
                      mppButton="fill grey rounded"
                      *ngIf="!activeVideoPlaying"
                      (click)="onPlayClick(video)"
              ></button>

              <button class="mpp-media-dialog-slide__action-button icon icon-pause-circle-filled"
                      mppButton="fill grey rounded"
                      *ngIf="activeVideoPlaying"
                      (click)="onPauseClick(video)"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="mpp-media-dialog__overlay">
    <div class="mpp-media-dialog__header">
      <div class="mpp-media-dialog__label" *ngIf="activeMedia?.label">
        {{ activeMedia?.label }}
      </div>

      <div class="mpp-media-dialog__meta-data" *ngIf="activeMedia?.viewsCountLabel || activeMedia?.dateLabel">
        <span class="icon icon-eye-filled mpp-media-dialog__meta-item"
               *ngIf="activeMedia?.viewsCountLabel">{{ activeMedia?.viewsCountLabel }}</span>
        <span class="icon icon-calendar-filled mpp-media-dialog__meta-item" *ngIf="activeMedia?.dateLabel">{{ activeMedia?.dateLabel }}</span>
      </div>

      <button class="mpp-media-dialog__close-button icon icon-close-circle-filled"
              mppButton="fill black"
              (click)="onClose()"
      ></button>

    </div>

    <div class="mpp-media-dialog__body">
      <button class="mpp-media-dialog__arrow icon icon-chevron-left-outlined"
              mppButton="fill white rounded"
              data-direction="prev"
      ></button>

      <button class="mpp-media-dialog__arrow icon icon-chevron-right-outlined"
              mppButton="fill white rounded"
              data-direction="next"
      ></button>
    </div>

    <div class="mpp-media-dialog__footer" *ngIf="data.actionsTemplate">
      <ng-container *ngTemplateOutlet="data.actionsTemplate"></ng-container>
    </div>
  </div>
</div>


<div class="mpp-media-dialog__sidebar" *ngIf="data.sidebarTemplate">
  <ng-container *ngTemplateOutlet="data.sidebarTemplate"></ng-container>
</div>
