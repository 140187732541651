import { Component, HostBinding, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MppControlDirective } from '@arturin/core';

@Component({
  selector: 'am-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'rt-input' },
})
export class AmInputComponent {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  public static ngAcceptInputType_control: AbstractControl;

  @Input()
  public readonly control: FormControl;

  @HostBinding('class.filled')
  public get isFilled(): boolean {
    return !!this.control.value && this.control.value.length > 0;
  }

  @HostBinding('class.focused')
  public isFocused = false;

  @ViewChild(MppControlDirective, { static: true })
  public controlDirective: MppControlDirective;

  @HostBinding('class.invalid')
  public get invalid(): boolean {
    return this.controlDirective.errorState;
  }

  @Input()
  public readonly type: string = 'text';

  @Input()
  public readonly placeholder: string;

  public onBlur(): void {
    this.control.patchValue(this.control.value.trim());
    this.isFocused = false;
  }
}
