import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MppImagePickerSizes } from '../enums';
import { IImageCardAction, IImageCardBadge } from '../types';

@Component({
  selector: 'mpp-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-image-card' },
})
export class MppImageCardComponent {
  @Input() public readonly isRemovable: boolean = true;

  @Input() public readonly image: { [key: string]: any; url: string };

  @Input() public readonly action: IImageCardAction;

  @Input() public readonly badge: IImageCardBadge;

  @Input()
  @HostBinding('attr.data-size')
  public readonly size: MppImagePickerSizes;

  @Output() public readonly onRemove: EventEmitter<void> = new EventEmitter<void>();

  public readonly mppImagePickerSizes = MppImagePickerSizes;
}
