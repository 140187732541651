import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter, map, pluck, switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MppLanguageService } from './language.service';

@Injectable()
export class MppPageTitleService {
  public constructor(
    private readonly router: Router,
    private readonly titleService: Title,
    private readonly translateService: TranslateService,
    private readonly languageService: MppLanguageService
  ) {}

  public listenOnRouteChanges(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.getDeepestActivatedChildRoute(this.router.routerState.root)),
        switchMap((activatedChildRoute) => activatedChildRoute.data.pipe(pluck('pageTitle'))),
        distinctUntilChanged(),
        filter(Boolean),
        switchMap((pageTitle: string) => this.translateService.stream(pageTitle)),
        filter(() => this.languageService.isTranslationLoaded())
      )
      .subscribe((title: string) => {
        this.titleService.setTitle(title);
      });
  }

  private getDeepestActivatedChildRoute(route: ActivatedRoute): ActivatedRoute {
    return route.firstChild ? this.getDeepestActivatedChildRoute(route.firstChild) : route;
  }
}
