import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { TranslateService } from '@ngx-translate/core';
import { enUS, fr } from 'date-fns/locale';
import { MppUserLanguage } from '../enums';

@Pipe({
  name: 'mppDateFormat',
  pure: false,
})
export class MppDateFormatPipe implements PipeTransform {
  private lastValue: string | number | Date | null;
  private lastFormat: string;
  private lastLang: string;
  private lastTimeZone: string | undefined;

  private lastTransformedValue: string;

  private readonly dateLocalesMap: Record<MppUserLanguage, Locale> = {
    [MppUserLanguage.FR]: fr,
    [MppUserLanguage.EN]: enUS,
  };

  public get currentLocale(): Locale {
    return this.dateLocalesMap[this.translateService.currentLang];
  }

  public constructor(private readonly translateService: TranslateService) {}

  public transform(
    value: string | number | Date | null,
    dateTimeFormat: string,
    timeZone?: string
  ): string {
    if (this.isInputsChanged(value, dateTimeFormat, timeZone)) {
      this.lastValue = value;
      this.lastFormat = dateTimeFormat;
      this.lastLang = this.translateService.currentLang;
      this.lastTimeZone = timeZone;
      let date = '';

      if (value) {
        date = timeZone
          ? format(utcToZonedTime(value, timeZone), dateTimeFormat)
          : format(new Date(value), dateTimeFormat, { locale: this.currentLocale });
      }

      this.lastTransformedValue = date;
    }

    return this.lastTransformedValue;
  }

  private isInputsChanged(
    value: string | number | Date | null,
    format: string,
    timeZone?: string
  ): boolean {
    return (
      this.lastValue !== value ||
      this.lastFormat !== format ||
      this.lastLang !== this.translateService.currentLang ||
      this.lastTimeZone !== timeZone
    );
  }
}
