import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MppTabComponent } from '../tab/tab.component';

@Component({
  selector: 'mpp-header-item',
  templateUrl: './header-item.component.html',
  styleUrls: ['./header-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-header-item' },
})
export class MppHeaderItemComponent {
  @Output() public readonly onSelect: EventEmitter<null> = new EventEmitter<null>();

  @Input() public readonly isAnimating: boolean;

  @Input() public readonly tab: MppTabComponent;

  @Input()
  @HostBinding('class.stretched')
  public readonly isStretched = false;

  @Input()
  @HostBinding('class.selected')
  public readonly isSelected: boolean;

  @HostBinding('class.focused')
  public isFocused: boolean;

  @HostBinding('class.disabled')
  public get isDisabled(): boolean {
    return this.tab?.disabled || false;
  }

  private isClickStart: boolean;

  public constructor(public readonly elRef: ElementRef) {}

  @HostListener('keydown.arrowRight')
  public onArrowRight(): void {
    const next: HTMLElement | undefined = this.elRef.nativeElement.nextElementSibling;
    if (next) {
      next.focus();
    }
  }

  @HostListener('keydown.arrowLeft')
  public onArrowLeft(): void {
    const previous: HTMLElement | undefined = this.elRef.nativeElement.previousElementSibling;
    if (previous) {
      previous.focus();
    }
  }

  @HostListener('keydown.enter')
  public onEnter(): void {
    if (!this.isAnimating) {
      this.select();
    }
  }

  @HostListener('mousedown')
  public onMouseDown(): void {
    this.isClickStart = true;
  }

  @HostListener('focus')
  public onFocus(): void {
    if (!this.isClickStart) {
      this.isFocused = true;
    }
  }

  @HostListener('blur')
  public onBlur(): void {
    this.isFocused = false;
  }

  @HostListener('click')
  public onClick(): void {
    if (!this.isAnimating) {
      this.select();
    }
  }

  public select(): void {
    if (!this.tab.disabled) {
      this.onSelect.emit();
      this.isClickStart = false;
    }
  }
}
