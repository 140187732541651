import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MppBlockquoteComponent } from './blockquote.component';

@NgModule({
  declarations: [MppBlockquoteComponent],
  imports: [CommonModule],
  exports: [MppBlockquoteComponent],
})
export class MppBlockquoteModule {}
