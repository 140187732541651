<div class="mpp-button__spinner-wrapper" *ngIf="isLoading">
  <mpp-spinner class="mpp-button__spinner" [isInline]="true"></mpp-spinner>
</div>

<span
  #text
  class="mpp-button__text"
  *ngIf="!isContentEmpty && isContentDisplaying"
  @expandAnimation
  (@expandAnimation.start)="onAnimationStart()"
>
  <ng-content></ng-content>
</span>
