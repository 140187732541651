<div class="mpp-tab-group__header" *ngIf="isNavigationShown">
  <div class="mpp-tab-group__labels">
    <mpp-header-item *ngFor="let tab of tabs; index as tabIndex"
                     [isAnimating]="!!contentAnimationState"
                     [isSelected]="tabIndex === selectedIndex"
                     [isStretched]="isLabelStretched"
                     (onSelect)="onLabelClick(tabIndex)"
                     [tab]="tab"
                     [class.error]="tab.error"
                     tabindex="0">
    </mpp-header-item>
  </div>

  <div *ngIf="actionsTemplate" class="mpp-tab-group__actions">
    <ng-template [ngTemplateOutlet]="actionsTemplate"></ng-template>
  </div>
</div>

<div class="mpp-tab-group__container"
     [class.hidden]="!isContentShown"
>
  <div class="mpp-tab-group__content"
       (@slideInOut.done)="onContentTransitionEnd()"
       [@slideInOut]="contentAnimationState"
       [class.reversed]="isContentReversed"
  >
    <div class="mpp-tab-group__item" *ngIf="active">
      <ng-container *ngTemplateOutlet="active.templateRef"></ng-container>
    </div>

    <div class="mpp-tab-group__item" *ngIf="next">
      <ng-container *ngTemplateOutlet="next.templateRef"></ng-container>
    </div>
  </div>
</div>
