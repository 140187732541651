import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MppPlaceholderSectionComponent } from './placeholder-section.component';

@NgModule({
  imports: [CommonModule],
  declarations: [MppPlaceholderSectionComponent],
  exports: [MppPlaceholderSectionComponent],
})
export class MppPlaceholderSectionModule {}
