import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { createPopper, Options, Placement } from '@popperjs/core';
import { IPopperEvents, TComponent } from '../types';
import { Instance } from '@popperjs/core/lib/types';
import { MppTemplateOutletComponent } from '../components/template-outlet/template-outlet.component';

@Directive({
  selector: '[mppDropdownOutlet]',
  exportAs: 'mppDropdownOutlet',
})
export class MppDropdownOutletDirective implements OnInit, OnDestroy {
  @Input() public readonly placement?: Placement;

  @Input('mppDropdownOutlet') public readonly content: TemplateRef<TComponent<any>>;

  public visible = false;
  public popperEvents: IPopperEvents;

  private readonly POPPER_DEFAULT_OPTIONS: Options = {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
    strategy: 'absolute',
  };

  private popper: Instance;
  private contentComponentRef: ComponentRef<MppTemplateOutletComponent>;

  public constructor(
    private readonly elementRef: ElementRef,
    private readonly appRef: ApplicationRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly injector: Injector
  ) {}

  @HostListener('click')
  public onClick(): void {
    if (this.visible) {
      this.popperEvents.hide();
    } else {
      this.popperEvents.show();
    }
  }

  @HostListener('document:pointerdown', ['$event'])
  @HostListener('window:scroll', ['$event'])
  public onDocumentClick(event: Event): void {
    this.popperEvents.hide(event);
  }

  private get contentElement(): HTMLElement {
    return this.contentComponentRef.location.nativeElement;
  }

  public ngOnInit(): void {
    this.contentComponentRef = this.getContentElement();
    this.popperEvents = this.getEvents();

    this.contentComponentRef.instance.hide = this.popperEvents.hide;
    this.appRef.attachView(this.contentComponentRef.hostView);

    this.popper = createPopper(
      this.elementRef.nativeElement,
      this.contentComponentRef.location.nativeElement,
      this.getPopperOptions()
    );
  }

  public ngOnDestroy(): void {
    this.popperEvents.hide();
  }

  private getContentElement(): ComponentRef<MppTemplateOutletComponent> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      MppTemplateOutletComponent
    );
    const componentRef = componentFactory.create(this.injector);
    componentRef.instance.content = this.content;

    return componentRef;
  }

  private getPopperOptions(): Options {
    return this.placement
      ? { ...this.POPPER_DEFAULT_OPTIONS, placement: this.placement }
      : this.POPPER_DEFAULT_OPTIONS;
  }

  private getEvents(): IPopperEvents {
    return {
      show: () => {
        document.body.appendChild(this.contentElement);
        this.contentElement.animate([{ opacity: 0 }, { opacity: 1 }], {
          duration: 300,
          easing: 'ease-out',
        });

        this.popper.update();
        this.visible = true;
      },
      hide: (event?: Event) => {
        if (
          event &&
          (this.contentElement.contains(event.target as HTMLElement) ||
            this.elementRef.nativeElement.contains(event.target))
        ) {
          return;
        }

        this.visible = false;
        this.contentElement.remove();
      },
    };
  }
}
