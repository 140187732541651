<input class="mpp-date-time-input__control"
       #input
       [formControl]="control"
       [placeholder]="placeholder"
       [mask]="format.mask"
       (input)="onTyping.emit()"
       (focus)="onInputFocus()"
       (change)="onInputChange()"
       (blur)="onInputBlur()"
/>

<div class="mpp-date-time-input__placeholder">
  {{
  value
    ? (value | mppDateFormat: format.placeholder)
    : placeholder
  }}
</div>
