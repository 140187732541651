import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { createPopper, Options, Placement } from '@popperjs/core';
import { TPopperTriggerEvent } from '../enums';
import { IPopperEvents } from '../types';
import { Instance } from '@popperjs/core/lib/types';

@Directive({
  selector: '[mppTooltip]',
})
export class MppTooltipDirective implements OnInit, OnDestroy {
  @Input() public readonly placement?: Placement;

  @Input() public readonly triggerEvent: TPopperTriggerEvent = TPopperTriggerEvent.HOVER;

  @Input() public readonly isOverflowShown: boolean = false;

  @Input()
  public set content(content: string) {
    this.tooltipContentElement.innerHTML = content;
  }

  public get content(): string {
    return this.tooltipContentElement.innerHTML;
  }

  private readonly popperDefaultOptions: Options = {
    placement: 'top',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: '.mpp-tooltip-container__arrow',
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
    strategy: 'absolute',
  };
  private readonly listenersMap: Record<TPopperTriggerEvent, () => IPopperEvents> = {
    [TPopperTriggerEvent.HOVER]: this.setHoverListeners,
    [TPopperTriggerEvent.CLICK]: this.setClickListeners,
  };

  private tooltipElement: HTMLElement;
  private tooltipContentElement: HTMLElement;

  private currentListeners: IPopperEvents;

  private popper: Instance;

  public constructor(private readonly elementRef: ElementRef) {
    this.initTooltipElement();
  }

  public ngOnInit(): void {
    if (!this.tooltipContentElement.innerHTML) {
      return;
    }

    this.popper = createPopper(
      this.elementRef.nativeElement,
      this.tooltipElement,
      this.getPopperOptions()
    );

    this.currentListeners = this.listenersMap[this.triggerEvent].apply(this);
  }

  public ngOnDestroy(): void {
    const listenersToRemove: Record<TPopperTriggerEvent, () => void> = {
      [TPopperTriggerEvent.HOVER]: this.removeHoverListeners,
      [TPopperTriggerEvent.CLICK]: this.removeClickListeners,
    };

    this.tooltipElement.remove();
    listenersToRemove[this.triggerEvent].apply(this);
  }

  private initTooltipElement(): void {
    const wrapper = document.createElement('div');

    wrapper.innerHTML = `
      <div class="mpp-tooltip-container">
        <div class="mpp-tooltip-container__arrow"></div>

        <div class="mpp-tooltip-container__content"></div>
      </div>
    `;

    this.tooltipElement = wrapper.firstElementChild as HTMLElement;
    this.tooltipContentElement = this.tooltipElement.lastElementChild as HTMLElement;
  }

  private getPopperOptions(): Options {
    return this.placement
      ? { ...this.popperDefaultOptions, placement: this.placement }
      : this.popperDefaultOptions;
  }

  private setClickListeners(): IPopperEvents {
    const events: IPopperEvents = {
      show: () => {
        if (this.isCanNotBeShown() || !this.content) {
          return;
        }

        document.body.appendChild(this.tooltipElement);

        this.popper.update();
        this.appearanceAnimation();
      },
      hide: (event: MouseEvent) => {
        if (this.elementRef.nativeElement.contains(event.target)) {
          return;
        }

        this.tooltipElement.remove();
      },
    };

    this.elementRef.nativeElement.addEventListener('click', events.show);
    document.addEventListener('click', events.hide);

    return events;
  }

  private setHoverListeners(): IPopperEvents {
    const events: IPopperEvents = {
      show: () => {
        if (this.isCanNotBeShown() || !this.content) {
          return;
        }

        document.body.appendChild(this.tooltipElement);

        this.popper.update();
        this.appearanceAnimation();
      },
      hide: () => this.tooltipElement.remove(),
    };

    this.elementRef.nativeElement.addEventListener('mouseenter', events.show);
    this.elementRef.nativeElement.addEventListener('mouseleave', events.hide);

    return events;
  }

  private appearanceAnimation(): void {
    const DURATION = 300;
    const EASING = 'ease-out';

    this.tooltipElement.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: DURATION,
      easing: EASING,
    });
  }

  private removeHoverListeners(): void {
    if (!this.currentListeners) {
      return;
    }

    this.elementRef.nativeElement.removeEventListener('mouseenter', this.currentListeners.show);
    this.elementRef.nativeElement.removeEventListener('mouseleave', this.currentListeners.hide);
  }

  private removeClickListeners(): void {
    this.elementRef.nativeElement.removeEventListener('click', this.currentListeners.show);
    document.removeEventListener('click', this.currentListeners.hide);
  }

  private isCanNotBeShown(): boolean {
    const nativeElement = this.elementRef.nativeElement;
    const container = nativeElement.scrollWidth ? nativeElement : nativeElement.parentNode;

    return this.isOverflowShown && container.scrollWidth <= container.offsetWidth;
  }
}
