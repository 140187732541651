import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MppInformationSectionComponent } from './information-section.component';

@NgModule({
  declarations: [MppInformationSectionComponent],
  imports: [CommonModule],
  exports: [MppInformationSectionComponent],
})
export class MppInformationSectionModule {}
