<ng-template #actionsTemplate>
  <mpp-select-icons-panel (onClearSearchClick)="clearSearch()">
  </mpp-select-icons-panel>
</ng-template>

<ng-select class="simple-select__control"
           [formControl]="control"
           [searchable]="hasSearch && isOpen"
           [clearable]="false"
           [clearOnBackspace]="false"
           [items]="options"
           [placeholder]="placeholder | translate"
           [searchFn]="searchFn"
           bindValue="value"
           bindLabel="label"
           appendTo="body"
           (open)="onControlOpen()"
           (close)="onControlClose()"
           (search)="onSearch($event.term)">
  <ng-template ng-label-tmp
               let-item="item">
    <ng-container *ngIf="!hasSearch || !isOpen">
      <div class="label-container">
        <div class="label-container__content">
          <span class="label-container__item">{{ item.label }}</span>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template ng-option-tmp
               let-index="index"
               let-search="searchTerm">
    <div class="simple-select__option-wrapper">
      <div class="simple-select__option">
        <ng-container [ngTemplateOutlet]="options[index].templateRef"
                      [ngTemplateOutletContext]="{ search: search }">
        </ng-container>
      </div>

      <div class="simple-select__marker icon icon-check-outdated"></div>
    </div>
  </ng-template>

  <ng-template ng-notfound-tmp>
    <div class="not-found-option">
      {{ 'COMMON.DROPDOWNS.NO_ITEMS_FOUND' | translate }}
    </div>
  </ng-template>
</ng-select>
