<label class="mpp-checkbox__wrapper">
  <input type="checkbox"
         class="mpp-checkbox__control"
         *ngIf="!control"
         [checked]="checked"
         (change)="onChange.emit(checkbox.checked)"
         hidden
         #checkbox
  >

  <input type="checkbox"
         class="mpp-checkbox__control"
         *ngIf="control"
         [formControl]="control"
         hidden
  >

  <mpp-marker class="mpp-checkbox__marker"
              [isChecked]="isChecked"
              [isIndeterminate]="indeterminate"
              [disabled]="isDisabled"
  ></mpp-marker>

  <span class="mpp-checkbox__label">
    <ng-content></ng-content>
  </span>
</label>
