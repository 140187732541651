import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MppPipesModule } from '../../../../../pipes';
import { MppDayComponent } from './day/day.component';
import { MppCalendarComponent } from './calendar.component';
import { MppCalendarService } from './services/calendar.service';

@NgModule({
  declarations: [MppDayComponent, MppCalendarComponent],
  exports: [MppCalendarComponent],
  providers: [MppCalendarService],
  imports: [CommonModule, MppPipesModule],
})
export class MppCalendarModule {}
