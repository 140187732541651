import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MppTemplateOutletComponent } from './template-outlet.component';

@NgModule({
  declarations: [MppTemplateOutletComponent],
  imports: [CommonModule],
  entryComponents: [MppTemplateOutletComponent],
})
export class MppTemplateOutletModule {}
