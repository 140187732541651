import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MppAutoUnsubscribeComponent, MppNotificationError } from '@arturin/core';
import { AmUserPasswordService } from '@am/_services/user-password.service';
import { takeUntil } from 'rxjs/operators';
import {
  MIN_PASSWORD_LENGTH,
  PASSWORD_EQUALITY_FORM_VALIDATOR,
  RESET_PASSWORD_TOKEN_PARAM_NAME,
} from './reset-password-page.constants';
import { IResetPasswordData } from '@am/_types';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'am-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  encapsulation: ViewEncapsulation.None,
  host: { class: 'access-container' },
})
export class AmResetPasswordPageComponent extends MppAutoUnsubscribeComponent {
  public isResetting = false;

  public form: FormGroup = this.formBuilder.group(
    {
      password: ['', [Validators.required, Validators.minLength(MIN_PASSWORD_LENGTH)]],
      passwordConfirmation: ['', [Validators.required, Validators.minLength(MIN_PASSWORD_LENGTH)]],
    },
    {
      validators: PASSWORD_EQUALITY_FORM_VALIDATOR,
    }
  );

  public isPasswordExposed = false;

  public constructor(
    private readonly formBuilder: FormBuilder,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly userPasswordService: AmUserPasswordService,
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  public reset(): void {
    try {
      this.validateForm();

      this.form.disable();
      this.isResetting = true;

      const resetPasswordData: IResetPasswordData = {
        ...this.form.value,
        resetPasswordToken: this.token,
      };

      this.userPasswordService
        .reset$(resetPasswordData)
        .pipe(takeUntil(this.destroy$$))
        .subscribe(
          () => {
            const message: string = this.translateService.instant(
              'ROOT.RESET_PASSWORD_PAGE.PASSWORD_SUCCESSFULLY_UPDATED'
            );

            this.toastrService.success(message);

            this.router.navigateByUrl('/');
          },
          () => {
            this.isResetting = false;
            this.form.enable();
          }
        );
    } catch (error) {
      const message: string = this.translateService.instant(error.key, error.params);

      this.toastrService.error(message);
    }
  }

  private validateForm(): void {
    if (
      this.form.hasError('required', 'password') ||
      this.form.hasError('required', 'passwordConfirmation')
    ) {
      throw new MppNotificationError('ROOT.RESET_PASSWORD_PAGE.ERROR_REQUIRED_FIELD');
    }

    if (
      this.form.hasError('minlength', 'password') ||
      this.form.hasError('minlength', 'passwordConfirmation')
    ) {
      throw new MppNotificationError('ROOT.RESET_PASSWORD_PAGE.ERROR_MIN_LENGTH', {
        count: MIN_PASSWORD_LENGTH,
      });
    }

    if (this.form.errors) {
      throw new MppNotificationError('ROOT.RESET_PASSWORD_PAGE.ERROR_PASSWORD_MISMATCH');
    }
  }

  public get token(): string | undefined {
    return this.activatedRoute.snapshot.queryParams[RESET_PASSWORD_TOKEN_PARAM_NAME];
  }
}
