import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MppAutoUnsubscribeComponent } from '../../helpers';
import { MppImageMimeTypes, MppImagePickerSizes } from './enums';
import { IImageCardAction, IImageCardBadge } from './types';
import { DEFAULT_IMAGE_MIME_TYPES } from './image-picker.constants';

@Component({
  selector: 'mpp-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-image-picker' },
})
export class MppImagePickerComponent extends MppAutoUnsubscribeComponent {
  @Input() public image: { [key: string]: any; url: string };
  @Input() public readonly isRemovable: boolean = true;

  @Input() public readonly size: MppImagePickerSizes;
  @Input() public readonly acceptedMimeTypes: MppImageMimeTypes[] = DEFAULT_IMAGE_MIME_TYPES;

  @Input() public readonly addImageText: string;
  @Input() public readonly badge: IImageCardBadge;
  @Input() public readonly action: IImageCardAction;

  @Input()
  @HostBinding('class.loading')
  public readonly isLoading = false;

  @Output() public readonly onChange: EventEmitter<Blob> = new EventEmitter<Blob>();
  @Output() public readonly onRemove: EventEmitter<void> = new EventEmitter<void>();

  public onFileChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    this.onChange.emit(files[0]);
  }
}
