import { FormControl, FormGroupDirective, NgControl } from '@angular/forms';
import { Directive, Input, OnInit, Optional, Self } from '@angular/core';
import { startWith, subscribeOn, takeUntil } from 'rxjs/operators';
import { asyncScheduler, merge } from 'rxjs';
import { MppAutoUnsubscribeComponent } from '../helpers/auto-unsubscribe-component';
import { isErrorState } from '../helpers/error-state-matcher';
import { TErrorStateMatcher } from '../types';

// @TODO drop and reuse mppInput for access manager
@Directive({
  selector: '[mppControl]',
  exportAs: 'control',
})
export class MppControlDirective extends MppAutoUnsubscribeComponent implements OnInit {
  @Input() public readonly errorStateMatcher: TErrorStateMatcher = isErrorState;

  public errorState = false;

  public constructor(
    @Optional() @Self() private readonly ngControl: NgControl,
    @Optional() private parent: FormGroupDirective
  ) {
    super();
  }

  public ngOnInit(): void {
    if (!this.control) {
      return;
    }

    const statusChanges$ = [this.control.statusChanges];

    if (this.parent) {
      statusChanges$.push(this.parent.ngSubmit);
    }

    merge(...statusChanges$)
      .pipe(startWith(undefined), subscribeOn(asyncScheduler), takeUntil(this.destroy$$))
      .subscribe(() => {
        this.errorState = this.errorStateMatcher(this.control, this.parent);
      });
  }

  private get control(): FormControl {
    return this.ngControl.control as FormControl;
  }
}
