import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MppHttpErrorsHandlerService } from '../services/http-errors-handler.service';

@Injectable()
export class MppErrorsInterceptor implements HttpInterceptor {
  public constructor(private readonly httpErrorsHandlerService: MppHttpErrorsHandlerService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        error: (errorResponse: HttpErrorResponse): void => {
          this.httpErrorsHandlerService.handle(errorResponse);
        },
      })
    );
  }
}
