import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MppDirectivesModule } from '../../../directives';
import { MppPipesModule } from '../../../pipes';
import { MppLanguageService } from '../../../services/language.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { MppTagSelectComponent } from './tag-select.component';
import { COMMON_ERRORS_TOKEN } from '../form-controls.constants';

@NgModule({
  declarations: [MppTagSelectComponent],
  imports: [CommonModule, ReactiveFormsModule, MppDirectivesModule, MppPipesModule, NgSelectModule],
  exports: [MppTagSelectComponent],
  providers: [MppLanguageService],
})
export class MppTagSelectModule {
  public static forRoot(errors: Record<string, string>): ModuleWithProviders<MppTagSelectModule> {
    return {
      ngModule: MppTagSelectModule,
      providers: [{ provide: COMMON_ERRORS_TOKEN, useValue: errors }],
    };
  }

  public static forChild(): ModuleWithProviders<MppTagSelectModule> {
    return { ngModule: MppTagSelectModule };
  }
}
