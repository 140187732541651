import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MppRadioButtonComponent } from './radio-button.component';

@NgModule({
  declarations: [MppRadioButtonComponent],
  imports: [CommonModule],
  exports: [MppRadioButtonComponent],
})
export class MppRadioButtonModule {}
