<div class="mpp-date-picker__container icon icon-calendar-filled">
  <mpp-date-time-input
    [value]="control.value"
    [isCentered]="false"
    [placeholder]="placeholder"
    [format]="inputFormat"
    (onTyping)="onTyping()"
    (onFocus)="onFocus()"
    (onChange)="onChange($event)"
  ></mpp-date-time-input>
</div>

<ng-template #dropdownTemplate>
  <mpp-calendar #calendar
                [selected]="[control.value, control.value]"
                (onSelect)="onSelect($event)"
                [min]="min"
                [max]="max"
                [viewMode]="viewMode"
  ></mpp-calendar>
</ng-template>


