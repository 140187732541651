import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

const SLIDE_IN_OUT_ANIMATION_TIMING = '300ms cubic-bezier(0.25, 0.1, 0.25, 1)';

export const SLIDE_IN_OUT_ANIMATION: AnimationTriggerMetadata = trigger('slideInOut', [
  transition('* => in', [
    style({ transform: 'translateX(0%)' }),
    animate(SLIDE_IN_OUT_ANIMATION_TIMING, style({ transform: 'translateX(-100%)' })),
  ]),
  transition('* => out', [
    style({ transform: 'translateX(0%)' }),
    animate(SLIDE_IN_OUT_ANIMATION_TIMING, style({ transform: 'translateX(100%)' })),
  ]),
]);
