import { Options } from '@popperjs/core';

export const DROPDOWN_OPTIONS: Partial<Options> = {
  placement: 'bottom-start',
  strategy: 'absolute',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 4],
      },
    },
  ],
};
