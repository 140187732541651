import { NgModule } from '@angular/core';
import { MppInitialsPipe } from './initials.pipe';
import { MppSanitizeHtmlPipe } from './sanitize-html.pipe';
import { MppPhoneNumberFormatPipe } from './phone-number-format.pipe';
import { MppDateFormatPipe } from './date-format.pipe';
import { MppGroupDigitsPipe } from './group-digits.pipe';
import { MppUtilitiesService } from '../services/utitities.service';
import { MppCurrencyPipe } from './currency.pipe';

@NgModule({
  declarations: [
    MppInitialsPipe,
    MppSanitizeHtmlPipe,
    MppPhoneNumberFormatPipe,
    MppDateFormatPipe,
    MppGroupDigitsPipe,
    MppCurrencyPipe,
  ],
  exports: [
    MppInitialsPipe,
    MppSanitizeHtmlPipe,
    MppPhoneNumberFormatPipe,
    MppDateFormatPipe,
    MppGroupDigitsPipe,
    MppCurrencyPipe,
  ],
  providers: [MppUtilitiesService],
})
export class MppPipesModule {}
