import { Routes } from '@angular/router';
import { AmLoginPageComponent } from './login-page/login-page.component';
import { AmForgotPasswordPageComponent } from './forgot-password-page/forgot-password-page.component';
import { AmResetPasswordPageComponent } from './reset-password-page/reset-password-page.component';
import { ROUTE_PATHS } from '@am/root.constants';
import { AmAccessGuard } from '@am/_guards/access.guard';
import { AmResetPasswordPageGuard } from '@am/reset-password-page/_guards/reset-password-page.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AmLoginPageComponent,
    data: {
      pageTitle: 'ROOT.PAGE_TITLES.LOGIN_PAGE',
    },
    canActivate: [AmAccessGuard],
  },
  {
    path: ROUTE_PATHS.FORGOT_PASSWORD,
    component: AmForgotPasswordPageComponent,
    data: {
      pageTitle: 'ROOT.PAGE_TITLES.FORGOT_PASSWORD_PAGE',
    },
    canActivate: [AmAccessGuard],
  },
  {
    path: ROUTE_PATHS.RESET_PASSWORD,
    component: AmResetPasswordPageComponent,
    canActivate: [AmAccessGuard, AmResetPasswordPageGuard],
    data: {
      pageTitle: 'ROOT.PAGE_TITLES.RESET_PASSWORD_PAGE',
    },
  },
  {
    path: '**',
    redirectTo: '/',
  },
];
