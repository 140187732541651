import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MppTimeRangeComponent } from './time-range.component';
import { NgxMaskModule } from 'ngx-mask';
import { ReactiveFormsModule } from '@angular/forms';
import { MppTimeRangeService } from './services/time-range.service';
import { MppPipesModule } from '../../../../pipes';
import { MppDateTimeInputModule } from '../components/date-time-input/date-time-input.module';

@NgModule({
  imports: [
    CommonModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    MppDateTimeInputModule,
    MppPipesModule,
  ],
  declarations: [MppTimeRangeComponent],
  exports: [MppTimeRangeComponent],
  providers: [MppTimeRangeService],
})
export class MppTimeRangeModule {
  public static forRoot(): ModuleWithProviders<MppTimeRangeModule> {
    return {
      ngModule: MppTimeRangeModule,
    };
  }

  public static forChild(): ModuleWithProviders<MppTimeRangeModule> {
    return {
      ngModule: MppTimeRangeModule,
    };
  }
}
