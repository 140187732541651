<ng-template #actionsTemplate>
  <mpp-select-icons-panel (onClearSearchClick)="clearSearch()">
  </mpp-select-icons-panel>
</ng-template>

<ng-select class="group-multiselect__control"
           [ngModel]="innerValue"
           [items]="items"
           [clearable]="false"
           [multiple]="true"
           [closeOnSelect]="false"
           [searchable]="isOpen"
           [searchFn]="searchFn"
           [selectableGroup]="true"
           [selectableGroupAsModel]="false"
           [clearOnBackspace]="false"
           [groupBy]="groupBy"
           [bindValue]="valueField"
           [placeholder]="placeholder | translate"
           [class.all-selected]="isSelectAllChecked"
           appendTo="body"
           (open)="onControlOpen()"
           (close)="onControlClose()"
           (search)="onSearch($event.term)"
           (ngModelChange)="onNgModelChange($event)">
  <ng-template ng-multi-label-tmp>
    <ng-container *ngIf="!isOpen && !isSelectAllChecked">
      <div class="label-container">
        <div class="label-container__content">
          <span class="label-container__item"
                *ngFor="let item of uniqueSelectedItems">{{ item[labelField] }}</span>
        </div>

        <div class="label-container__counter selected-items-counter"
             *ngIf="uniqueSelectedItems.length">
          {{ uniqueSelectedItems.length }}
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template ng-header-tmp>
    <mpp-checkbox [checked]="isSelectAllChecked"
                  [indeterminate]="isSelectAllIndeterminate()"
                  (onChange)="$event ? selectAll() : unselectAll()"
                  #selectAllCheckbox>
      <span class="multiple-item-wrapper">
        <span class="multiple-item-wrapper__content">
          {{ selectAllLabel | translate }}
        </span>

        <span class="multiple-item-wrapper__counter selected-items-counter"
              *ngIf="selectAllCheckbox.checked">
          {{ uniqueSelectedItems.length }}
        </span>
      </span>
    </mpp-checkbox>
  </ng-template>

  <ng-template ng-optgroup-tmp
               let-item="item"
               let-item$="item$">
    <button class="group-multiselect__expander-button icon icon-arrow-down-outdated"
            [class.active]="expandedGroupIds.has(getOptionValue(item$))"
            (click)="onGroupVisibilityToggleClick($event, item$)"
            type="button">
    </button>

    <mpp-checkbox [checked]="item$.selected"
                  [indeterminate]="isGroupOptionIndeterminate(item$)"
                  #groupCheckbox
                  (click)="onGroupOptionClick($event, item$)">
      <div class="multiple-item-wrapper">
        <div class="multiple-item-wrapper__content">
          <ng-container [ngTemplateOutlet]="groupOptionTemplate"
                        [ngTemplateOutletContext]="{ item: item }">
          </ng-container>
        </div>

        <div class="multiple-item-wrapper__counter selected-items-counter"
             *ngIf="groupCheckbox.checked || groupCheckbox.indeterminate">
          {{ getGroupSelectedCounter(item$) }}
        </div>
      </div>
    </mpp-checkbox>
  </ng-template>

  <ng-template ng-option-tmp
               let-item="item"
               let-item$="item$"
               let-search="searchTerm">
    <mpp-checkbox [checked]="item$.selected"
                  [disabled]="item$.disabled"
                  (click)="onOptionClick($event, item$)">
      <ng-container [ngTemplateOutlet]="optionTemplate"
                    [ngTemplateOutletContext]="{ item: item, search: search }">
      </ng-container>
    </mpp-checkbox>
  </ng-template>

  <ng-template ng-notfound-tmp>
    <div class="not-found-option">
      {{ 'COMMON.DROPDOWNS.NO_ITEMS_FOUND' | translate }}
    </div>
  </ng-template>
</ng-select>
