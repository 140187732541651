import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MppButtonComponent } from './button.component';
import { MppSpinnerModule } from '../spinner';

@NgModule({
  declarations: [MppButtonComponent],
  imports: [CommonModule, MppSpinnerModule],
  exports: [MppButtonComponent],
})
export class MppButtonModule {}
