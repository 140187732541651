import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MppBaseFormControl } from '../helpers/base-form-control';
import { COMMON_ERRORS_TOKEN } from '../form-controls.constants';
import { MppLanguageService } from '../../../services/language.service';
import { TCommonErrors } from '../types/common-errors';

@Component({
  selector: 'mpp-textarea',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-textarea' },
})
export class MppTextareaComponent extends MppBaseFormControl {
  @Input()
  public readonly maxLength: number;

  @Input()
  public readonly characterLeftText: string;

  @Input()
  public readonly linkButtonText: string;

  @Input()
  public readonly linkText: string;

  @Input()
  public readonly linkSize: number;

  @ViewChild('textAreaElement') private textAreaElement: ElementRef;

  private previousValue = '';

  public constructor(
    @Inject(COMMON_ERRORS_TOKEN) COMMON_ERRORS: TCommonErrors,
    languageService: MppLanguageService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(languageService, changeDetectorRef, COMMON_ERRORS);
  }

  public get charactersLeft(): number {
    const linksNumber = this.linkButtonText
      ? this.control.value.match(new RegExp(this.linkText, 'g'))?.length
      : 0;
    const additionalTextSize = linksNumber
      ? linksNumber * this.linkSize - linksNumber * (this.linkText.length + 4)
      : 0;
    return this.maxLength - (this.control.value?.length + additionalTextSize || 0);
  }

  public insertLinkStructure(event: Event): void {
    const textarea = this.textAreaElement.nativeElement;
    const currentValue = textarea.value;

    if (this.charactersLeft < this.linkSize) {
      this.control.setErrors({ maxlength: true });
      return;
    }
    const selectionStart = textarea.selectionStart;
    const selectionEnd = textarea.selectionEnd;
    const linkStructure = `{{${this.linkText}}}`;
    const newValue =
      currentValue.substring(0, selectionStart) +
      linkStructure +
      currentValue.substring(selectionEnd);

    textarea.value = newValue;
    textarea.setSelectionRange(
      selectionStart + linkStructure.length,
      selectionStart + linkStructure.length
    );
    this.previousValue = newValue;
    this.control.setValue(newValue);

    event.preventDefault();
  }

  public onTextareaInput(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    const currentValue = textarea.value;

    if (currentValue.length < this.previousValue.length) {
      const diffIndex = this.findFirstDifferenceIndex(this.previousValue, currentValue);

      if (diffIndex >= 0) {
        const linkStructure = this.findContainingLinkStructure(this.previousValue, diffIndex);

        if (linkStructure) {
          const newTextValue =
            this.previousValue.slice(0, linkStructure.startIndex) +
            this.previousValue.slice(linkStructure.endIndex);
          textarea.value = newTextValue;
          this.control.setValue(newTextValue);
          this.previousValue = newTextValue;
        }
      }
    } else {
      this.previousValue = currentValue;
    }

    this.onTyping.emit(event);
  }

  private findFirstDifferenceIndex(str1: string, str2: string): number {
    const minLength = Math.min(str1.length, str2.length);

    for (let i = 0; i < minLength; i++) {
      if (str1[i] !== str2[i]) {
        return i;
      }
    }

    if (str1.length !== str2.length) {
      return minLength;
    }

    return -1;
  }

  private findContainingLinkStructure(
    str: string,
    index: number
  ): { startIndex: number; endIndex: number } | null {
    const pattern = /{{LIEN}}/g;
    let match;
    let startIndex = 0;
    let endIndex = 0;

    while ((match = pattern.exec(str)) !== null) {
      if (index >= match.index && index < match.index + match[0].length) {
        startIndex = match.index;
        endIndex = match.index + match[0].length;
        break;
      }
    }

    if (startIndex !== 0 || endIndex !== 0) {
      return { startIndex, endIndex };
    }

    return null;
  }
}
