import { MppConfirmationType } from '../enums';

export interface IConfirmationConfig {
  type: MppConfirmationType;
  confirmText: string;
  declineText: string;
  titleText: string;
  descriptionText: string;
  bodyText: string;
  confirmIcon?: string;
}
