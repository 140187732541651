import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { validatePhoneNumberLength } from 'libphonenumber-js';
import { MppPhoneNumberErrors } from '../enums';

export function lengthPhoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const validateLength = validatePhoneNumberLength(control.value);
    const isValidPhoneNumber: boolean =
      validateLength !== MppPhoneNumberErrors.TOO_LONG &&
      validateLength !== MppPhoneNumberErrors.NOT_A_NUMBER;

    return isValidPhoneNumber ? null : { pattern: true };
  };
}
