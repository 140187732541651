import { MppUserRegions } from '.././../../../enums';
import { TRegionDateFormat } from '../types';

export const DEFAULT_DATE_INPUT_FORMAT: TRegionDateFormat = {
  [MppUserRegions.US]: {
    mask: 'm0/d0',
    date: 'MM/dd',
    placeholder: 'dd MMM.',
  },
  [MppUserRegions.FR]: {
    mask: 'd0/m0',
    date: 'dd/MM',
    placeholder: 'dd MMM.',
  },
};

export const DEFAULT_MONTH_VIEW_FORMAT: TRegionDateFormat = {
  [MppUserRegions.US]: {
    mask: 'm0/0000',
    date: 'MM/yyyy',
    placeholder: 'MMM. yyyy',
  },
  [MppUserRegions.FR]: {
    mask: 'm0/0000',
    date: 'MM/yyyy',
    placeholder: 'MMM. yyyy',
  },
};

export const DEFAULT_YEAR_VIEW_FORMAT: TRegionDateFormat = {
  [MppUserRegions.US]: {
    mask: '0000',
    date: 'yyyy',
    placeholder: 'yyyy',
  },
  [MppUserRegions.FR]: {
    mask: '0000',
    date: 'yyyy',
    placeholder: 'yyyy',
  },
};

export const DAY_FORMAT = 'dd';

export const WEEK_DAY_FORMAT = 'EEEEEE';

export const MONTH_FORMAT = 'MMM';

export const YEAR_FORMAT = 'yyyy';
