<div class="mpp-filters-bar__search" *ngIf="searchTemplate">
  <ng-container *ngTemplateOutlet="searchTemplate"></ng-container>
</div>

<div class="mpp-filters-bar__content">
  <ng-content></ng-content>
</div>

<div class="mpp-filters-bar__actions" *ngIf="actionsTemplate">
  <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
</div>
