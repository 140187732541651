import { MppCleanHttpClientFactory } from '../services/clean-http-client-factory';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class MppTranslateHttpLoader implements TranslateLoader {
  private readonly http: HttpClient = this.cleanHttpClientFactory.create();

  public constructor(private readonly cleanHttpClientFactory: MppCleanHttpClientFactory) {}

  public getTranslation(lang: string): Observable<Record<string, any>> {
    return this.http.get(`/assets/i18n/texts/${lang}.json?${Date.now()}`);
  }
}
