import { Inject, Injectable } from '@angular/core';
import { endOfYesterday } from 'date-fns';
import { IBaseEnvironment } from '../types';
import { ENV_TOKEN } from '@arturin/constants';

@Injectable()
export class MppAccessTokenService {
  public constructor(@Inject(ENV_TOKEN) private readonly env: IBaseEnvironment) {}

  public create(value: string, expirationAt: string, remember: boolean): void {
    let cookieParams = `accessToken=${value}; path=/; domain=${this.env.baseDomain}`;

    if (remember) {
      const formattedExpirationAt = new Date(expirationAt).toUTCString();
      cookieParams += `; expires=${formattedExpirationAt}`;
    }

    document.cookie = cookieParams;
  }

  public destroy(): void {
    const expiredDate = endOfYesterday().toUTCString();

    document.cookie = `accessToken=${this.value}; path=/; expires=${expiredDate}; domain=${this.env.baseDomain}`;
    document.cookie = `accessToken=${this.value}; path=/; expires=${expiredDate}; domain=${window.location.hostname}`;
  }

  public get exist(): boolean {
    return this.value.length > 0;
  }

  public get value(): string {
    const matches = document.cookie.match(/(?:^|; )accessToken=([^;]*)/);
    return (matches && matches[1]) || '';
  }
}
