import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[mppClickOutside]',
})
export class MppClickOutsideDirective {
  @Output('mppClickOutside')
  public readonly clickOutside: EventEmitter<void> = new EventEmitter<void>();

  public constructor(private element: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onDocumentClick(target: EventTarget): void {
    if (this.element.nativeElement.contains(target)) {
      return;
    }

    this.clickOutside.emit();
  }
}
