import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { toSnakeCase } from '../helpers';
import { MppHttpParameterEncoder } from '../encoders';

@Injectable()
export class MppParamsFormatInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req.clone({ params: this.getUpdatedParams(req.params) }));
  }

  private getUpdatedParams(params: HttpParams): HttpParams {
    const updatedParams = {};

    params.keys().forEach((paramKey) => {
      updatedParams[toSnakeCase(paramKey)] = params.getAll(paramKey);
    });

    return new HttpParams({ encoder: new MppHttpParameterEncoder(), fromObject: updatedParams });
  }
}
