import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, mapTo, tap } from 'rxjs/operators';
import { MppAccessTokenService } from '@arturin/core';

@Injectable()
export class AmAccessGuard implements CanActivate {
  public constructor(
    private readonly accessTokenService: MppAccessTokenService,
    private readonly http: HttpClient
  ) {}

  public canActivate(): Observable<boolean> {
    if (!this.accessTokenService.exist) {
      return of(true);
    }

    return this.getPermittedBackOfficeUrl$().pipe(
      tap((redirectUrl) => (window.location.href = redirectUrl)),
      mapTo(false),
      catchError(() => of(true))
    );
  }

  private getPermittedBackOfficeUrl$(): Observable<string> {
    return this.http
      .get<{ backoffice_url: string }>('api/v2/me')
      .pipe(map((data) => data.backoffice_url));
  }
}
