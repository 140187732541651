import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { MppButtonModule } from '../../button';
import { MppMediaDialogComponent } from './media-dialog.component';

@NgModule({
  declarations: [MppMediaDialogComponent],
  imports: [CommonModule, MppButtonModule, SwiperModule],
  exports: [MppMediaDialogComponent],
})
export class MppMediaDialogModule {}
