import { ModuleWithProviders, NgModule } from '@angular/core';
import { MppAccessTokenService } from './access-token.service';
import { ENV_TOKEN } from '@arturin/constants';
import { MppCleanHttpClientFactory } from './clean-http-client-factory';
import { MppComponentInitializerService } from './component-initializer.service';
import { MppHttpErrorsHandlerService } from './http-errors-handler.service';
import { MppLanguageService } from './language.service';
import { MppPageTitleService } from './page-title.service';
import { MppUserSessionService } from './user-session.service';
import { IBaseEnvironment } from '../types';

@NgModule()
export class MppServicesModule {
  public static forRoot(env: IBaseEnvironment): ModuleWithProviders<MppServicesModule> {
    return {
      ngModule: MppServicesModule,
      providers: [
        MppAccessTokenService,
        MppCleanHttpClientFactory,
        MppComponentInitializerService,
        MppHttpErrorsHandlerService,
        MppLanguageService,
        MppPageTitleService,
        MppUserSessionService,
        { provide: ENV_TOKEN, useValue: env },
      ],
    };
  }
}
