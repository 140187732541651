import { MppSocialCredentialStatus, MppSocialNetwork } from '../enums';
import { IRawSocialCredential } from '../types';

export class MppSocialCredential {
  public constructor(
    public id: number | null,
    public activeName: string | null,
    public oauthExpiresAt: string | null,
    public profilePictureUrl: string | null,
    public profileUrl: string | null,
    public showChangeButton: boolean,
    public socialNetwork: MppSocialNetwork,
    public status: MppSocialCredentialStatus,
    public activePageId: string | null,
    public uid: string | null,
    public reviewsUrl: string | null
  ) {}

  public static fromRawData(value: IRawSocialCredential): MppSocialCredential {
    return new MppSocialCredential(
      value.id,
      value.active_name,
      value.oauth_expires_at,
      value.profile_picture_url,
      value.profile_url,
      value.show_change_button,
      value.social_network,
      value.status,
      value.active_page_id,
      value.uid,
      value.reviews_url
    );
  }

  public makeInactive(): MppSocialCredential {
    return new MppSocialCredential(
      null,
      null,
      null,
      null,
      null,
      false,
      this.socialNetwork,
      MppSocialCredentialStatus.NOT_CONNECTED,
      null,
      null,
      null
    );
  }
}
