import {
  Attribute,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { MppBaseFormControl } from '../helpers/base-form-control';
import { MppLanguageService } from '../../../services/language.service';
import { COMMON_ERRORS_TOKEN } from '../form-controls.constants';
import { TCommonErrors } from '../types/common-errors';

@Component({
  selector: 'mpp-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-input' },
})
export class MppInputComponent extends MppBaseFormControl {
  @Input()
  public readonly actionIcon: string;

  @Input()
  public readonly type: string = 'text';

  @Input()
  public readonly maxLength: number;

  @Input()
  public readonly characterLeftText: string;

  @Output()
  public readonly onActionIconClick: EventEmitter<Event> = new EventEmitter();

  @ContentChild('actionIconTemplate')
  public readonly actionIconTemplate: TemplateRef<any>;

  public get charactersLeft(): number {
    return this.maxLength - (this.control.value?.length || 0);
  }

  public constructor(
    @Inject(COMMON_ERRORS_TOKEN) COMMON_ERRORS: TCommonErrors,
    languageService: MppLanguageService,
    changeDetectorRef: ChangeDetectorRef,
    @Attribute('prefixIcon') public readonly prefixIcon?: string
  ) {
    super(languageService, changeDetectorRef, COMMON_ERRORS);
  }
}
