<label class="rt-input__container">
  <span class="rt-input__placeholder"
        *ngIf="placeholder"
        [attr.data-text]="placeholder | translate"
  ></span>

  <input class="rt-input__control"
         mppControl
         [type]="type"
         [formControl]="control"
         (focus)="isFocused = true"
         (blur)="onBlur()"
  >

  <span class="rt-input__suffix-wrapper">
    <ng-content select="[suffix]"></ng-content>
  </span>
</label>
