import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MppDatePickerComponent } from './date-picker.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MppPipesModule } from '../../../../pipes';
import { MppCalendarModule } from '../components/calendar/calendar.module';
import { MppDateTimeInputModule } from '../components/date-time-input/date-time-input.module';

@NgModule({
  declarations: [MppDatePickerComponent],
  exports: [MppDatePickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MppPipesModule,
    MppCalendarModule,
    MppDateTimeInputModule,
  ],
})
export class MppDatePickerModule {
  public static forRoot(): ModuleWithProviders<MppDatePickerModule> {
    return {
      ngModule: MppDatePickerModule,
    };
  }

  public static forChild(): ModuleWithProviders<MppDatePickerModule> {
    return {
      ngModule: MppDatePickerModule,
    };
  }
}
