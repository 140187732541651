<span class="mpp-form-field__header" *ngIf="labelText">
    {{ labelText }}

  <span class="mpp-form-field__hint icon icon-info-outlined"
        *ngIf="tooltipText"
        mppTooltip
        [content]="tooltipText"
  ></span>

    <mark *ngIf="optionalLabelText">{{ optionalLabelText }}</mark>
  </span>

<span class="mpp-form-field__body">
    <ng-content></ng-content>
  </span>
