import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MppAutoUnsubscribeComponent } from '../../../../../helpers';
import {
  addMonths,
  addYears,
  getDate,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  isDate,
  isSameDay,
  isSameMonth,
  isSameYear,
  isThisMonth,
  isThisYear,
  isWithinInterval,
  set,
  startOfToday,
  subMonths,
  subYears,
} from 'date-fns';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { MppRange, MppViewModes } from '../../enums';
import {
  DAY_FORMAT,
  MONTH_FORMAT,
  WEEK_DAY_FORMAT,
  YEAR_FORMAT,
} from '../../date-range/date-range.constants';
import { MppCalendarService } from './services/calendar.service';
import {
  CALENDAR_TITLE_MONTH_VIEW_FORMAT,
  CALENDAR_TITLE_YEARS_VIEW_FORMAT,
  MODES_ORDER_STEP,
  MODES_SWITCH_ORDER,
} from './calendar.constants';

@Component({
  selector: 'mpp-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-calendar' },
})
export class MppCalendarComponent extends MppAutoUnsubscribeComponent implements OnInit, OnDestroy {
  @Input()
  public date: Date | null;

  @Input()
  public readonly selected: (Date | null)[];

  @Output()
  public readonly onSelect: EventEmitter<Date> = new EventEmitter();

  @Input()
  public readonly min: Date;

  @Input()
  public readonly max: Date;

  @Input()
  public readonly disableDates: (Date | Date[])[];

  @Input()
  public readonly viewMode: MppViewModes;

  public readonly DAY_FORMAT: string = DAY_FORMAT;

  public readonly WEEK_DAY_FORMAT: string = WEEK_DAY_FORMAT;

  public readonly MONTH_FORMAT: string = MONTH_FORMAT;

  public readonly YEAR_FORMAT: string = YEAR_FORMAT;

  public readonly today: Date = startOfToday();

  public daysOfWeek: Date[];
  public days: Date[];
  public years: Date[];
  public months: Date[];

  public activeViewPanel: MppViewModes;

  public readonly viewPanels: typeof MppViewModes = MppViewModes;
  public readonly range: typeof MppRange = MppRange;

  private currentDate$$: BehaviorSubject<Date>;

  private readonly viewPanel$$: Subject<MppViewModes> = new Subject();

  public get currentDate(): Date {
    return this.currentDate$$.value;
  }

  public get titleFormat(): string {
    const titleFormatMap: Record<MppViewModes, string> = {
      [MppViewModes.DAYS]: CALENDAR_TITLE_MONTH_VIEW_FORMAT,
      [MppViewModes.MONTHS]: CALENDAR_TITLE_YEARS_VIEW_FORMAT,
      [MppViewModes.YEARS]: CALENDAR_TITLE_YEARS_VIEW_FORMAT,
    };

    return titleFormatMap[this.activeViewPanel];
  }

  public get decadeBoundaries(): Date[] {
    const firstDate: Date = this.years[0];
    const laseDate: Date = this.years[this.years.length - 1];

    return [firstDate, laseDate];
  }

  public constructor(private readonly calendarService: MppCalendarService) {
    super();
  }

  public ngOnInit(): void {
    this.initViewPanel();
    this.initCurrentDate();
    this.initStaticRanges();
    this.initDateChangeListener();
  }

  public onChangeView(): void {
    const ordersLength: number = MODES_SWITCH_ORDER.length;
    const activePanelIndex: number = this.getOrderViewIndex(this.activeViewPanel);
    const nextIndex = (activePanelIndex + MODES_ORDER_STEP) % ordersLength;

    this.viewPanel$$.next(MODES_SWITCH_ORDER[nextIndex]);
  }

  public onBackward(): void {
    const navigationMap: Record<MppViewModes, any> = {
      [MppViewModes.DAYS]: subMonths(this.currentDate, 1),
      [MppViewModes.MONTHS]: subYears(this.currentDate, 1),
      [MppViewModes.YEARS]: this.calendarService.subDecade(this.currentDate, 1),
    };

    this.currentDate$$.next(navigationMap[this.activeViewPanel]);
  }

  public onForward(): void {
    const navigationMap: Record<MppViewModes, any> = {
      [MppViewModes.DAYS]: addMonths(this.currentDate, 1),
      [MppViewModes.MONTHS]: addYears(this.currentDate, 1),
      [MppViewModes.YEARS]: this.calendarService.addDecade(this.currentDate, 1),
    };

    this.currentDate$$.next(navigationMap[this.activeViewPanel]);
  }

  public onDateSelect(date: Date): void {
    const selectedDate: Record<MppViewModes, Date> = {
      [MppViewModes.DAYS]: set(this.currentDate, { date: getDate(date) }),
      [MppViewModes.MONTHS]: set(this.currentDate, { month: getMonth(date) }),
      [MppViewModes.YEARS]: set(this.currentDate, { year: getYear(date) }),
    };

    this.currentDate$$.next(selectedDate[this.activeViewPanel]);

    if (this.viewMode === this.activeViewPanel) {
      this.onSelect.emit(this.currentDate);
    }

    const activeOrderIndex: number = this.getOrderViewIndex(this.activeViewPanel);
    const prevPanelIndex: number = this.getOrderViewIndex(activeOrderIndex - MODES_ORDER_STEP);

    this.viewPanel$$.next(MODES_SWITCH_ORDER[prevPanelIndex]);
  }

  public isDisabledDate(currentDate: Date): boolean {
    const range: boolean[] | null = this.disableDates?.map((date) =>
      Array.isArray(date)
        ? isWithinInterval(currentDate, {
            start: date[MppRange.START],
            end: date[MppRange.END],
          })
        : isSameDay(date, currentDate)
    );

    return (
      isBefore(currentDate, this.min) || isAfter(currentDate, this.max) || range?.includes(true)
    );
  }

  public isSelectedDate(date: Date): boolean {
    if (!this.selected.find(isDate)) {
      return false;
    }

    const [start, end] = this.selected as Date[];

    return isWithinInterval(date, { start, end });
  }

  public isStartDay(date: Date): boolean {
    const startDate: Date | null = this.selected[MppRange.START];

    return !!startDate && isSameDay(date, startDate);
  }

  public isEndDay(date: Date): boolean {
    const endDate: Date | null = this.selected[MppRange.END];

    return !!endDate && isSameDay(date, endDate);
  }

  public isSameYear(date: Date): boolean {
    return isSameYear(date, this.today);
  }

  public isThisMonth(date: Date): boolean {
    return isThisMonth(date);
  }

  public isThisYear(): boolean {
    return isThisYear(this.currentDate);
  }

  public isSameMonth(date: Date, month: Date): boolean {
    return isSameMonth(date, month);
  }

  private initCurrentDate(): void {
    const initialDate: Date = this.date || this.selected[MppRange.END] || this.today;

    this.currentDate$$ = new BehaviorSubject(initialDate);
  }

  private initStaticRanges(): void {
    this.daysOfWeek = this.calendarService.getWeekRange(this.today);
    this.months = this.calendarService.getMonthsRange(this.today);
    this.years = this.calendarService.getYearsRange(this.today);
  }

  private initDateChangeListener(): void {
    this.currentDate$$
      .pipe(
        takeUntil(this.destroy$$),
        map((date: Date) => [
          this.calendarService.getDaysRange(date),
          this.calendarService.getMonthsRange(date),
          this.calendarService.getYearsRange(date),
        ])
      )
      .subscribe(([daysRange, monthsRange, yearsRange]) => {
        this.days = daysRange;
        this.months = monthsRange;
        this.years = yearsRange;
      });
  }

  private initViewPanel(): void {
    this.viewPanel$$
      .pipe(
        startWith(this.viewMode),
        map((view) => {
          const viewIndex = this.getOrderViewIndex(this.viewMode);
          const panelIndex = this.getOrderViewIndex(view);

          return viewIndex > panelIndex
            ? MODES_SWITCH_ORDER[viewIndex]
            : MODES_SWITCH_ORDER[panelIndex];
        }),
        takeUntil(this.destroy$$)
      )
      .subscribe((view) => (this.activeViewPanel = view));
  }

  private getOrderViewIndex(view: MppViewModes): number {
    return MODES_SWITCH_ORDER.findIndex((mode) => mode === view);
  }
}
