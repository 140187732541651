import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'mpp-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'mpp-star-rating' },
})
export class MppStarRatingComponent {
  @Input()
  private readonly rate: number;

  private readonly MAX_STAR_COUNT: number = 5;

  public getStarsRating(): boolean[] {
    const progressList: boolean[] = new Array(this.MAX_STAR_COUNT).fill(false);

    return progressList.map((_item: boolean, index: number) => index < this.rate);
  }

  public getImgPath(starFlag: boolean): string {
    const STAR_FILL = 'star.svg';
    const STAR_EMPTY = 'star-empty.svg';

    return `assets/images/${starFlag ? STAR_FILL : STAR_EMPTY}`;
  }
}
