import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MppAutoUnsubscribeComponent } from '../../helpers';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SEARCHING_DROPDOWN_PANEL_CLASS } from './dropdowns.constants';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class MppBaseDropdownComponent
  extends MppAutoUnsubscribeComponent
  implements AfterViewInit, OnDestroy
{
  @Input() public readonly panelCssClass?: string;

  @Output() public readonly onOpen: EventEmitter<void> = new EventEmitter();

  @Output() public readonly onClose: EventEmitter<void> = new EventEmitter();

  @ViewChild(NgSelectComponent, { static: true })
  public selectComponent: NgSelectComponent;

  @ViewChild(NgSelectComponent, { read: ElementRef, static: true })
  public selectElementRef: ElementRef<HTMLElement>;

  @ViewChild('actionsTemplate', { static: true })
  public actionsTemplateRef: TemplateRef<void>;

  public isOpen = false;
  public isSearching = false;

  public dropdownPanelElement: HTMLElement | null = null;

  protected actionsEmbeddedViewRef: EmbeddedViewRef<void> | undefined;

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly elementRef: ElementRef<HTMLElement>,
    protected readonly viewContainerRef: ViewContainerRef
  ) {
    super();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.actionsEmbeddedViewRef) {
      this.actionsEmbeddedViewRef.destroy();
    }
  }

  public ngAfterViewInit(): void {
    this.actionsEmbeddedViewRef = this.viewContainerRef.createEmbeddedView(this.actionsTemplateRef);

    this.selectElementRef.nativeElement
      .querySelector('.ng-select-container')!
      .appendChild(this.actionsEmbeddedViewRef.rootNodes[0]);
  }

  public onControlOpen(): void {
    this.isOpen = true;
    this.onOpen.emit();

    setTimeout(() => {
      this.dropdownPanelElement = document.querySelector<HTMLElement>('.ng-dropdown-panel')!;

      if (this.panelCssClass) {
        this.dropdownPanelElement.classList.add(this.panelCssClass);
      }
    }, 0);
  }

  public onSearch(term: string): void {
    this.isSearching = !!term;

    if (this.isSearching) {
      this.dropdownPanelElement!.classList.add(SEARCHING_DROPDOWN_PANEL_CLASS);
    } else {
      this.dropdownPanelElement!.classList.remove(SEARCHING_DROPDOWN_PANEL_CLASS);
    }
  }

  public clearSearch(): void {
    this.selectComponent.searchTerm = '';
    this.selectComponent.itemsList.resetFilteredItems();

    this.onSearch('');

    this.changeDetectorRef.detectChanges();
    this.selectComponent.detectChanges();
  }
}
