<div class="mpp-icons-panel__search-icon icon icon-search-outlined">
</div>

<button class="mpp-icons-panel__clear-button icon icon-close-circle-filled"
        (click)="onClearSearchClick.emit()"
        type="button"
></button>

<button class="mpp-icons-panel__dropdown-button icon icon-arrow-ios-downward-outlined"
        type="button"
>
</button>
