import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MppCheckboxComponent } from './checkbox.component';
import { MppMarkerComponent } from './marker/marker.component';

@NgModule({
  imports: [ReactiveFormsModule, CommonModule],
  declarations: [MppCheckboxComponent, MppMarkerComponent],
  exports: [MppCheckboxComponent],
})
export class MppCheckboxModule {}
