import { CountryCode } from 'libphonenumber-js';
import { IRawPhoneNumberSettings } from '../types';

export class MppPhoneNumberSettings {
  public constructor(public code: string, public alpha2CountryCode: CountryCode) {}

  public static fromRawData(value: IRawPhoneNumberSettings): MppPhoneNumberSettings {
    return new MppPhoneNumberSettings(value.code, value.alpha2_country_code);
  }
}
