<div class="mpp-information-section__header"
     *ngIf="heading || actionsRef"
>
  <div class="mpp-information-section__title"
       *ngIf="heading"
       [innerHTML]="heading"
  ></div>

  <div class="mpp-information-section__actions" *ngIf="actionsRef">
    <ng-container *ngTemplateOutlet="actionsRef"></ng-container>
  </div>
</div>

<div class="mpp-information-section__content">
  <ng-content></ng-content>
</div>
