<div class="mpp-range__container">
  <mpp-date-time-input
    #startInput
    [value]="startDate"
    [format]="inputFormat"
    [placeholder]="startPlaceholder"
    (onFocus)="onStartFocus()"
    (onTyping)="onTyping()"
    (onChange)="setStartDate($event)"
  ></mpp-date-time-input>

  <mpp-date-time-input
    #endInput
    [value]="endDate"
    [format]="inputFormat"
    [placeholder]="endPlaceholder"
    (onFocus)="onEndFocus()"
    (onTyping)="onTyping()"
    (onChange)="setEndDate($event)"
  ></mpp-date-time-input>
</div>

<div class="mpp-range__close-button icon icon-close-circle-filled"
     *ngIf="isClearable"
     (click)="onClearClick($event)"
></div>

<ng-template #dropdownTemplate>
  <div class="mpp-schedule-dropdown">
    <div class="mpp-schedule-dropdown__container">
      <time class="mpp-schedule-dropdown__item"
            *ngFor="let date of timeSteps"
            [attr.datetime]="date.toISOString()"
            [class.disabled]="!isAvailableTime(date)"
            (click)="onSelect(date)"
      >{{ date | mppDateFormat: inputFormat.placeholder }}</time>
    </div>
  </div>
</ng-template>


