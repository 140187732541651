<div class="mpp-input__icon-prefix icon" *ngIf="prefixIcon" [ngClass]="prefixIcon"></div>

<input
  class="mpp-input__control"
  [formControl]="control"
  [value]="control.value"
  [type]="type"
  [placeholder]="placeholderText"
  [maxlength]="maxLength"
  (input)="onTyping.emit($event)"
  (change)="onChange.emit($event)"
  (focus)="onFocus.emit($event)"
  (blur)="onBlur.emit($event)"
/>

<ng-template #defaultActionIconTemplate>
  <span
    class="mpp-input__action-icon icon"
    *ngIf="actionIcon"
    [ngClass]="actionIcon"
    (click)="$event.preventDefault(); onActionIconClick.emit($event)"
  ></span>
</ng-template>

<ng-container *ngIf="actionIcon || actionIconTemplate">
  <ng-container
    *ngTemplateOutlet="actionIconTemplate ? actionIconTemplate : defaultActionIconTemplate"
  ></ng-container>
</ng-container>

<span
  class="mpp-input__error-icon icon icon-alert-circle-filled"
  mppTooltip
  [content]="errorMessage"
></span>

<div class="mpp-input__characters-left" *ngIf="maxLength && characterLeftText">
  {{ characterLeftText }}

  <mark>{{ charactersLeft }}</mark>
</div>
