import { TRegionDateFormat } from '../types';
import { MppUserRegions } from '../../../../enums';

export const DEFAULT_STEP_MINUTES = 30;

export const DEFAULT_TIME_INPUT_FORMAT: TRegionDateFormat = {
  [MppUserRegions.US]: {
    mask: 'Hh:m0',
    date: 'HH:mm',
    placeholder: 'hh:mm a',
  },
  [MppUserRegions.FR]: {
    mask: 'Hh:m0',
    date: 'HH:mm',
    placeholder: 'HH:mm',
  },
};
