<div class="mpp-range__container">
  <mpp-date-time-input
    #startInput
    (onFocus)="onStartFocus()"
    [format]="inputFormat"
    [placeholder]="startPlaceholder"
    [value]="startDate"
    (onTyping)="onTyping()"
    (onChange)="setStartDate($event)"
  ></mpp-date-time-input>

  <mpp-date-time-input
    #endInput
    (onFocus)="onEndFocus()"
    [format]="inputFormat"
    [placeholder]="endPlaceholder"
    [value]="endDate"
    (onTyping)="onTyping()"
    (onChange)="setEndDate($event)"
  ></mpp-date-time-input>

  <div class="mpp-range__equal-range">
    {{ startDate | mppDateFormat: inputFormat.placeholder }}
  </div>
</div>

<div class="mpp-range__close-button icon icon-close-circle-filled"
     *ngIf="isClearable"
     (click)="onClearClick($event)"
></div>

<ng-template #dropdownTemplate>
  <mpp-calendar #calendar
                [date]="focusedDate"
                [selected]="[startDate, endDate]"
                (onSelect)="onSelect($event)"
                [min]="min"
                [max]="max"
                [viewMode]="viewMode"
  ></mpp-calendar>
</ng-template>


