export interface IEmojiPickerTranslationMap {
  search: string;
  emojilist: string;
  notfound: string;
  clear: string;
  categories: {
    search: string;
    recent: string;
    people: string;
    nature: string;
    foods: string;
    activity: string;
    places: string;
    objects: string;
    symbols: string;
    flags: string;
    custom: string;
  };
  skintones: Record<number, string>;
}
