<button type="button" class="mpp-image-card__action icon"  *ngIf="action" [ngClass]="action.icon">
  <ng-container *ngIf="size !== mppImagePickerSizes.SMALL">{{ action.text }}</ng-container>
</button>

<button type="button" class="mpp-image-card__remove-button icon icon-close" *ngIf="isRemovable" (click)="$event.stopPropagation(); onRemove.emit()"></button>

<div class="mpp-image-card__badge icon"
     *ngIf="badge && size !== mppImagePickerSizes.SMALL"
     [ngClass]="badge.icon"
     [attr.data-no-text]="!badge.text"
>{{ badge.text }}</div>

<img alt="" class="mpp-image-card__img" [src]="image.url" />
