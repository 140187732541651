import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mppInitials',
})
export class MppInitialsPipe implements PipeTransform {
  private readonly INITIALS_LENGTH: number = 2;

  public transform(name: string): string {
    const words = name.split(' ').filter(Boolean);

    if (words.length < this.INITIALS_LENGTH) {
      return name.slice(0, this.INITIALS_LENGTH);
    }

    return words.reduce((prevValue, currValue, index) =>
      index < this.INITIALS_LENGTH ? prevValue[0] + currValue[0] : prevValue
    );
  }
}
