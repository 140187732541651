import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostBinding,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { MppBlockquoteStates } from './enums/states';
import { MppBlockquoteTypes } from './enums/types';

@Component({
  selector: 'mpp-blockquote',
  templateUrl: './blockquote.component.html',
  styleUrls: ['./blockquote.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'mpp-blockquote icon' },
})
export class MppBlockquoteComponent {
  @Input()
  @HostBinding('attr.data-type')
  public readonly type: MppBlockquoteTypes;

  @Input()
  @HostBinding('attr.data-state')
  public readonly state: MppBlockquoteStates;

  @Input()
  public readonly content: string;

  @ContentChild(TemplateRef, { static: true })
  public readonly contentRef: TemplateRef<any>;
}
