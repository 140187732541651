import { InjectionToken } from '@angular/core';
import { IBaseSelectOption, IEmojiPickerTranslationMap } from './types';
import { MppActivityErrorType, MppCountries, MppUserLanguage } from './enums';
import { NumberFormat } from 'libphonenumber-js';

export const CONTROL_CHANGES_DEBOUNCE_TIME = 300;

export const ANIMATION_DURATION_MS = 300;

export const RESIZE_EVENT_DEBOUNCE_TIME_MS = 100;

// @todo: ut used only for All time date range functionality
//  clean up when All Time will be implemented natively
export const ARTUR_IN_START_DATE: Date = new Date(2015, 0, 1, 0, 0, 0);

export const API_URL_TOKEN = new InjectionToken('Api url token');

export const ENV_TOKEN = new InjectionToken('Env token');

export const ERROR_CODES = {
  unauthorized: 401,
  serverError: 500,
};

export const ERROR_TYPES = {
  MUTED: 'muted',
  NOT_CREATED_YET: 'not_created_yet',
  SHOOT_IN: 'shoot_in',
  WARNING_INFO: 'warning_info',
};

export const SF_SELECT_DISABLED_CLASS = 'e-list-item-disabled';
export const SF_SELECT_ACTIVE_CLASS = 'e-active';

export const FACEBOOK_POST_MAX_LENGTH = 5000;
export const INSTAGRAM_POST_MAX_LENGTH = 2200;
export const LINKEDIN_POST_MAX_LENGTH = 3000;
export const TWITTER_POST_MAX_LENGTH = 5000;
export const GOOGLE_POST_MAX_LENGTH = 1500;

export const LANGUAGE_SELECTION_LIST: IBaseSelectOption<MppUserLanguage>[] = [
  {
    label: 'COMMON.LANGUAGE.FRENCH',
    value: MppUserLanguage.FR,
    disabled: false,
  },
  {
    label: 'COMMON.LANGUAGE.ENGLISH',
    value: MppUserLanguage.EN,
    disabled: false,
  },
];

export const COUNTRY_SELECTION_LIST: IBaseSelectOption<MppCountries>[] = [
  {
    label: 'COMMON.COUNTRIES.FRANCE',
    value: MppCountries.FR,
    disabled: false,
  },
  {
    label: 'COMMON.COUNTRIES.USA',
    value: MppCountries.US,
    disabled: false,
  },
];

export const IMAGE_MIME_TYPES: string[] = ['image/png', 'image/jpeg', 'image/jpg'];

export const IMAGE_MAX_SIZE: number = 1024 * 1024 * 4;

export const ACTIVITY_ERROR_TYPES: Record<MppActivityErrorType, string> = {
  [MppActivityErrorType.MISSING_VARIABLES]: 'ACTIVITY_FEED.ERROR_TYPES.MISSING_VARIABLES',
  [MppActivityErrorType.CREDENTIALS]: 'ACTIVITY_FEED.ERROR_TYPES.CREDENTIALS',
  [MppActivityErrorType.TOO_MANY_RETRY]: 'ACTIVITY_FEED.ERROR_TYPES.TOO_MANY_RETRY',
  [MppActivityErrorType.EXECUTION_EXPIRED]: 'ACTIVITY_FEED.ERROR_TYPES.EXECUTION_EXPIRED',
  [MppActivityErrorType.TIMEOUT]: 'ACTIVITY_FEED.ERROR_TYPES.TIMEOUT',
  [MppActivityErrorType.SYSTEM_ERROR]: 'ACTIVITY_FEED.ERROR_TYPES.SYSTEM_ERROR',

  [MppActivityErrorType.FB_AUTHORIZATION_VIDEO]: 'ACTIVITY_FEED.ERROR_TYPES.FB_AUTHORIZATION_VIDEO',
  [MppActivityErrorType.FB_PERMISSION_UNPUBLISHED_POST]:
    'ACTIVITY_FEED.ERROR_TYPES.FB_PERMISSION_UNPUBLISHED_POST',
  [MppActivityErrorType.FB_MISSING_UPLOAD_FILE]: 'ACTIVITY_FEED.ERROR_TYPES.FB_MISSING_UPLOAD_FILE',
  [MppActivityErrorType.FB_TOO_MUCH_DATA]: 'ACTIVITY_FEED.ERROR_TYPES.FB_TOO_MUCH_DATA',
  [MppActivityErrorType.FB_UNKNOWN_ERROR]: 'ACTIVITY_FEED.ERROR_TYPES.FB_UNKNOWN_ERROR',
  [MppActivityErrorType.FB_INTERNAL_ERROR]: 'ACTIVITY_FEED.ERROR_TYPES.FB_INTERNAL_ERROR',
  [MppActivityErrorType.FB_INVALID_IMAGE]: 'ACTIVITY_FEED.ERROR_TYPES.FB_INVALID_IMAGE',
  [MppActivityErrorType.FB_AUTHORIZATION_SHARING]:
    'ACTIVITY_FEED.ERROR_TYPES.FB_AUTHORIZATION_SHARING',
  [MppActivityErrorType.FB_USER_NOT_OWNER]: 'ACTIVITY_FEED.ERROR_TYPES.FB_USER_NOT_OWNER',
  [MppActivityErrorType.FB_UNEXPECTED_TOKEN]: 'ACTIVITY_FEED.ERROR_TYPES.FB_UNEXPECTED_TOKEN',
  [MppActivityErrorType.FB_VIDEO_FETCHING]: 'ACTIVITY_FEED.ERROR_TYPES.FB_VIDEO_FETCHING',

  [MppActivityErrorType.INSTA_AUTHORIZATION]: 'ACTIVITY_FEED.ERROR_TYPES.INSTA_AUTHORIZATION',
  [MppActivityErrorType.INSTA_NOT_CONFIRMED_USER]:
    'ACTIVITY_FEED.ERROR_TYPES.INSTA_NOT_CONFIRMED_USER',
  [MppActivityErrorType.INSTA_RESTRICTED_USER]: 'ACTIVITY_FEED.ERROR_TYPES.INSTA_RESTRICTED_USER',
  [MppActivityErrorType.INSTA_TOO_MANY_TAGS]: 'ACTIVITY_FEED.ERROR_TYPES.INSTA_TOO_MANY_TAGS',
  [MppActivityErrorType.INSTA_INVALID_PARAMETER]:
    'ACTIVITY_FEED.ERROR_TYPES.INSTA_INVALID_PARAMETER',
  [MppActivityErrorType.INSTA_UNSUPPORTED_POST_REQUEST]:
    'ACTIVITY_FEED.ERROR_TYPES.INSTA_UNSUPPORTED_POST_REQUEST',
  [MppActivityErrorType.INSTA_NOT_LOGGED]: 'ACTIVITY_FEED.ERROR_TYPES.INSTA_NOT_LOGGED',
  [MppActivityErrorType.INSTA_NOT_PHOTO_NOR_VIDEO]:
    'ACTIVITY_FEED.ERROR_TYPES.INSTA_NOT_PHOTO_NOR_VIDEO',
  [MppActivityErrorType.INSTA_UNAUTHORIZATION_ACTION]:
    'ACTIVITY_FEED.ERROR_TYPES.INSTA_UNAUTHORIZATION_ACTION',
  [MppActivityErrorType.INSTA_NOT_BUSINESS]: 'ACTIVITY_FEED.ERROR_TYPES.INSTA_NOT_BUSINESS',
  [MppActivityErrorType.INSTA_TOO_MANY_ACTIONS]: 'ACTIVITY_FEED.ERROR_TYPES.INSTA_TOO_MANY_ACTIONS',
  [MppActivityErrorType.INSTA_IMAGE_SIZE]: 'ACTIVITY_FEED.ERROR_TYPES.INSTA_IMAGE_SIZE',
  [MppActivityErrorType.INSTA_REQUEST_LIMIT_REACHED]:
    'ACTIVITY_FEED.ERROR_TYPES.INSTA_REQUEST_LIMIT_REACHED',
  [MppActivityErrorType.INSTA_VIDEO_FORMAT_NOT_SUPPORTED]:
    'ACTIVITY_FEED.ERROR_TYPES.INSTA_VIDEO_FORMAT_NOT_SUPPORTED',
  [MppActivityErrorType.INSTA_SESSION_CHANGED]: 'ACTIVITY_FEED.ERROR_TYPES.INSTA_SESSION_CHANGED',

  [MppActivityErrorType.LK_500_ERROR]: 'ACTIVITY_FEED.ERROR_TYPES.LK_500_ERROR',
  [MppActivityErrorType.LK_AUTHORIZATION_IMAGE]: 'ACTIVITY_FEED.ERROR_TYPES.LK_AUTHORIZATION_IMAGE',
  [MppActivityErrorType.LK_503_ERROR]: 'ACTIVITY_FEED.ERROR_TYPES.LK_503_ERROR',
  [MppActivityErrorType.LK_AUTHORIZATION]: 'ACTIVITY_FEED.ERROR_TYPES.LK_AUTHORIZATION',
  [MppActivityErrorType.LK_MEMBER_RESTRICTED]: 'ACTIVITY_FEED.ERROR_TYPES.LK_MEMBER_RESTRICTED',
  [MppActivityErrorType.LK_SSL_CERTIFICATE]: 'ACTIVITY_FEED.ERROR_TYPES.LK_SSL_CERTIFICATE',
  [MppActivityErrorType.LK_AUTHORIZATION_VIDEO]: 'ACTIVITY_FEED.ERROR_TYPES.LK_AUTHORIZATION_VIDEO',
  [MppActivityErrorType.LK_TOKEN_REVOKED]: 'ACTIVITY_FEED.ERROR_TYPES.LK_TOKEN_REVOKED',
  [MppActivityErrorType.LK_SSL_ERROR]: 'ACTIVITY_FEED.ERROR_TYPES.LK_SSL_ERROR',
  [MppActivityErrorType.LK_CONNECTION_RESET]: 'ACTIVITY_FEED.ERROR_TYPES.LK_CONNECTION_RESET',
  [MppActivityErrorType.LK_ORGANISATION_DENIED]: 'ACTIVITY_FEED.ERROR_TYPES.LK_ORGANISATION_DENIED',
  [MppActivityErrorType.LK_EXECUTION_EXPIRED]: 'ACTIVITY_FEED.ERROR_TYPES.LK_EXECUTION_EXPIRED',
  [MppActivityErrorType.LK_INVALID_CONTENT_OWNERSHIP]:
    'ACTIVITY_FEED.ERROR_TYPES.LK_INVALID_CONTENT_OWNERSHIP',
  [MppActivityErrorType.LK_AUTHORIZATION_RESSOURCE]:
    'ACTIVITY_FEED.ERROR_TYPES.LK_AUTHORIZATION_RESSOURCE',

  [MppActivityErrorType.TW_ASYNC_FAIL]: 'ACTIVITY_FEED.ERROR_TYPES.TW_ASYNC_FAIL',
  [MppActivityErrorType.TW_ACCOUNT_LOCKED]: 'ACTIVITY_FEED.ERROR_TYPES.TW_ACCOUNT_LOCKED',
  [MppActivityErrorType.TW_SSL_CERTIFICATE]: 'ACTIVITY_FEED.ERROR_TYPES.TW_SSL_CERTIFICATE',
  [MppActivityErrorType.TW_403_ERROR]: 'ACTIVITY_FEED.ERROR_TYPES.TW_403_ERROR',
  [MppActivityErrorType.TW_500_ERROR]: 'ACTIVITY_FEED.ERROR_TYPES.TW_500_ERROR',
  [MppActivityErrorType.TW_AUTOMATED_REQUEST]: 'ACTIVITY_FEED.ERROR_TYPES.TW_AUTOMATED_REQUEST',
  [MppActivityErrorType.TW_MEDIA_PROCESSING_TIMEOUT]:
    'ACTIVITY_FEED.ERROR_TYPES.TW_MEDIA_PROCESSING_TIMEOUT',
  [MppActivityErrorType.TW_404_ERROR]: 'ACTIVITY_FEED.ERROR_TYPES.TW_404_ERROR',
  [MppActivityErrorType.TW_TCP_ERROR]: 'ACTIVITY_FEED.ERROR_TYPES.TW_TCP_ERROR',
  [MppActivityErrorType.TW_UNKNOW_ERROR_VIDEO]: 'ACTIVITY_FEED.ERROR_TYPES.TW_UNKNOW_ERROR_VIDEO',
  [MppActivityErrorType.TW_VIDEO_TOO_LONG]: 'ACTIVITY_FEED.ERROR_TYPES.TW_VIDEO_TOO_LONG',
  [MppActivityErrorType.TW_INVALID_MEDIA_TYPE]: 'ACTIVITY_FEED.ERROR_TYPES.TW_INVALID_MEDIA_TYPE',
  [MppActivityErrorType.TW_TOO_LONG]: 'ACTIVITY_FEED.ERROR_TYPES.TW_TOO_LONG',
  [MppActivityErrorType.TW_ACCOUNT_SUSPENDED]: 'ACTIVITY_FEED.ERROR_TYPES.TW_ACCOUNT_SUSPENDED',
  [MppActivityErrorType.TW_503_ERROR]: 'ACTIVITY_FEED.ERROR_TYPES.TW_503_ERROR',
  [MppActivityErrorType.TW_TIMEOUT]: 'ACTIVITY_FEED.ERROR_TYPES.TW_TIMEOUT',

  [MppActivityErrorType.GO_INVALID_ARGUMENT]: 'ACTIVITY_FEED.ERROR_TYPES.GO_INVALID_ARGUMENT',
  [MppActivityErrorType.GO_FORBIDDEN]: 'ACTIVITY_FEED.ERROR_TYPES.GO_FORBIDDEN',
  [MppActivityErrorType.GO_REJECTED_STATE]: 'ACTIVITY_FEED.ERROR_TYPES.GO_REJECTED_STATE',
  [MppActivityErrorType.GO_ENTITY_NOT_FOUND]: 'ACTIVITY_FEED.ERROR_TYPES.GO_ENTITY_NOT_FOUND',
  [MppActivityErrorType.GO_TOO_MANY_OPEN_FILES]: 'ACTIVITY_FEED.ERROR_TYPES.GO_TOO_MANY_OPEN_FILES',
  [MppActivityErrorType.GO_SERVER_ERROR]: 'ACTIVITY_FEED.ERROR_TYPES.GO_SERVER_ERROR',
  [MppActivityErrorType.GO_LOCAL_POST_FORBIDDEN]:
    'ACTIVITY_FEED.ERROR_TYPES.GO_LOCAL_POST_FORBIDDEN',
  [MppActivityErrorType.GO_SYSTEM_LIB]: 'ACTIVITY_FEED.ERROR_TYPES.GO_SYSTEM_LIB',
  [MppActivityErrorType.GO_AUTHORIZATION]: 'ACTIVITY_FEED.ERROR_TYPES.GO_AUTHORIZATION',
  [MppActivityErrorType.GO_PROCESSING]: 'ACTIVITY_FEED.ERROR_TYPES.GO_PROCESSING',
};

export const EMOJI_PICKER_TRANSLATION_MAP: IEmojiPickerTranslationMap = {
  search: 'COMMON.EMOJI_PICKER.SEARCH',
  emojilist: 'COMMON.EMOJI_PICKER.EMOJI_LIST',
  notfound: 'COMMON.EMOJI_PICKER.EMOJI_NOT_FOUND',
  clear: 'COMMON.EMOJI_PICKER.CLEAR',
  categories: {
    search: 'COMMON.EMOJI_PICKER.CATEGORY_SEARCH',
    recent: 'COMMON.EMOJI_PICKER.CATEGORY_RECENT',
    people: 'COMMON.EMOJI_PICKER.CATEGORY_PEOPLE',
    nature: 'COMMON.EMOJI_PICKER.CATEGORY_NATURE',
    foods: 'COMMON.EMOJI_PICKER.CATEGORY_FOODS',
    activity: 'COMMON.EMOJI_PICKER.CATEGORY_ACTIVITY',
    places: 'COMMON.EMOJI_PICKER.CATEGORY_PLACES',
    objects: 'COMMON.EMOJI_PICKER.CATEGORY_OBJECTS',
    symbols: 'COMMON.EMOJI_PICKER.CATEGORY_SYMBOLS',
    flags: 'COMMON.EMOJI_PICKER.CATEGORY_FLAGS',
    custom: 'COMMON.EMOJI_PICKER.CATEGORY_CUSTOM',
  },
  skintones: {
    1: 'COMMON.EMOJI_PICKER.SKINTONE_DEFAULT',
    2: 'COMMON.EMOJI_PICKER.SKINTONE_LIGHT',
    3: 'COMMON.EMOJI_PICKER.SKINTONE_MEDIUM_LIGHT',
    4: 'COMMON.EMOJI_PICKER.SKINTONE_MEDIUM',
    5: 'COMMON.EMOJI_PICKER.SKINTONE_MEDIUM_DARK',
    6: 'COMMON.EMOJI_PICKER.SKINTONE_DARK',
  },
};

export const PHONE_NUMBER_FORMATS: Record<string, NumberFormat> = {
  NATIONAL: 'National',
  INTERNATIONAL: 'International',
  E_164: 'E.164',
  RFC3966: 'RFC3966',
  IDD: 'IDD',
};
