import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode, parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js';

@Pipe({
  name: 'mppPhoneNumberFormat',
})
export class MppPhoneNumberFormatPipe implements PipeTransform {
  public transform(value: string, countryCode: CountryCode): string {
    return this.formatNumber(value, countryCode);
  }

  private formatNumber(value: string, code: CountryCode): string {
    const newPhoneNumber: PhoneNumber | undefined = parsePhoneNumberFromString(value, code);
    const internationalNumber: string | undefined = newPhoneNumber?.formatInternational();

    return internationalNumber || value;
  }
}
