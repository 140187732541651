<textarea class="mpp-textarea__control"
          [formControl]="control"
          [value]="control.value"
          [placeholder]="placeholderText"
          [maxlength]="maxLength"
          (input)="onTextareaInput($event)"
          (change)="onChange.emit($event)"
          (focus)="onFocus.emit($event)"
          (blur)="onBlur.emit($event)"
          #textAreaElement
></textarea>

<div *ngIf="linkButtonText" class="mpp-textarea__buttons">
  <button class="mpp-textarea__link-button"
          mppButton="stroke white"
          (click)="insertLinkStructure($event)"
  >
    {{ linkButtonText }}
  </button>
</div>

<span class="mpp-textarea__error-icon icon icon-alert-circle-filled"
      mppTooltip
      [content]="errorMessage"
></span>

<div class="mpp-textarea__characters-left" *ngIf="maxLength && characterLeftText">
  {{ characterLeftText }}

  <mark>{{ charactersLeft }}</mark>
</div>
