import { TemplateRef } from '@angular/core';
import { IMediaItemData } from './media-item-data';

export interface IMediaDialogData {
  media: IMediaItemData[];
  initialSlide?: number;

  actionsTemplate?: TemplateRef<any>;
  sidebarTemplate?: TemplateRef<any>;
}
