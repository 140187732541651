import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MppAccessTokenService } from '../services/access-token.service';

@Injectable()
export class MppAccessTokenInterceptor implements HttpInterceptor {
  public constructor(private readonly accessTokenService: MppAccessTokenService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(
      req.clone({
        setHeaders: { 'X-Access-Token': this.accessTokenService.value },
      })
    );
  }
}
