import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { ISummaryCounterData } from './types';

@Component({
  selector: 'mpp-summary-counter',
  templateUrl: './summary-counter.component.html',
  styleUrls: ['./summary-counter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'mpp-summary-counter' },
})
export class MppSummaryCounterComponent {
  @Input() public readonly data: ISummaryCounterData;

  @HostBinding('style.background')
  public get background(): string {
    return this.data.background;
  }

  public isCountAvailable(): boolean {
    return this.data.count !== undefined;
  }

  public getCountLabel(data: ISummaryCounterData): string {
    const isSingular = data.count === 0 || data.count === 1;
    return isSingular ? data.labels.single : data.labels.multiple;
  }
}
