import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'mpp-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'mpp-form-field',
    role: 'group',
    'aria-label': 'Form field',
  },
})
export class MppFormFieldComponent {
  @Input()
  public readonly labelText: string;

  @Input()
  public readonly tooltipText: string;

  @Input()
  public readonly optionalLabelText: string;

  @Input()
  @HostBinding('class.optional')
  public readonly isOptional: boolean;

  @Input()
  @HostBinding('class.invalid')
  public readonly isInvalid: boolean;

  @Input()
  @HostBinding('class.disabled')
  public readonly isDisabled: boolean;
}
