import { MppAttachmentOriginType, MppMediaType } from '../enums';

export interface IRawMppAttachment {
  id: number;
  byte_size: number;
  checksum: string;
  content_type: MppMediaType;
  source: string;
  origin: MppAttachmentOriginType;
  origin_id: string;
  origin_url: string;
  small_preview_url: string;
  filename: string;
}
