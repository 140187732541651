<div class="mpp-spinner__container"
     [style.width.px]="size"
     [style.height.px]="size"
>
  <svg [attr.viewBox]="viewBox">
    <circle cx="50%"
            cy="50%"
            fill="none"
            [attr.stroke-width]="DEFAULT_STROKE_WIDTH"
            [attr.r]="getCircleRadius()"
    />
  </svg>
</div>
