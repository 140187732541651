import {
  Attribute,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { MppBaseDropdown } from '../helpers/base-dropdown';
import { MppLanguageService } from '../../../services/language.service';
import { COMMON_ERRORS_TOKEN } from '../form-controls.constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TCommonErrors } from '../types/common-errors';

@Component({
  selector: 'mpp-group-tag-select',
  templateUrl: './group-tag-select.component.html',
  styleUrls: ['./group-tag-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-group-tag-select' },
})
export class MppGroupTagSelectComponent extends MppBaseDropdown implements OnInit {
  @Input()
  private set items(value: any[]) {
    this.listItems = value?.length ? value : this.listItems;
    this._items = value || [];
  }

  private get items(): any[] {
    return this._items;
  }

  @Input() public readonly readonly: boolean = false;
  @Input() public readonly placeholderText: string = '';
  @Input() public readonly notFoundText: string;

  @Input() public readonly maxSelectedItems: number;

  @Input() public readonly allowCustomTags: boolean = false;

  @Output() public readonly onTagAdd = new EventEmitter<any>();

  @Output() public readonly onTagRemove = new EventEmitter<any>();

  @ViewChild('tagsCountTemplate', { static: true })
  public readonly tagsCountTemplate: TemplateRef<any>;

  @ViewChild('errorIconTemplate', { static: true })
  public readonly errorIconTemplate: TemplateRef<any>;

  @ContentChild('optionsGroupTemplate')
  public readonly optionsGroupTemplate: TemplateRef<any>;

  @ContentChild('optionTemplate')
  public readonly optionTemplate: TemplateRef<any>;

  public hiddenTags: Element[] = [];

  public listItems: any[] = [];
  private tagsBuffer: Element[] = [];
  private tags: Element[] = [];

  private inputContainer: HTMLElement | null;
  private valueContainer: HTMLElement | null;
  private placeholderElement: HTMLElement | null;
  private tagsCountElement: HTMLElement;
  private errorIconElement: HTMLElement;

  private isFocused = false;

  private availableTagsSpace = 0;

  private _items: any[];
  private readonly tagsChanges$$: Subject<void> = new Subject();

  private get selectElement(): HTMLElement {
    return this.selectElementRef.nativeElement;
  }

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef<HTMLElement>,
    viewContainerRef: ViewContainerRef,
    languageService: MppLanguageService,
    @Inject(COMMON_ERRORS_TOKEN) COMMON_ERRORS: TCommonErrors,
    @Attribute('bindValue') public readonly bindValue: string,
    @Attribute('bindLabel') public readonly bindLabel: string,
    @Attribute('groupBy') public readonly groupBy: string | ((key: any) => any)
  ) {
    super(changeDetectorRef, elementRef, viewContainerRef, languageService, COMMON_ERRORS);

    this.bindLabel ||= this.DEFAULT_BIND_LABEL;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.initTagsChanges();
    this.initStructureReplacement();
  }

  public onType(term?: string): void {
    this.isOpen = true;
    this.onSearch.emit(term);

    const isItemExist = this.items.some(
      (item) => item[this.bindLabel].toLowerCase() === term?.trim().toLowerCase()
    );
    if (!this.allowCustomTags || isItemExist || !term?.trim()) {
      this.listItems = this.items;
      return;
    }
    this.listItems = [{ [this.bindLabel]: term.trim() }, ...this.items];
  }

  public onSelectFocus(): void {
    this.onFocus.emit();
    this.isFocused = true;

    this.tagsChanges$$.next();
  }

  public onSelectBlur(): void {
    this.onBlur.emit();
    this.isOpen = false;
    this.isFocused = false;

    this.tagsChanges$$.next();
  }

  // eslint-disable-next-line
  public onAdd(value: any): void {
    this.isOpen = false;
    this.onTagAdd.emit(value);
  }

  // eslint-disable-next-line
  public onRemove(value: any): void {
    this.onTagRemove.emit(value);
  }

  public onSelectChange(): void {
    this.onChange.emit();

    this.tagsChanges$$.next();
  }

  private initTagsChanges(): void {
    this.tagsChanges$$.pipe(takeUntil(this.destroy$$)).subscribe(() => {
      this.changeDetectorRef.detectChanges();

      const tagsContainerBuffer = this.selectElement.querySelector('.mpp-group-tags-buffer');
      const tagsContainer = this.selectElement.querySelector('.mpp-group-tags-container');

      this.tagsBuffer = Array.from<Element>(tagsContainerBuffer?.children || []);
      this.tags = Array.from<Element>(tagsContainer?.children || []);

      if (!(this.tags.length && this.tagsBuffer.length)) {
        return;
      }

      const placeholderStyles: CSSStyleDeclaration = window.getComputedStyle(
        this.placeholderElement as HTMLElement
      );

      const deductibles: number[] = [
        parseInt(placeholderStyles.minWidth, 10),
        this.tagsCountElement.clientWidth,
        this.errorIconElement.clientWidth,
      ];

      this.availableTagsSpace = deductibles.reduce(
        (result, size) => (result -= size),
        this.valueContainer?.clientWidth || 0
      );

      const actionsTriggerMap: (() => void)[] = [
        this.hideTags.bind(this),
        this.showTags.bind(this),
      ];

      actionsTriggerMap[Number(this.isFocused)]();
    });
  }

  private initStructureReplacement(): void {
    const tagsCountEmbeddedView: EmbeddedViewRef<void> = this.viewContainerRef.createEmbeddedView(
      this.tagsCountTemplate
    );
    const errorIconEmbeddedView: EmbeddedViewRef<void> = this.viewContainerRef.createEmbeddedView(
      this.errorIconTemplate
    );

    this.inputContainer = this.selectElement.querySelector('.ng-input');
    this.valueContainer = this.selectElement.querySelector('.ng-value-container');
    this.placeholderElement = this.selectElement.querySelector('.ng-placeholder');
    this.tagsCountElement = tagsCountEmbeddedView.rootNodes[0];
    this.errorIconElement = errorIconEmbeddedView.rootNodes[0];

    if (this.inputContainer) {
      this.inputContainer.append(this.placeholderElement || '');
      this.inputContainer.append(this.errorIconElement);
      this.inputContainer.append(this.tagsCountElement);
    }

    this.tagsChanges$$.next();
  }

  private hideTags(): void {
    let totalTagsSize = 0;

    this.hiddenTags = this.tags.filter((_, index) => {
      totalTagsSize += this.tagsBuffer[index].clientWidth;

      return totalTagsSize > this.availableTagsSpace;
    });

    this.hiddenTags.forEach((hiddenTag) => hiddenTag.setAttribute('hidden', ''));
  }

  private showTags(): void {
    this.hiddenTags = [];

    this.tags.forEach((tag) => tag.removeAttribute('hidden'));
  }
}
