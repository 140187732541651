import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { MppImagePickerSizes } from '../enums';

@Component({
  selector: 'mpp-image-add',
  templateUrl: './image-add.component.html',
  styleUrls: ['./image-add.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-image-add icon' },
})
export class MppImageAddComponent {
  @Input()
  @HostBinding('attr.data-theme')
  public readonly type: 'dark' | 'light' | 'secondary' = 'light';

  @Input()
  @HostBinding('attr.data-size')
  public readonly size: MppImagePickerSizes;

  @HostBinding('class')
  public get iconClass(): string {
    return this.size === MppImagePickerSizes.SMALL ? 'icon-plus-bold' : 'icon-plus-circle-filled';
  }
}
