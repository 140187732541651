<ng-select class="mpp-lib"
           [formControl]="control"
           [items]="items"
           [clearable]="false"
           [clearOnBackspace]="false"
           [multiple]="true"
           [hideSelected]="true"
           [readonly]="readonly"
           [isOpen]="isOpen"
           [bindValue]="bindValue"
           [bindLabel]="bindLabel"
           appendTo="body"
           [notFoundText]="notFoundText"
           [placeholder]="placeholderText"
           (search)="onSearch.emit($event.term);toggleSearch()"
           (add)="isOpen = false"
           (blur)="isOpen = false"
>
  <ng-template ng-multi-label-tmp
               let-clear="clear"
               let-items="items"
  >
    <div class="mpp-tag icon icon-plus-bold" *ngFor="let item of items" (click)="clear(item)">
      {{ item[ bindLabel ] }}
    </div>
  </ng-template>

  <ng-template ng-option-tmp let-item="item">
    {{ item[bindLabel] }}
  </ng-template>
</ng-select>
