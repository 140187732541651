import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js';
import { MppBaseFormControl } from '../helpers/base-form-control';
import { lengthPhoneNumberValidator } from '../../../helpers';
import { MppPhoneNumberSettings } from '../../../models';
import { COMMON_ERRORS_TOKEN } from '../form-controls.constants';
import { MppLanguageService } from '../../../services/language.service';
import { TCommonErrors } from '../types/common-errors';

@Component({
  selector: 'mpp-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'mpp-phone-input' },
})
export class MppPhoneInputComponent extends MppBaseFormControl implements OnInit {
  @Input()
  public readonly phoneNumberSettings: MppPhoneNumberSettings;

  @HostBinding('class.invalid')
  public get isInvalid(): boolean {
    return this.control.invalid || !!(this.errorMessage && this.errorMessage.length);
  }

  public constructor(
    @Inject(COMMON_ERRORS_TOKEN) COMMON_ERRORS: TCommonErrors,
    languageService: MppLanguageService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(languageService, changeDetectorRef, COMMON_ERRORS);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.updateControlValue();
  }

  private updateControlValue(): void {
    if (!this.phoneNumberSettings || !this.control.value) {
      return;
    }

    const phoneNumber = parsePhoneNumber(
      this.control.value,
      this.phoneNumberSettings.alpha2CountryCode
    );

    this.control.setValue(phoneNumber.formatInternational());
    this.control.setValidators([lengthPhoneNumberValidator()]);
    this.control.updateValueAndValidity();
  }
}
